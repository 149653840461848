<template>
  <NavMain />
  <div class="content-all">
    <section class="content-title">
      <div class="container">
        <div class="text-center">
          <div class="title">Agregar un Nuevo Usuario</div>
        </div>
      </div>
    </section>

    <section v-if="is_portrait == false">
      <form @submit.stop.prevent="handleSubmit">
        <div class="container">
          <div class="justify-content-center">
            <div class="row">
              <div class="col-4">
                <div class="form-floating">
                  <input
                    type="text"
                    required
                    autocomplete="off"
                    placeholder="Nombre..."
                    class="form-control my-2"
                    id="nombre"
                    v-model="name"
                  />
                  <label for="nombre">Nombre...</label>
                </div>
                <div class="form-floating">
                  <input
                    type="text"
                    required
                    autocomplete="off"
                    placeholder="Apellido..."
                    class="form-control my-2"
                    id="apellido"
                    v-model="last_name"
                  />
                  <label for="apellido">Apellido...</label>
                </div>
                <div class="form-floating">
                  <input
                    type="text"
                    autocomplete="off"
                    placeholder="Edad..."
                    class="form-control my-2"
                    id="edad"
                    v-model="age"
                  />
                  <label for="edad">Edad...</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-floating">
                  <input
                    type="email"
                    required
                    autocomplete="off"
                    placeholder="Correo..."
                    class="form-control my-2"
                    id="correo"
                    v-model="email"
                  />
                  <label for="correo">Correo...</label>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-floating d-flex">
                      <input
                        :type="showPass1"
                        required
                        autocomplete="off"
                        placeholder="Contraseña..."
                        class="form-control"
                        id="contraseña"
                        v-model="password"
                      />
                      <label for="contraseña">Contraseña...</label>
                      <span @click="changeEye1">
                        <span v-show="noEye1"
                          ><i class="icon-input far fa-eye"></i
                        ></span>
                        <span v-show="eye1"
                          ><i class="icon-input fas fa-eye-slash"></i
                        ></span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-floating d-flex">
                      <input
                        :type="showPass2"
                        required
                        autocomplete="off"
                        placeholder="Confirmar Contraseña..."
                        class="form-control my-2"
                        id="con-contraseña"
                        v-model="c_password"
                      />
                      <label for="con-contraseña"
                        >Confirmar Contraseña...</label
                      >
                      <span @click="changeEye2">
                        <span v-show="noEye2"
                          ><i class="icon-input far fa-eye"></i
                        ></span>
                        <span v-show="eye2"
                          ><i class="icon-input fas fa-eye-slash"></i
                        ></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <select
                  class="form-select form-select-lg mb-2 mt-2"
                  v-model="state"
                >
                  <option selected>Estado</option>
                  <option value="Activo">Activo</option>
                  <option value="Inactivo">Inactivo</option>
                </select>
                <select
                  class="form-select form-select-lg mb-2 mt-3"
                  v-model="role"
                >
                  <option selected>Rol</option>
                  <option value="Administrador">Administrador</option>
                  <option value="Docente">Docente</option>
                  <option value="Estudiante">Estudiante</option>
                </select>
              </div>
              <div class="text-center my-2">
                <button class="btn-submit">Enviar datos</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
    <!-- portrait -->
    <section v-if="is_landscape == false && is_portrait == true">
      <form @submit.stop.prevent="handleSubmit">
        <div class="container">
          <div class="justify-content-center">
            <!-- <div class="row"> -->
            <!-- <div class="col-4"> -->
            <div class="form-floating">
              <input
                type="text"
                required
                autocomplete="off"
                placeholder="Nombre..."
                class="form-control my-2"
                id="nombre"
                v-model="name"
              />
              <label for="nombre">Nombre...</label>
            </div>
            <div class="form-floating">
              <input
                type="text"
                required
                autocomplete="off"
                placeholder="Apellido..."
                class="form-control my-2"
                id="apellido"
                v-model="last_name"
              />
              <label for="apellido">Apellido...</label>
            </div>
            <div class="form-floating">
              <input
                type="text"
                autocomplete="off"
                placeholder="Edad..."
                class="form-control my-2"
                id="edad"
                v-model="age"
              />
              <label for="edad">Edad...</label>
            </div>
            <!-- </div> -->
            <!-- <div class="col-4"> -->
            <div class="form-floating">
              <input
                type="email"
                required
                autocomplete="off"
                placeholder="Correo..."
                class="form-control my-2"
                id="correo"
                v-model="email"
              />
              <label for="correo">Correo...</label>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-floating d-flex">
                  <input
                    :type="showPass1"
                    required
                    autocomplete="off"
                    placeholder="Contraseña..."
                    class="form-control"
                    id="contraseña"
                    v-model="password"
                  />
                  <label for="contraseña">Contraseña...</label>
                  <span @click="changeEye1">
                    <span v-show="noEye1"
                      ><i class="icon-input far fa-eye"></i
                    ></span>
                    <span v-show="eye1"
                      ><i class="icon-input fas fa-eye-slash"></i
                    ></span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-floating d-flex">
                  <input
                    :type="showPass2"
                    required
                    autocomplete="off"
                    placeholder="Confirmar Contraseña..."
                    class="form-control my-2"
                    id="con-contraseña"
                    v-model="c_password"
                  />
                  <label for="con-contraseña">Confirmar Contraseña...</label>
                  <span @click="changeEye2">
                    <span v-show="noEye2"
                      ><i class="icon-input far fa-eye"></i
                    ></span>
                    <span v-show="eye2"
                      ><i class="icon-input fas fa-eye-slash"></i
                    ></span>
                  </span>
                </div>
              </div>
            </div>
            <!-- </div> -->
            <!-- <div class="col-4"> -->
            <select
              class="form-select form-select-lg mb-2 mt-2"
              v-model="state"
            >
              <option selected>Estado</option>
              <option value="Activo">Activo</option>
              <option value="Inactivo">Inactivo</option>
            </select>
            <select class="form-select form-select-lg mb-2 mt-3" v-model="role">
              <option selected>Rol</option>
              <option value="Administrador">Administrador</option>
              <option value="Docente">Docente</option>
              <option value="Estudiante">Estudiante</option>
            </select>
            <!-- </div> -->
            <div class="text-center mt-3">
              <button class="btn-submit">Enviar datos</button>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import NavMain from "@/components/NavMain.vue";
import axios from "axios";
import moment from "moment";

export default {
  components: {
    NavMain
  },
  data() {
    return {
      name: "",
      last_name: "",
      age: "",
      email: "",
      password: "",
      c_password: "",
      state: "Estado",
      role: "Rol",
      showPass1: "password",
      showPass2: "password",
      eye1: true,
      eye2: true,
      noEye1: false,
      noEye2: false,
      url: process.env.VUE_APP_URL,
      // tamaño de pantalla
      is_portrait: false,
      is_landscape: false
      // passwordError: ''
    };
  },
  mounted() {
    if (window.innerHeight > window.innerWidth) {
      //
      this.is_portrait = true;
      this.is_landscape = false;
    } else {
      this.is_portrait = false;
      if (
        (screen.width <= 760 && screen.height <= 360) ||
        (screen.width <= 1080 && screen.height <= 810)
      ) {
        this.is_landscape = true;
      }
    }
    // console.log("land:", this.is_landscape);
    window.addEventListener("orientationchange", this.handleOrientationChange);
  },
  methods: {
    changeEye1() {
      if (this.showPass1 === "password") {
        this.showPass1 = "text";
        this.eye1 = false;
        this.noEye1 = true;
      } else {
        this.showPass1 = "password";
        this.noEye1 = false;
        this.eye1 = true;
      }
    },
    changeEye2() {
      if (this.showPass2 === "password") {
        this.showPass2 = "text";
        this.eye2 = false;
        this.noEye2 = true;
      } else {
        this.showPass2 = "password";
        this.noEye2 = false;
        this.eye2 = true;
      }
    },
    async handleSubmit() {
      if (this.c_password == this.password) {
        // submit
        await axios
          .post(this.url + `/api/usuarios`, {
            nombre: this.name,
            apellido: this.last_name,
            edad: this.age,
            correo: this.email,
            contrasena: this.password,
            confirmar_contrasena: this.c_password,
            estado: this.state,
            rol: this.role
          })
          .then(response => {
            // console.log(response);
            // this.courses = response.data
          })
          .catch(e => {
            console.log(e);
          });
        // alert
        this.$swal({
          icon: "success",
          text:
            "El Usuario" + "  " + this.name + "  " + "se ha creado con exito!",
          confirmButtonText: "Continuar",
          confirmButtonColor: "#8FD53B",
          focusConfirm: true
        });
        // redirect
        this.$router.push({ name: "AdminUsers" });
      } else {
        // alert
        this.$swal({
          icon: "warning",
          text: "Las contraseñas no coinciden",
          confirmButtonText: "Continuar",
          confirmButtonColor: "#8FD53B",
          focusConfirm: true
        });
      }
    },
    async handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
        // if (screen.width <= 360 && screen.height <= 760) {
        //
        this.is_portrait = true;
        this.is_landscape = false;
        // }
      } else if (orientation === "landscape-primary") {
        // landscape mode
        if (
          (screen.width <= 760 && screen.height <= 360) ||
          (screen.width <= 1080 && screen.height <= 810)
        ) {
          this.is_landscape = true;
        }
        this.is_portrait = false;
      }
    }
  }
};
</script>


<style scoped>
.content-all {
  margin-bottom: 30vmin;
}
.content-title {
  /* margin-top: 12rem; */
  margin-top: 20vmin;
  margin-bottom: 2rem;
  font-family: "Fredoka One", cursive;
  color: #1d2945;

  /* background: #d7dade; */
}
.title {
  font-size: 5vmin;
  font-weight: bold;
}
input,
textarea {
  /* font-size: 22px; */
  font-size: 2.3vmin;
  /* max-width: 40vmin; */
  height: 6.5vmin;
  /* display: block; */
  /* width: 100%; */
  /* height: 90%; */
  /* padding: 5px 10px; */
  border: 1px solid #78be20 !important;
  /* color: #ffffff; */
}
textarea {
  height: 200px;
}
input:focus {
  border: 3px solid #78be20 !important;
}
textarea:focus {
  border: 3px solid #78be20 !important;
}
label {
  /* font-size: 17px; */
  font-size: 2.1vmin;
  color: #78be20 !important;
}
.input-file {
  display: none;
}
.form-control {
  height: 6.5vmin;
}
/* .form-floating{
  width: auto;
} */
.tags {
  display: inline-block;
  width: auto;
  height: 30px;
  background: #78be20;
  color: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 2px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
}
.icon-tag {
  /* text-align: right; */
  margin-left: 5px;
  /* font-size: 25px; */
  font-size: 2.3vmin;
  padding-top: 5px;
}
.icon-input {
  display: inline-block;
  /* float: left; */
  /* font-size: 25px; */
  font-size: 2.3vmin;
  cursor: pointer;
  margin-top: 15px;
  padding-left: 1vmin;
}
/* select */
.form-select {
  /* width: 23rem; */
  max-width: 40vmin;
  /* height: 58px; */
  height: 6.7vmin;
  border: 1px solid #78be20 !important;
}
select {
  color: #78be20;
  font-weight: bold;
  /* font-size: 18px; */
  font-size: 2.3vmin;
}
select:focus {
  border: 3px solid #78be20;
}
option {
  color: #000;
}
/* button */
.btn-file {
  display: inline-block;
  text-align: center;
  width: 180px;
  color: #ffffff !important;
  background: #8fd53b;
  cursor: pointer;
  border-radius: 0.25rem;
  padding-top: 5px;
  padding-bottom: 5px;
}
.btn-file:hover,
.btn-submit:hover {
  color: #ffffff;
  /* font-weight: bold; */
}
.btn-submit {
  display: inline-block;
  text-align: center;
  /* width: 180px; */
  width: auto;
  color: #ffffff !important;
  background: #8fd53b;
  cursor: pointer;
  border-radius: 0.25rem;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 2.5vmin;
  /* font-family: "Ferdoka One", cursive; */
}
/* .btn-submit:hover{
  color: #ffffff;
  font-weight: bold;
} */
</style>