<template>
  <NavMain @search="handleSearch" />
  <div class="content-all">
    <div class="container">
      <div>
        <div class="main-title">Todos mis cursos</div>
        <p class="article">Verifica tu progreso y avance en tu aprendisaje</p>
      </div>
    </div>
    <section class="content-title">
      <div class="container">
        <div class="row">
          <div class="title d-flex py-2">
            <div class="">Cursos</div>
            <div class="title2">
              Totales: <span>{{ numero }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="blue">
      <div class="container py-1">
        <vueper-slides
          v-if="is_portrait == false"
          ref="vueperslides1"
          @slide="
            $refs.vueperslides2 &&
              $refs.vueperslides2.goToSlide($event.currentSlide.index, {
                emit: false
              })
          "
          :slide-ratio="val_ratio"
          :bullets="false"
          :touchable="false"
          slide-image-inside
          class="no-shadow py-2"
          :visible-slides="val_slides"
          slide-multiple
          :dragging-distance="70"
          :gap="4"
        >
          <vueper-slide
            v-for="course in trueCourses"
            :key="course._id"
            :title="
              `<p style='color:#74A800; font-size:2.5vmin; text-transform:uppercase; font-family: Fredoka One, cursive;margin-top: 20px;'>${course.nombre}</p>`
            "
            :content="
              `<p style=' font-size:2.3vmin; font-weight: bold;'>${course.autor}</p><p style=' font-size:2.3vmin; font-weight: bold;'>${course.duracion}</p>`
            "
            :image="url + '/api/archivos/' + course.imagen"
            :style="
              `box-shadow: 0px 10px 10px black;
          height: 90%; color: #fff; 
          font-size:25px; cursor: pointer;`
            "
            @click="link(course._id.$oid)"
          />
        </vueper-slides>

        <vueper-slides
          v-if="is_portrait == false"
          class="no-shadow"
          ref="vueperslides2"
          :arrows="false"
          :bullets="true"
          slide-multiple
          :slide-ratio="val_ratio"
          :dragging-distance="70"
          @slide="
            $refs.vueperslides1 &&
              $refs.vueperslides1.goToSlide($event.currentSlide.index, {
                emit: false
              })
          "
          :visible-slides="val_slides"
          :gap="10"
          :fixed-height="val_fixed"
        >
          <vueper-slide
            v-for="(course, i_course) in trueCourses"
            :key="i_course"
            @click="
              $refs.vueperslides2 && $refs.vueperslides2.goToSlide(course - 1)
            "
          >
            <template #content>
              <span v-for="(por, i_por) in percents" :key="i_por">
                <div
                  v-if="i_por == i_course"
                  class="vueperslide__content-wrapper "
                  :style="
                    'background-color: ' + ['#ff5252', '#42b983'][course % 2]
                  "
                >
                  <div class="vueperslide__title ">
                    <div class="row">
                      <div
                        class="progress col-4"
                        style="
                      width: 25vmin;
                      background: #e2f9c0;
                      padding-left: 2px;
                      padding-right: 2px;
                      margin-top: 8px;
                    "
                      >
                        <div
                          class="progress-bar"
                          :style="{ width: (por * 25) / 100 + 'vmin' }"
                          style="background: #a2e048"
                          role="progressbar"
                          aria-valuenow="20"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div
                        class="col-1"
                        style="color: #a2e048; font-size: 2.3vmin; font-weight: bold;"
                      >
                        {{ por }}%
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </template>
          </vueper-slide>
        </vueper-slides>
        <!--  -->
      </div>
      <div v-if="is_portrait" class="container main-container">
        <div v-for="(curso, index) in trueCourses" :key="index">
          <div class="container-img">
            <router-link
              :to="{
                name: 'Course',
                params: { id: curso._id.$oid, index_show: 0 }
              }"
            >
              <img
                class="img-curso"
                :src="url + '/api/archivos/' + curso.imagen"
              />
              <div class="text-curso">{{ curso.nombre }}</div>
              <div class="text-curso">{{ curso.autor }}</div>
              <div class="text-curso">{{ curso.duracion }}</div>
            </router-link>
          </div>

          <!--  -->
          <span v-for="(por, i_por) in percents" :key="i_por">
            <span v-if="i_por == index">
              <div class="row justify-content-center cont-progress">
                <div
                  class="progress col-4"
                  style="
                      width: 25vmin;
                      background: #e2f9c0;
                      padding-left: 2px;
                      padding-right: 2px;
                      margin-top: 1vmin
                    "
                >
                  <div
                    class="progress-bar"
                    :style="{ width: (por * 25) / 100 + 'vmin' }"
                    style="background: #a2e048"
                    role="progressbar"
                    aria-valuenow="20"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div
                  class="col-1"
                  style="color: #a2e048; font-size: 3.5vmin; font-weight: bold;"
                >
                  {{ por }}%
                </div>
              </div>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavMain from "@/components/NavMain.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import _ from "lodash";
export default {
  components: {
    NavMain,
    Footer,
    VueperSlides,
    VueperSlide
  },
  data() {
    return {
      myCourses: null,
      filteredMyCourses: null,
      userCourses: null,
      trueCourses: [],
      numero: 0,
      cant_lessons: null,
      trueMyCourses: null,
      complete: null,
      percents: null,
      // valores vueper
      val_slides: 3,
      val_ratio: 1 / 4,
      val_fixed: "50px",
      is_portrait: false,
      url: process.env.VUE_APP_URL
    };
  },
  methods: {
    handleSearch(value) {
      if (value && value.length > 0) {
        // console.log(value)
        this.trueCourses = this.filteredMyCourses.filter(i => {
          const val = value.toLowerCase();
          // console.log(val)
          const nombre = i.nombre && i.nombre.toLowerCase();
          // console.log(nombre)
          if (val && nombre.indexOf(val) !== -1) {
            // console.log(nombre)
            return true;
          }
          return false;
        });
      } else {
        this.trueCourses = this.filteredMyCourses;
      }
    },
    link(id_course) {
      this.$router.push({
        name: "CourseDetails",
        params: { id: id_course }
      });
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
        // if (screen.width <= 360 && screen.height <= 760) {
        this.val_slides = 1;
        this.val_ratio = 0.8 / 1.2;
        //
        this.is_portrait = true;
        // }
      } else if (orientation === "landscape-primary") {
        // landscape mode
        this.val_slides = 2;
        this.val_ratio = 1 / 3;
        //
        this.is_portrait = false;
      }
    }
  },
  async mounted() {
    // if (screen.width <= 360 && screen.height <= 760) {
    //   this.val_slides = 1;
    //   this.val_ratio = 0.8 / 1.2;
    // }
    // Tamanño de pantalla
    if (window.innerHeight > window.innerWidth) {
      // alert("Please use Landscape!");
      this.val_slides = 1;
      this.val_ratio = 0.8 / 1.3;
      this.val_fixed = "25px";
      //
      this.is_portrait = true;
    } else {
      this.is_portrait = false;
      if (
        (screen.width <= 800 && screen.height <= 360) ||
        (screen.width <= 1080 && screen.height <= 810)
      ) {
        this.val_slides = 2;
        this.val_ratio = 1 / 3;
      }
    }
    window.addEventListener("orientationchange", this.handleOrientationChange);

    axios
      .get(this.url + `/api/cursos`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      })
      .then(response => {
        // console.log(response.data.cursos);
        this.myCourses = response.data.cursos;
        // console.log(response.data)
        this.filteredMyCourses = response.data.cursos;

        this.myCourses.forEach(el => {
          if (el.inscritos != undefined) {
            var inscritos = el.inscritos;

            for (let i = 0; i < inscritos.length; i++) {
              // console.log("inscritos: ", inscritos[i]);
              if (inscritos[i].$oid == localStorage.getItem("id")) {
                this.trueCourses.push(el);
              }
            }
          }
        });
        this.numero = this.trueCourses.length;
        // console.log("true: ", this.trueCourses);
      })
      .catch(e => {
        console.log(e);
      });
    // Obtenemos los cursos del usuario
    // console.log("asd: ",this.myCourses)
    var responseCourse = await axios.get(
      this.url + "/api/usuarios/" + localStorage.getItem("id"),
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }
    );
    this.userCourses = responseCourse.data;
    // Obteniando mis cursos
    // FORMA 1 DESORDENADA
    // console.log("asd: ", this.userCourses);
    // if (this.userCourses.cursos != undefined) {
    //   this.trueMyCourses = _.range(0, this.userCourses.cursos.length);
    //   var id_course = 0;
    //   // console.log("userCourses: ",this.userCourses)
    //   this.userCourses.cursos.forEach((el, index) => {
    //     id_course = el.$oid;
    //     this.trueMyCourses[index] = id_course;
    //     // console.log("el: ",id_course)
    //   });
    // }
    // END FORMA 1
    // 
    // FORMA 2 MISMO ORDEN DE TRUECOURSES
    if (this.userCourses.cursos != undefined) {
      this.trueMyCourses = _.range(0, this.trueCourses);
      var id_course = 0;
      // console.log("userCourses: ",this.userCourses)
      this.trueCourses.forEach((el, index) => {
        id_course = el._id.$oid;
        this.trueMyCourses[index] = id_course;
        // console.log("el: ",id_course)
      });
      // console.log("truemy: ",this.trueMyCourses)
    }
    // END FORMA 2
    // console.log("true :", this.trueMyCourses[0]);
    // hallando la cantidad de lecciones de cada curso
    this.cant_lessons = _.range(0, this.trueMyCourses.length);
    this.userCourses = responseCourse.data;
    for (let i = 0; i < this.trueMyCourses.length; i++) {
      var responseCant = await axios.get(
        this.url + "/api/lecciones?curso_id=" + this.trueMyCourses[i],
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }
      );
      this.cant_lessons[i] = responseCant.data.lecciones.length;
      // this.userCourses = responseCourse.data;
    }
    // console.log("cant: ", this.cant_lessons);
    // obteniendo el avance de las lecciones
    this.complete = _.range(0, this.trueMyCourses.length);
    for (let i = 0; i < this.trueMyCourses.length; i++) {
      var responseCompletar = await axios.get(
        this.url +
          "/api/cursos/" +
          this.trueMyCourses[i] +
          "/lecciones-completas/" +
          localStorage.getItem("id"),
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }
      );
      this.complete[i] = responseCompletar.data.length;
    }
    // console.log("complete: ",this.complete)
    // Obeniendo porcentajes
    this.percents = _.range(0, this.trueMyCourses.length);
    var porcentaje = 0;
    for (let i = 0; i < this.trueMyCourses.length; i++) {
      porcentaje = (this.complete[i] * 100) / this.cant_lessons[i];
      // console.log("porcent: ",porcentaje)
      this.percents[i] = porcentaje;
    }
    // console.log("porcentajes: ", this.percents);
  }
};
</script>

<style scoped>
.content-all{
  margin-bottom: 30vmin;
}
.main-title {
  /* margin-top: 12rem; */
  /* margin-top: 8rem; */
  padding-top: 17vmin;
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  /* font-size: 5rem; */
  font-size: 9vmin;
}
.content-title {
  /* margin-top: 12rem; */
  background: #d7dade;
}
.title {
  /* font-size: 30px; */
  font-size: 3vmin;
  font-weight: bold;
  font-family: "Fredoka One", cursive;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.title2 {
  margin-left: 10px;
  color: #78b829;
}
.article {
  font-family: "Poiret One", cursive !important;
  color: #1d2945;
  font-size: 2.7vmin;
}
.bars-slides {
  /* padding-bottom: 70px; */
  margin-top: 8rem;
}
.main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  /* align-items: center; */
  /* margin: 50px; */
}
.container-img {
  max-width: 150vmin;
  /* max-width: 22rem; */
  margin: 10px;
}
.img-curso {
  margin-top: 2rem;
  width: 100%;
}
.text-curso {
  padding-left: 10px;
  font-size: 3.5vmin;
  font-family: "Fredoka One", cursive;
  color: #78b829;
  text-align: center;
}
.cont-progress {
  margin-bottom: 3rem;
}
.blue {
  background: url(../../assets/img/LINEA-AZUL.png) no-repeat fixed center;
  background-position: 0 10px !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* height: 10% !important; */
  /* width: 100%; */
  background-size: 100% 98% !important;
}
</style>