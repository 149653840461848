<template>
  <div v-if="user" class="content-all">
    <NavMain />
    <section>
      <!-- <div class="container">
        <div class="row">
          <div class="main-title"></div>
        </div>
      </div> -->

      <div class="bar">
        <div class="">
          <form @submit.prevent="handleSubmit">
            <div class="bar-title">
              <div class="container">
                <div class="title2 row">
                  <div class="col">
                    Información <span class="text-green">general </span>
                  </div>
                  <div class="col-4 d-flex">
                    <button class="btn-update mx-1">Actualizar</button>
                    <button
                      @click="delete_acount"
                      type="button"
                      class="btn-delete mx-1"
                    >
                      Borrar Cuenta
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="container cont-data">
              <div class="row">
                <div class="col-7">
                  <div class="title-name my-2">
                    <p class="row">
                      <span class="col-3">Nombre:</span>
                      <!-- <span class="main-text">{{ formInput.userName }}</span> -->
                      <span class="col-9"
                        ><input
                          class="form-control main-text"
                          type="text"
                          v-model="formInput.userName"
                      /></span>
                      <!-- <span @click="editName"
                        ><i class="icon-pencil fas fa-pencil-alt"></i
                      ></span> -->
                    </p>
                  </div>
                  <div
                    class="input-errors"
                    v-for="error of v$.formInput.userName.$errors"
                    :key="error.$uid"
                  >
                    <div class="error">{{ error.$message }}</div>
                  </div>
                  <div>
                    <p class="title-name row">
                      <span class="col-3">Apellido:</span>
                      <!-- <span class="main-text" v-if="text.userLast">{{
                        user.apellido
                      }}</span> -->
                      <span class="col-9"
                        ><input
                          class="form-control main-text"
                          type="text"
                          v-model="formInput.userLast"
                      /></span>
                      <!-- <span @click="editLast"
                        ><i class="icon-pencil fas fa-pencil-alt"></i
                      ></span> -->
                    </p>
                    <div
                      class="input-errors"
                      v-for="error of v$.formInput.userLast.$errors"
                      :key="error.$uid"
                    >
                      <div class="error">{{ error.$message }}</div>
                    </div>

                    <p class="title-name row">
                      <span class="col-3">Correo:</span>
                      <!-- <span class="main-text" v-if="text.userEmail">{{
                        user.correo
                      }}</span> -->
                      <span class="col-9"
                        ><input
                          class="form-control main-text"
                          type="text"
                          v-model="formInput.userEmail"
                      /></span>
                      <!-- <span @click="editEmail"
                        ><i class="icon-pencil fas fa-pencil-alt"></i
                      ></span> -->
                    </p>
                    <div
                      class="input-errors"
                      v-for="error of v$.formInput.userEmail.$errors"
                      :key="error.$uid"
                    >
                      <div class="error">{{ error.$message }}</div>
                    </div>

                    <p class="title-name row">
                      <span class="col-3">Edad:</span>
                      <!-- <span class="main-text" v-if="text.userAge">{{
                        user.edad
                      }}</span> -->
                      <span class="col-9"
                        ><input
                          class="form-control main-text"
                          type="text"
                          v-model="formInput.userAge"
                      /></span>
                      <!-- <span @click="editAge"
                        ><i class="icon-pencil fas fa-pencil-alt"></i
                      ></span> -->
                    </p>
                    <div
                      class="input-errors"
                      v-for="error of v$.formInput.userAge.$errors"
                      :key="error.$uid"
                    >
                      <div class="error">{{ error.$message }}</div>
                    </div>
                    <p class="title-name row">
                      <span class="col-3">Rol:</span>
                      <span class="col-9 main-text">{{ user.rol }}</span>
                      <!-- <span v-if="form.userLast"
                    ><input
                      class="form-control"
                      type="text"
                      v-model="formInput.userLast"
                  /></span> -->
                      <!-- <span @click="editLast"
                    ><i class="icon-pencil fas fa-pencil-alt"></i
                  ></span> -->
                      <!-- <span  class="main-text" v-if="text.userState && user.estado == true"
                    >Activo</span
                  >
                  <span class="main-text" v-if="text.userState && user.estado == false"
                    >Inactivo</span
                  >
                  <span v-if="form.userState">
                    <select class="select-course" v-model="formInput.userState">
                      <option selected v-if="user.estado == true" value="true">
                        Activo
                      </option>
                      <option
                        selected
                        v-if="user.estado == false"
                        value="false"
                      >
                        Inactivo
                      </option>
                      <option v-if="user.estado == true" value="false">
                        Inactivo
                      </option>
                      <option v-if="user.estado == false" value="true">
                        Activo
                      </option>
                    </select>
                  </span>
                  <span @click="editState"
                    ><i class="icon-pencil fas fa-pencil-alt"></i
                  ></span> -->
                    </p>
                    <p class="title-name">
                      Cursos Inscritos:
                      <span
                        ><div>
                          <div
                            v-for="user_curso in user.cursos"
                            :key="user_curso"
                          >
                            <span v-for="curso in courses" :key="curso">
                              <span>
                                <li
                                  class="mx-5 main-text"
                                  v-if="user_curso.$oid == curso._id.$oid"
                                >
                                  <p>Nombre: {{ curso.nombre }}</p>
                                  <p>Autor: {{ curso.autor }}</p>
                                  <span v-for="test in tests" :key="test">
                                    <p
                                      v-if="
                                        test.usuario.$oid == user._id.$oid &&
                                          test.curso.$oid == curso._id.$oid
                                      "
                                    >
                                      Puntaje:
                                      <span
                                        class="result-apro"
                                        :class="{
                                          'result-repro': test.puntaje < 51
                                        }"
                                        >{{ test.puntaje }}</span
                                      >
                                    </p>
                                    <p
                                      v-if="
                                        test.usuario.$oid == user._id.$oid &&
                                          test.curso.$oid == curso._id.$oid
                                      "
                                    >
                                      Resultado:
                                      <span
                                        class="result-apro"
                                        v-if="test.estado == true"
                                        >Aprobado</span
                                      ><span class="result-repro" v-else
                                        >Reprobado</span
                                      >
                                    </p>
                                  </span>
                                </li>
                              </span>
                            </span>
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
                <div class="col-4 offset-1">
                  <div class="content-img">
                    <!-- <img v-if="user.imagen == 'User-Profile.png'"
                  class="video-img img-thumbnail img-responsive"
                  :src="this.url+'/api/archivos/User-Profile.png'"
                /> -->
                    <img
                      class="video-img img-thumbnail img-responsive"
                      :src="formInput.userImage"
                    />
                    <input
                      class="form-control form-control-lg btn-file"
                      type="file"
                      id="formFile"
                      @change="fileChange"
                      accept="image/*"
                    />
                  </div>
                  <!-- <button
                    @click="delete_acount"
                    type="button"
                    class="my-5 btn-delete"
                  >
                    Borrar Cuenta
                  </button> -->
                </div>
              </div>
            </div>
            <!-- <button class="btn-save-course">Guardar cambios</button> -->
          </form>
          <!-- <button class="btn-save-course" @click="question()"> Ver Cuestionarios</button> -->
          <!-- <router-link :to="{ name: 'QuestionDetails', params: { id: id } }">
            <button class="btn-save-course">Ver Cuestionarios</button>
          </router-link> -->

          <!-- <router-link to="/question" class="btn-save-course">Voy a home</router-link> -->
        </div>
      </div>
    </section>
  </div>
  <div class="text-center" v-else>Cargando myProfile...</div>
</template>

<script>
import NavMain from "@/components/NavMain.vue";
import formLessons from "@/components/formLessons.vue";
import axios from "axios";
// Vuelidate
import useValidate from "@vuelidate/core";
import { required, minLength, email } from "@vuelidate/validators";
export default {
  props: ["id"],
  components: {
    NavMain,
    formLessons
  },
  data() {
    return {
      v$: useValidate(),
      // courses: null,
      url: process.env.VUE_APP_URL,
      user: null,
      tests: null,
      cursoUsuario: null,
      courses: null,
      // lessons: null,
      trueLessons: null,
      text: {
        userName: true,
        userLast: true,
        userAge: true,
        userEmail: true,
        userState: true
        // courseDuration: true,
        // courseCard: true,
        // courseDescription: true,
      },
      form: {
        userName: false,
        userLast: false,
        userEmail: false,
        userAge: false,
        userState: false
        // courseDuration: false,
        // courseCard: false,
        // courseDescription: false,
      },
      formInput: {
        userName: "",
        userLast: "",
        userPass: "",
        userConfirmPass: "",
        userEmail: "",
        userCursos: [],
        userCreated: "",
        userAge: "",
        userState: "",
        userImage: "",
        userImageName: ""
      },
      selectedFile: null,
      selectedFileImg: null,
      selectedFileVideo: null,
      formLesson: [
        {
          id: "",
          // course_id: "",
          name: "",
          content: "",
          url: "",
          image: "",
          imageName: "",
          video: "",
          videoName: "",
          // Valores de Verdad
          showName: "",
          showInputName: "",
          showInputs: "",
          errorUrl: false
        }
      ],
      ifFormLesson: false,
      questions: null
    };
  },
  validations() {
    return {
      formInput: {
        userName: { required, minLength: minLength(4) },
        userLast: { required },
        userEmail: { required, email },
        userAge: { required }
        // courseDuration: "",
        // courseHours: { required },
        // courseMinutes: { required },
        // courseCard: { required },
        // courseDescription: { required, minLength: minLength(4) },
      }
      // formLesson: [
      //   {
      //     // id: "",
      //     // course_id: "",
      //     name: { required },
      //     content: { required },
      //     // image: "",
      //     // imageName: "",
      //     // video: "",
      //     // videoName: "",
      //     // Valores de Verdad
      //     // showName: "",
      //     // showInputName: "",
      //     // showInputs: "",
      //   },
      // ],
    };
  },
  methods: {
    async handleSubmit() {
      // validate
      this.v$.$touch();
      if (!this.v$.$invalid) {
        if (
          this.formInput.userName == this.user.nombre &&
          this.formInput.userLast == this.user.apellido &&
          this.formInput.userEmail == this.user.correo &&
          this.formInput.userAge == this.user.edad &&
          this.formInput.userState == this.user.estado &&
          this.formInput.userImageName == this.user.imagen
        ) {
          // console.log(this.course);
          this.$swal({
            icon: "info",
            title: "No se han realizado cambios",
            // text: "¿ Está seguro de CREAR este curso ?",
            // showCancelButton: true,
            confirmButtonText: "OK",
            confirmButtonColor: "#7AB929",
            // cancelButtonColor: "#CB3234",
            focusConfirm: true
            // cancelButtonText: "Cancelar",
          });
        } else {
          this.$swal({
            icon: "warning",
            title: "Cambiar los datos de mi Perfil!",
            text: "Está seguro de CAMBIAR los datos de perfil?",
            showCancelButton: true,
            confirmButtonText: "OK",
            confirmButtonColor: "#7AB929",
            cancelButtonColor: "#CB3234",
            focusConfirm: true,
            cancelButtonText: "Cancelar"
          }).then(async result => {
            if (result.isConfirmed) {
              // Editando Imagen de curso
              // editando imagen
              if (this.selectedFile != null) {
                var fd = new FormData();
                fd.append("archivo", this.selectedFile, this.selectedFile.name);
                // console.log(this.selectedFile);
                await axios
                  .post(this.url + "/api/archivos", fd, {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                    }
                  })
                  .then(response => {
                    // console.log(response);
                    this.formInput.userImageName = response.data.archivo;
                    // console.log(this.formInput.courseImageName)
                  })
                  .catch(e => {
                    console.log(e);
                  });
              }
              if (this.formInput.userState == "true") {
                this.formInput.userState = true;
              } else {
                this.formInput.userState = false;
              }
              // Editando datos ded curso
              await axios
                .put(
                  this.url + "/api/usuarios/" + localStorage.getItem("id"),
                  {
                    nombre: this.formInput.userName,
                    apellido: this.formInput.userLast,
                    correo: this.formInput.userEmail,
                    edad: this.formInput.userAge,
                    estado: this.formInput.userState,
                    imagen: this.formInput.userImageName
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                    }
                  }
                )
                .then(response => {
                  // console.log(response);
                })
                .catch(e => {
                  console.log(e);
                });
              this.$swal({
                title: "Actualizando Perfil",
                text:
                  "Porfavor espere mientras se actualizan los datos de Perfil",
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: false
              }).then(result => {
                // this.$router.push({ name: "MyCoursesTeacher" });
                this.$router.replace({
                  params: { id: localStorage.getItem("id") },
                  path: "/processing/:" + localStorage.getItem("id"),
                  name: "ProcessingUser"
                });
                this.$swal({
                  icon: "success",
                  title: "Los datos se han actualizado satisfactoriamente!",
                  confirmButtonText: "Gracias!",
                  confirmButtonColor: "#7AB929"
                });
                // location.reload();
                // .then((result) => {
                //   this.$router.push({ name: "Home" });
                // });
              });
            }
          });
        }
      }
      // else {
      //   alert("invalid");
      // }
    },
    fileChange(e) {
      this.selectedFile = e.target.files[0];
      const file = e.target.files[0];
      this.formInput.userImage = URL.createObjectURL(file);
      this.formInput.userImageName = file.name;
      // console.log(this.formInput.courseImageName)
    },
    editName() {
      if (this.text.userName == true) {
        this.text.userName = false;
        this.form.userName = true;
      } else {
        this.text.userName = true;
        this.form.userName = false;
      }
    },
    editLast() {
      if (this.text.userLast == true) {
        this.text.userLast = false;
        this.form.userLast = true;
      } else {
        this.text.userLast = true;
        this.form.userLast = false;
      }
    },
    editEmail() {
      if (this.text.userEmail == true) {
        this.text.userEmail = false;
        this.form.userEmail = true;
      } else {
        this.text.userEmail = true;
        this.form.userEmail = false;
      }
    },
    editAge() {
      if (this.text.userAge == true) {
        this.text.userAge = false;
        this.form.userAge = true;
      } else {
        this.text.userAge = true;
        this.form.userAge = false;
      }
    },
    editState() {
      if (this.text.userState == true) {
        this.text.userState = false;
        this.form.userState = true;
      } else {
        this.text.userState = true;
        this.form.userState = false;
      }
    },
    delete_acount() {
      // console.log("curso: ",this.id);
      // console.log("lecciones: ",this.trueLessons)
      // console.log("preguntas: ",this.questions)
      this.$swal({
        icon: "warning",
        title: "Borrar mi Cuenta!",
        text: "¿ Está seguro de Borrar esta cuenta ?",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        confirmButtonColor: "#7AB929",
        cancelButtonColor: "#CB3234",
        focusCancel: true,
        cancelButtonText: "Cancelar"
      }).then(async result => {
        if (result.isConfirmed) {
          // Borrando progreso
          // this.tests.forEach(async el => {
          //   if (el.usuario.$oid == localStorage.getItem("id")) {
          //     // console.log("test.el: ",el.usuario.$oid)
          //     await axios
          //       .delete(this.url + "/api/examenes/" + el._id.$oid, {
          //         headers: {
          //           Authorization: "Bearer " + localStorage.getItem("token")
          //         }
          //       })
          //       .then(response => {
          //         console.log(response);
          //       })
          //       .catch(e => {
          //         console.log(e);
          //       });
          //   }
          // });
          // Borrando examenes
          this.tests.forEach(async el => {
            if (el.usuario.$oid == localStorage.getItem("id")) {
              // console.log("test.el: ",el.usuario.$oid)
              await axios
                .delete(this.url + "/api/examenes/" + el._id.$oid, {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                  }
                })
                .then(response => {
                  // console.log(response);
                })
                .catch(e => {
                  console.log(e);
                });
            }
          });
          // Borrando inscribciones backend ??
          // this.courses.forEach(async (el) => {
          //   if (el.inscritos != undefined) {
          //     var inscritos = el.inscritos;
          //     for (let i = 0; i < inscritos.length; i++) {
          //       // console.log("inscritos: ", i, " ", inscritos[i]);
          //       if(inscritos[i].$oid == localStorage.getItem("id") ){
          //         // console.log("inscritos: ", inscritos[i].$oid)
          //         inscritos.splice(i,1)
          //         el.inscritos = inscritos
          //       }
          //     }
          //   }
          // });
          // console.log("cursos: ",this.courses)
          // Borrando Usuario
          await axios
            .delete(this.url + "/api/usuarios/" + localStorage.getItem("id"), {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
              }
            })
            .then(response => {
              // console.log(response);
            })
            .catch(e => {
              console.log(e);
            });
          // this.trueLessons.splice(index, 1);
          this.$swal({
            icon: "success",
            title: "Los datos se han eliminado satisfactoriamente!",
            confirmButtonText: "Gracias!",
            confirmButtonColor: "#7AB929"
          }).then(result => {
            this.$router.push({ name: "NLogin" });
          });
        }
      });
    }
    // question() {
    //   this.$router.push({ path: `${this.id}/question`})
    // }
  },

  async mounted() {
    var responseCourses = await axios.get(this.url + "/api/cursos", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    this.courses = responseCourses.data.cursos;
    // console.log("todos los cursos: ", this.courses);
    // Progreso
    // var responseCursoUsuario = await axios.get(this.url + "/api/cursos/"+"id_curso/"+"lecciones-completas/"+localStorage.getItem("id"), {
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("token")
    //   }
    // });
    // this.cursoUsuario = responseCursoUsuario.data;
    //
    var responseTests = await axios.get(this.url + "/api/examenes", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    this.tests = responseTests.data.examenes;
    // console.log("todos los examenes: ", this.tests);

    var responseUser = await axios.get(
      this.url + "/api/usuarios/" + localStorage.getItem("id"),
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }
    );
    this.user = responseUser.data;
    // console.log(this.user);
    // this.formInput.forEach(el => {
    this.formInput.userName = this.user.nombre;
    this.formInput.userLast = this.user.apellido;
    this.formInput.userPass = this.user.contrasena;
    this.formInput.userConfirmPass = this.user.confirmar_contrasena;
    this.formInput.userEmail = this.user.correo;
    this.formInput.userCursos = this.user.cursos;
    this.formInput.userCreated = this.user.created;
    this.formInput.userAge = this.user.edad;
    this.formInput.userState = this.user.estado;
    this.formInput.userImage = this.url + "/api/archivos/" + this.user.imagen;
    if (
      this.formInput.userImage ==
        this.url + "/api/archivos/perfil-default.jpg" &&
      this.user.avatar != undefined
    ) {
      // console.log("avatar: ",this.user.avatar)
      this.formInput.userImage = this.user.avatar;
    }
    this.formInput.userImageName = this.user.imagen;
    // });
  }
};
</script>

<style scoped>
.content-all {
  margin-bottom: 30vmin;
}
/* .main{
} */
.bar {
  padding-top: 8vmin;
  /* margin-top: 8rem; */
  /* padding-top: 6rem; */
}
.main-title {
  margin-top: 8rem;
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  /* font-size: 5vmin; */
}
.bar-title {
  margin-top: 12rem;
  background: #d7dade;
  margin-top: 3rem;
}
.title2 {
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  font-size: 5vmin;
  /* font-size: 3rem; */
  /* cursor: pointer; */
}
.container {
  /* margin-left: 20rem ; */
  margin-right: 20rem;
  /* padding-left: 0rem !important */
  /* padding-left: var(--bs-gutter-x,0px) !important; */
}
.main-text {
  font-family: "Poiret One", cursive !important;
  color: #1d2945;
  font-size: 2.5vmin;
}
.text-green {
  color: #7ab929 !important;
}
.text-size {
  word-wrap: break-Word;
}
/* Edit Form */
.title-name {
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  font-size: 3vmin;
}
.icon-pencil {
  position: absolute;
  right: auto;
  margin-top: 15px;
  border: 1px solid #1d2945;
  margin-left: 10px;
  /* font-size: 1.5rem; */
  font-size: 2.5vmin;
  cursor: pointer;
}
.delete-icon {
  color: red;
  cursor: pointer;
}
.content-img {
  /* display: block; */
  float: right;
  margin: auto;
  max-width: 25.5rem;
  max-height: 25.5.5rem;
}
.img-course {
  width: 25.5rem;
  height: 25.5rem;
}
.list-lessons {
  background: #d7dade;
  border-radius: 15px;
  padding: 15px;
}
/* FORM */
input,
textarea {
  display: inline;
  border-radius: 15px;
  color: #1d2945;
  /* text-transform: uppercase; */
  text-align: start;
}
.form-control {
  max-width: 40rem;
  font-size: 2.5vmin;
}
option,
select {
  font-size: 18px;
  font-family: "Fredoka One", cursive;
  border-radius: 15px;
}
/* .select-course {
  width: 4.5rem;
} */
.btn-update {
  margin: auto;
  color: #fff;
  background: #7ab929;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.4rem; */
  font-size: 2.4vmin;
  text-align: center;
  border: none;
}
.lesson-img {
  display: block;
  margin: auto;
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 15px;
}
.btn-lesson {
  background: #7c8393;
  color: #fff;
  display: block;
  margin: auto;
  margin-top: 10px;
  border-radius: 20px;
  text-align: center;
  font-family: "Fredoka One", cursive;
  font-size: 1rem;
  border: none;
  padding: 18px;
}
.btn-save-lesson {
  margin: auto;
  width: auto;
  color: #fff;
  background: #7ab929;
  padding: 5px;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  font-size: 1.5rem;
  text-align: center;
  display: inline-block;
  border: none;
}
.btn-form-lesson {
  color: #fff;
  background: #7ab929;
  padding: 5px;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  font-size: 1.5rem;
  text-align: center;
  display: inline-block;
  border: none;
  cursor: pointer;
}
.btn-redirect {
  margin: auto;
  width: auto;
  color: #fff;
  background: #7ab929;
  padding: 5px;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  font-size: 1.5rem;
  text-align: center;
  display: inline-block;
  border: none;
}
.result-apro {
  color: #7ab929;
  font-weight: bold;
}
.result-repro {
  color: #dc3545;
  font-weight: bold;
}
.btn-file {
  font-size: 2vmin;
}
.btn-delete {
  margin: auto;
  color: #fff;
  background: #cc0000;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.4rem; */
  font-size: 2.4vmin;
  text-align: center;
  border: none;
}
</style>