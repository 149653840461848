<template>a</template>

<script>
export default {
    props: ["id"],
  data() {
    this.$router.replace({
        path: '/coursesTeacher/:'+this.id,
        name: 'CourseDetailsTeacher'
    })
    return{}
  },
};
</script>

<style scoped>
</style>