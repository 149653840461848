<template>
  <!-- {{id_course}} -->

  <div class="py-2">
    <label class="subtitle"
      >Lecciones:
      <p class="main-text">
        Coloca de forma organizada las lecciones que tendrá el curso. No olvides
        colocar el material de apoyo para cada lección.
      </p></label
    >
  </div>
  <div class="form-lesson">
    <form @submit.prevent="handleSumbit">
      <div class="" v-for="(input, index) in inputs" :key="input.idInput">
        <div class="my-2">
          <div class="btn-new-lesson" @click="addInput(index)">
            Nueva lección
          </div>
          <span @click="putOff(index)">
            <i class="icon-put-off fas fa-times"></i>
          </span>
        </div>
        <hr class="hr-main-lessons" />
        <label :for="input.idInput">{{ input.labelInput }}</label>
        <input
          class="mx-1 form-control"
          :id="input.idInput"
          v-model="input.valueInput"
          required
          placeholder="Nombre de la lección..."
          type="text"
        />
        <div
          class="input-errors"
          v-for="error of v$.inputs[0].valueInput.$errors"
          :key="error.$uid"
        >
          <div class="error">{{ error.$message }}</div>
        </div>
        <!-- <label class="main-text">{{ input.labelInfograf }}</label> -->
        <div class="justify-content-center text-center" :class="(is_portrait == false && is_landscape == true) ? 'content-multimedia' : ''">
          <div class="img-lesson m-auto text-center">
            <img
              v-if="formLesson.ifImage"
              :src="input.valueImage"
              class="preview-image mt-3"
            />
            <input
              @change="previewFiles(input.idImage, $event)"
              :id="input.idImage"
              type="file"
              hidden
              accept="image/*"
            />
            <p class="main-text text-center">Ingrese una imagen.</p>
            <button
              class="btn-lesson"
              type="button"
              @click="chooseFiles(input.idImage)"
            >
              Imagen
            </button>
          </div>
          <div class="video-lesson m-auto text-center">
            <video
              v-if="formLesson.ifVideo"
              :src="input.valueVideo"
              controls
              width="320"
              height="190"
              class="mt-3 video-preview"
            >
              <!-- Lo sentimos. Este vídeo no puede ser reproducido en tu
                    navegador.<br />
                    La versión descargable está disponible en -->
              <!-- <a href="URL">Enlace</a>. -->
            </video>
            <input
              @change="previewFiles(input.idVideo, $event)"
              :id="input.idVideo"
              type="file"
              hidden
              accept="video/*"
            />
            <p class="main-text ">Ingrese un video desde su computador.</p>
            <button
              class="btn-lesson"
              type="button"
              @click="chooseFiles(input.idVideo)"
            >
              Video
            </button>
          </div>
        </div>
        <!-- <div class="url m-auto"> -->
        <label for="">YOUTUBE:</label>
        <div class="d-flex">
          
          <label class="">
            https://www.youtube.com/watch?v=
          </label>
          <input
            class="form-control lesson-nameform mx-3"
            type="text"
            v-model="input.valueUrl"
            :id="input.idUrl"
          />
          <p class="error" v-if="input.errorUrl == true">
            La direccion de video es incorrecta
          </p>
        </div>
        <label :for="input.idArea">{{ input.labelArea }}</label>
        <p class="main-text">
          El texto que se añadirá tiene que ser resumido y complementario al
          vídeo o imágen.
        </p>
        <textarea
          class="form-control"
          name=""
          :id="input.idArea"
          v-model="input.valueArea"
          cols="8"
          rows="3"
          
          placeholder="Descripción multimedia..."
        >
        </textarea>
        <!-- <div
          class="input-errors"
          v-for="error of v$.inputs[0].valueArea.$errors"
          :key="error.$uid"
        >
          <div class="error">{{ error.$message }}</div>
        </div> -->
        <label class="main-text">
          Inserte las secciones de la lección, pueden ser maximo 3:
        </label>
        <!--  -->
        <div
          class="row mx-5"
          v-for="(block, blockIndex) in input.blocks"
          :key="blockIndex"
        >
          <div class="cont-btn-block text-center my-2">
            <span class="next-block d-inline">Siguiente sección </span>
            <div class="d-inline" @click="addFormBlock(index)">
              <i class="plus-icon fas fa-plus"></i>
            </div>
            <span class="d-inline" @click="putOffBlock(index, blockIndex)">
              <i class="icon-put-off fas fa-times"></i>
            </span>
          </div>
          <div class="col-6 form-grpup my-1">
            <div class="text-lessons">Nombre:</div>
            <input
              v-model="block.titulo"
              type="text"
              autocomplete="off"
              class="form-control"
              placeholder="Nombre de la sección"
              required
            />
            <div class="text-lessons">Concepto: :</div>
            <input
              type="text"
              class="form-control"
              v-model="block.concepto"
              required
              placeholder="Concepto de la sección..."
            />
            <div class="text-lessons">TIKTOK:</div>
            <input
              v-model="block.enlaceTikTok"
              type="text"
              autocomplete="off"
              class="form-control"
              placeholder="id del video TIKTOK..."
            />
          </div>
          <div class="col-6 my-1">
            <div class="text-lessons">Contenido:</div>
            <textarea
              class="form-control"
              v-model="block.contenido"
              cols="8"
              rows="3"
              required
              placeholder="Contenido de la sección..."
            >
            </textarea>
            <div class="text-lessons">Definición:</div>
            <textarea
              class="form-control"
              v-model="block.definicion"
              cols="8"
              rows="3"
              required
              placeholder="Definición de la sección..."
            >
            </textarea>
          </div>
          <hr class="hr-blocks my-3" />
        </div>
      </div>
      <button class="btn-save my-3">Guardar Lecciones</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
// Vuelidate
import useValidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
export default {
  props: ["idCourse"],
  data() {
    return {
      v$: useValidate(),
      formLesson: {
        course_id: this.idCourse,
        ifImage: false,
        ifVideo: false,
        errorUrl: false
      },
      counter: 0,
      inputs: [
        {
          idInput: "name0",
          labelInput: "Nombre de la lección",
          valueInput: "",
          idArea: "content0",
          labelArea: "Texto complementario",
          valueArea: "",
          // labelInfograf:
          //   "Video e Infografías: Es necesario colocar al menos un video por archivo ó Enlace web",
          idUrl: "url0",
          valueUrl: "",
          ifUrl: false,
          errorUrl: false,
          idImage: "img0",
          valueImage: "",
          fileImage: "",
          nameImage: "",
          idVideo: "video0",
          valueVideo: "",
          fileVideo: "",
          nameVideo: "",
          blocks: [
            {
              titulo: "",
              contenido: "",
              enlaceTikTok: "",
              concepto: "",
              definicion: ""
            }
          ]
        }
      ],

      url: process.env.VUE_APP_URL,
      // 
      is_portrait: false,
      is_landscape: false
    };
  },
  validations() {
    return {
      inputs: [
        {
          valueInput: { required },
          // valueArea: { required, minLength: minLength(4) }
        }
      ]
    };
  },
  methods: {
    async handleSumbit() {
      // this.formLesson.course_id = this.id_course
      // validate
      this.v$.$touch();
      if (!this.v$.$invalid) {
        var re = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/watch\?v=([^&]+)/m;
        // this.inputs.forEach(el => {
        //   var urlID = el.valueUrl;
        //   if (urlID != "") {
        //     if (urlID.match(re)) {
        //       this.formLesson.errorUrl = false;
        //       el.errorUrl = false;
        //     } else {
        //       // alert("direccion invalida");
        //       el.errorUrl = true;
        //       this.formLesson.errorUrl = true;
        //     }
        //   } else {
        //     this.formLesson.errorUrl = false;
        //     el.errorUrl = false;
        //   }
        // });
        if (this.formLesson.errorUrl === false) {
          this.$swal({
            icon: "warning",
            title: "Crear Lecciones!",
            text: "¿ Está seguro de CREAR todas las lecciones añadidas ?",
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            confirmButtonColor: "#7AB929",
            cancelButtonColor: "#CB3234",
            focusCancel: true,
            cancelButtonText: "Cancelar"
          }).then(async result => {
            if (result.isConfirmed) {
              // Guardando lecciones si es que se lleno el formulario de lecciones
              // Guardando imagenes de cada leccion añadida
              this.inputs.forEach(async el => {
                if (el.fileImage != "") {
                  var fd = new FormData();
                  fd.append("archivo", el.fileImage, el.fileImage.name);
                  // console.log(this.selectedFile);
                  await axios
                    .post(this.url + "/api/archivos", fd, {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Credentials": true,
                        "Access-Control-Allow-Methods":
                          "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                        Authorization: "Bearer " + localStorage.getItem("token")
                      }
                    })
                    .then(response => {
                      // console.log(response);
                      el.nameImage = response.data.archivo;
                      // console.log(this.formCourse.imageName)
                      // return response.data.archivo
                    })
                    .catch(e => {
                      console.log(e);
                    });
                }
                if (el.fileVideo != "") {
                  // Guardando videos de cada leccion añadida
                  var fd = new FormData();
                  fd.append("archivo", el.fileVideo, el.fileVideo.name);
                  await axios
                    .post(this.url + "/api/archivos", fd, {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Credentials": true,
                        "Access-Control-Allow-Methods":
                          "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                        Authorization: "Bearer " + localStorage.getItem("token")
                      }
                    })
                    .then(response => {
                      // console.log(response);
                      el.nameVideo = response.data.archivo;
                      // console.log(this.formCourse.imageName)
                    })
                    .catch(e => {
                      console.log(e);
                    });
                }

                //Guardando datos de las lecciones
                // console.log("id del curso: ", this.formLesson.course_id);
                //
                // var urlID = el.valueUrl;
                // var start = urlID.indexOf("=");
                // el.valueUrl = el.valueUrl.substring(start + 1);
                // if (el.valueUrl.indexOf("&") != -1) {
                //   var end = el.valueUrl.indexOf("&");
                //   el.valueUrl = el.valueUrl.substring(0, end);
                // }
                // console.log(el.valueUrl);
                await axios
                  .post(
                    this.url + `/api/lecciones`,
                    {
                      curso: this.formLesson.course_id,
                      // icono: this.iconSelect,
                      nombre: document.getElementById(el.idInput).value,
                      contenido: document.getElementById(el.idArea).value,
                      // tipo_leccion: this.type,
                      imagen: el.nameImage,
                      video: el.nameVideo,
                      videoUrl: el.valueUrl,
                      bloques: el.blocks
                    },
                    {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        Authorization: "Bearer " + localStorage.getItem("token")
                      }
                    }
                  )
                  .then(response => {
                    // console.log(response);
                  })
                  .catch(e => {
                    console.log(e);
                  });
              });
              this.$swal({
                title: "Guardando Lecciones",
                text:
                  "Porfavor espere mientras se guardan los datos de las lecciones",
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: false
              }).then(result => {
                this.$swal({
                  icon: "success",
                  title: "Los datos se han guardado satisfactoriamente!",
                  confirmButtonText: "Gracias!",
                  confirmButtonColor: "#7AB929"
                }).then(result => {
                  this.$router.push({ name: "Home" });
                });
              });
            }
          });
        }
      }
      // else{
      //   alert('invalid')
      // }
    },
    addInput(indice) {
      this.counter = this.counter++;
      this.inputs.push({
        idInput: `name${++this.counter}`,
        labelInput: "Nombre de la lección",
        valueInput: "",
        idArea: `content${++this.counter}`,
        labelArea: "Texto complementario",
        valueArea: "",
        // labelInfograf: "Video e Infografías",
        idUrl: `url${++this.counter}`,
        valueUrl: "",
        errorUrl: false,
        idImage: `img${++this.counter}`,
        valueImage: "",
        fileImage: "",
        nameImage: "",
        idVideo: `video${++this.counter}`,
        valueVideo: "",
        fileVideo: "",
        nameVideo: ""
      });
      //   this.indexInputs = indice;
    },
    putOff(index) {
      this.counter = this.counter--;
      this.inputs.splice(index, 1);
    },
    chooseFiles(id) {
      document.getElementById(id).click();
    },
    previewFiles(id, event) {
      var input = document.getElementById(id);
      var file = input.value.split("\\");
      var fileName = file[file.length - 1];
      // console.log(fileName);
      this.inputs.forEach(el => {
        if (el.idImage == id) {
          var image = event.target.files[0];
          el.valueImage = URL.createObjectURL(image);
          el.fileImage = event.target.files[0];
          this.formLesson.ifImage = true;
          // console.log(el.valueImage)
        }
        if (el.idVideo == id) {
          var video = event.target.files[0];
          el.valueVideo = URL.createObjectURL(video);
          el.fileVideo = event.target.files[0];
          this.formLesson.ifVideo = true;
          // console.log(el.valueImage)
        }
      });
    },
    urlInput(index) {
      // for (let i = 0; i < this.inputs.length; i++) {
      if (this.inputs[index].ifUrl === false) {
        this.inputs[index].ifUrl = true;
      } else {
        this.inputs[index].ifUrl = false;
      }
    },
    addFormBlock(index) {
      if (this.inputs[index].blocks.length < 3) {
        this.inputs[index].blocks.push({
          titulo: "",
          contenido: "",
          enlaceTikTok: "",
          concepto: "",
          definicion: ""
        });
      }
      // console.log(this.inputs[index].blocks.length)
    },
    putOffBlock(index, blockIndex) {
      this.inputs[index].blocks.splice(blockIndex, 1);
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
        // if (screen.width <= 360 && screen.height <= 760) {
        // this.val_ratio = 1 / 2.5;
        // }
        this.is_portrait = true
        this.is_landscape = false
      } else if (orientation === "landscape-primary") {
        // landscape mode
        this.is_landscape = true
        this.is_portrait = false
      }
    }
  },
  mounted(){
    if (window.innerHeight > window.innerWidth) {
      // alert("Please use Landscape!");
      this.is_portrait = true
    } else {
      this.is_portrait = false
      this.is_landscape = true
      // if (
      //   (screen.width <= 800 && screen.height <= 360) ||
      //   (screen.width <= 1080 && screen.height <= 810)
      // ) {
      //   this.is_landscape = true
      // }
    }
    window.addEventListener("orientationchange", this.handleOrientationChange);
  }
};
</script>

<style scoped>
.hr-main-lessons {
  color: #7ab929;
  font-weight: bold;
  height: 5px;
  background-color: #7ab929;
  border: none;
}
.hr-blocks {
  color: red;
  font-weight: bold;
  height: 5px;
  background-color: red;
  border: none;
}
.main-text {
  font-family: "Poiret One", cursive !important;
  color: #1d2945;
  /* font-size: 25px; */
  font-size: 3vmin;
  word-wrap: break-word;
}
.subtitle {
  /* font-size: 1.5rem; */
  font-size: 2.5vmin;
  font-weight: bold;
}
.lesson-nameform {
  max-width: 64rem;
  /* font-size: 2vmin; */
}
label {
  color: #1d2945 !important;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.1rem; */
  font-size: 2.8vmin;
}
input,
textarea {
  margin-top: 5px;
  border-radius: 15px;
  font-family: "Poiret One", cursive;
  color: #1d2945;
  /* font-size: 18px; */
  font-size: 2.5vmin !important;
  font-weight: bold;
  /* text-transform: uppercase; */
  text-align: start;
}
option,
select {
  /* font-size: 18px; */
  font-size: 3vmin;
  font-family: "Fredoka One", cursive;
  border-radius: 15px;
}
.content-multimedia{
  display: flex;
}
.preview-image {
  /* max-width: 327px; */
  /* max-height: 182px; */
  max-width: 20vmax;
  /* max-height: 25vmin; */
  border-radius: 15px;
}
.video-preview{
  max-width: 20vmax;
  max-height: 20vmin;
}
input[type="file"] {
  border: 2px solid #fff;
  font-family: "Poiret One", cursive !important;
  font-weight: bold !important;
  font-size: 2.5vmin;
}
.btn-form-lesson {
  color: #fff;
  background: #7ab929;
  padding: 5px;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  font-size: 1.5rem;
  text-align: center;
  display: inline-block;
  border: none;
  cursor: pointer;
}
.btn-new-lesson {
  color: #fff;
  background: #7ab929;
  /* padding: 5px; */
  padding: 0px 0.5vmin 0px 0.5vmin;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  /* font-size: 1rem; */
  font-size: 2.5vmin;
  text-align: center;
  display: inline-block;
  border: none;
  cursor: pointer;
}
.icon-put-off {
  margin-left: 10px;
  margin-bottom: 5px;
  background: red;
  color: #fff;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  /* padding-left: 4px; */
  /* padding-right: 4px; */
  /* font-size: 28px; */
  padding: 0px 0.5vmin 0px 0.5vmin;
  font-size: 3.8vmin;
  vertical-align: middle;
  border-radius: 5px;
  cursor: pointer;
  /* max-width: 20px; */
}
.text-lessons {
  font-family: "Fredoka One", cursive !important;
  color: #1d2945;
  /* font-size: 20px; */
  font-size: 3vmin;
}
.btn-lesson {
  background: #7c8393;
  color: #fff;
  display: block;
  margin: auto;
  border-radius: 20px;
  text-align: center;
  font-family: "Fredoka One", cursive;
  /* font-size: 1rem; */
  font-size: 2.5vmin;
  border: none;
  /* padding: 18px; */
  padding: 1vmin;
  
}
.btn-save {
  margin: auto;
  background: #1d2945;
  color: #fff;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.5rem; */
  font-size: 3vmin;
  border: none;
  display: block;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  /* font-size: 20px; */
}
.url-input {
  text-transform: lowercase;
}
.cont-btn-block {
  float: right;
  text-align: right;
}
.next-block {
  /* font-size: 20px; */
  font-size: 3vmin;
  font-weight: bold;
  font-family: "Fredoka One", cursive;
}
.plus-icon {
  margin-left: 10px;
  margin-bottom: 5px;
  background: #7ab929;
  color: #fff;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  /* padding-left: 4px; */
  /* padding-right: 4px; */
  /* font-size: 28px; */
  padding: 0px 0.5vmin 0px 0.5vmin;
  font-size: 3.5vmin;
  vertical-align: middle;
  border-radius: 5px;
  cursor: pointer;
  /* max-width: 20px; */
}
input[type="text"],
textarea {
  /* border: 2px solid #fff; */
  font-family: "Fredoka One", cursive !important;
  /* font-weight: bold !important; */
  /* font-size: 1rem; */
  font-size: 3vmin;
  color: #1d2945;
}
</style>