<template>
  <NavMain />
  <section class="content-all">
    <div class="container">
      <div class="row">
        <div class="main-title">{{ title }}</div>
        <p class="main-text">Crea los cuestionarios para los cursos</p>
      </div>
    </div>
    <div class="bar-title">
      <div class="container">
        <div @click="newQuestion" class="title2">
          Crear <span class="text-green">Cuestionario </span>
          <span v-show="formQuestion == false">+</span>
          <span v-show="formQuestion == true">-</span>
        </div>
      </div>
    </div>
    <!-- <div class="container"> -->
      <!-- <div class="main-title">{{ title }}</div>
      <div class="main-text">Crea los cuestionarios para los cursos</div>
      <div class="bar-title">
        <div class="container">
          <div @click="newQuestion" class="title2">
            Crear <span class="text-green">Cuestionario </span>
            <span v-show="formQuestion == false">+</span>
            <span v-show="formQuestion == true">-</span>
          </div>
        </div>
      </div> -->
      <add-question
        v-bind:id="id"
        v-bind:nameCourse="curso.nombre"
        v-if="formQuestion"
      ></add-question>
      <div class="bar-title my-3">
        <div class="container">
          <div @click="viewAllQuestions" class="title2">
            Cuestionarios <span class="text-green">actuales </span>
            <span v-show="viewQuestion == false">+</span>
            <span v-show="viewQuestion == true">-</span>
          </div>
        </div>
      </div>
      <ViewAllQuestions :id="id" v-if="viewQuestion"></ViewAllQuestions>
    <!-- </div> -->
  </section>
</template>

<script>
import NavMain from "@/components/NavMain.vue";
import axios from "axios";
import AddQuestion from "./component/addQuestion.vue";
import ViewAllQuestions from "./component/viewAllQuestions.vue";

export default {
  name: "Questions",
  props: ["id"],
  components: {
    NavMain,
    AddQuestion,
    ViewAllQuestions
  },

  data() {
    return {
      formQuestion: false,
      viewQuestion: false,
      title: null,
      curso: null,
      url: process.env.VUE_APP_URL
    };
  },

  async mounted() {
    let cursoResponse = await axios.get(this.url + "/api/cursos/" + this.id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    this.curso = cursoResponse.data;
    this.title = "Cuestionarios de " + this.curso.nombre;
  },

  methods: {
    newQuestion() {
      this.formQuestion = !this.formQuestion;
      // console.log(this.formQuestion);
    },
    viewAllQuestions() {
      this.viewQuestion = !this.viewQuestion;
    }
  }
};
</script>

<style scoped>
.content-all{
  margin-bottom: 80vmin;
}
.main-title {
  margin-top: 20vmin;
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  /* font-size: 5rem; */
  font-size: 8vmin;
}
.bar-title {
  background: #d7dade;
  /* margin-top: 3rem; */
}
.title2 {
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  /* font-size: 3rem; */
  font-size: 5vmin;
  cursor: pointer;
}
.main-text {
  font-family: "Poiret One", cursive !important;
  color: #1d2945;
  /* font-size: 30px; */
  font-size: 3vmin;
}
.text-green {
  color: #7ab929 !important;
}
.text-size {
  word-wrap: break-Word;
}
/* Edit Form */
.title-name {
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  /* font-size: 2rem; */
  font-size: 3vmin;
}
.icon-pencil {
  position: absolute;
  right: auto;
  margin-top: 15px;
  border: 1px solid #1d2945;
  margin-left: 10px;
  font-size: 1.5rem;
  cursor: pointer;
}
.content-img {
  /* display: block; */
  float: right;
  margin: auto;
  max-width: 25.5rem;
  max-height: 25.5.5rem;
}
.img-course {
  width: 25.5rem;
  height: 25.5rem;
}
.list-lessons {
  background: #d7dade;
  border-radius: 15px;
  padding: 15px;
}
/* FORM */
input,
textarea {
  display: inline;
  border-radius: 15px;
  color: #1d2945;
  /* text-transform: uppercase; */
  text-align: start;
}
.form-control {
  max-width: 40rem;
}
option,
select {
  font-size: 18px;
  font-family: "Fredoka One", cursive;
  border-radius: 15px;
}
.select-course {
  width: 4.5rem;
}
.btn-save-course {
  margin: auto;
  width: auto;
  color: #fff;
  background: #7ab929;
  padding: 5px;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  font-size: 1.5rem;
  text-align: center;
  display: inline-block;
  border: none;
}
.lesson-img {
  display: block;
  margin: auto;
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 15px;
}
.btn-lesson {
  background: #7c8393;
  color: #fff;
  display: block;
  margin: auto;
  margin-top: 10px;
  border-radius: 20px;
  text-align: center;
  font-family: "Fredoka One", cursive;
  font-size: 1rem;
  border: none;
  padding: 18px;
}
.btn-save-lesson {
  margin: auto;
  width: auto;
  color: #fff;
  background: #7ab929;
  padding: 5px;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  font-size: 1.5rem;
  text-align: center;
  display: inline-block;
  border: none;
}
</style>