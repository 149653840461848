<template>
  <NavMain />
  <div v-if="course" class="content-all">
    <section>
      <!-- <div class="container">
        <div class="row">
          <div class="main-title">Todos mis cursos</div>
          <p class="main-text">Verifica o crea tus cursos</p>
        </div>
      </div> -->
      <div class="bar">
        <form @submit.prevent="handleSumbitCourse">
          <div class="bar-title">
            <div class="container">
              <div class="title2 row">
                <div class="col ">
                  Curso: <span class="text-green">{{ course.nombre }} </span>
                </div>
                <div class="col d-flex btns-options">
                  <button class="btn-save-course mx-1">
                    Actualizar
                  </button>
                  <button class="btn-questions mx-1" @click="goQuestions">
                    Cuestionarios
                  </button>
                  <button
                    @click="delete_course"
                    type="button"
                    class="btn-delete-course mx-1"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-7">
                <div class="title-name">
                  Nombre:
                  <span class="main-text" v-if="text.courseName">{{
                    course.nombre
                  }}</span>
                  <span v-if="form.courseName"
                    ><input
                      class="form-control"
                      type="text"
                      v-model="formInput.courseName"
                  /></span>
                  <span @click="editName"
                    ><i class="icon-pencil fas fa-pencil-alt"></i
                  ></span>
                </div>
                <div
                  class="input-errors"
                  v-for="error of v$.formInput.courseName.$errors"
                  :key="error.$uid"
                >
                  <div class="error">{{ error.$message }}</div>
                </div>
                <div>
                  <div class="title-name">
                    Inscritos:
                    <span class="main-text">{{ students }}</span>
                  </div>
                  <div class="title-name">
                    Autor:
                    <span class="main-text" v-if="text.courseAuthor">{{
                      course.autor
                    }}</span>
                    <span v-if="form.courseAuthor"
                      ><input
                        class="form-control"
                        type="text"
                        v-model="formInput.courseAuthor"
                    /></span>
                    <span @click="editAuthor"
                      ><i class="icon-pencil fas fa-pencil-alt"></i
                    ></span>
                  </div>
                  <div
                    class="input-errors"
                    v-for="error of v$.formInput.courseAuthor.$errors"
                    :key="error.$uid"
                  >
                    <div class="error">{{ error.$message }}</div>
                  </div>
                  <div class="title-name">
                    Duración:
                    <span class="main-text" v-if="text.courseDuration">{{
                      course.duracion
                    }}</span>
                    <span class="main-text" v-if="form.courseDuration"
                      >Horas:
                      <select
                        class="select-course"
                        onfocus="this.size=4;"
                        onblur="this.size=1;"
                        onchange="this.size=1; this.blur();"
                        v-model="formInput.courseHours"
                      >
                        <option v-for="h in 23" :key="h">
                          {{ h }}
                        </option>
                      </select>
                      Minutos:
                      <select
                        class="select-course"
                        aria-label=".form-select-lg example"
                        onfocus="this.size=4;"
                        onblur="this.size=1;"
                        onchange="this.size=1; this.blur();"
                        v-model="formInput.courseMinutes"
                      >
                        <option selected>{{ formInput.courseMinutes }}</option>
                        <option v-for="m in 59" :key="m">{{ m }}</option>
                      </select></span
                    >
                    <span @click="editDuration"
                      ><i class="icon-pencil fas fa-pencil-alt"></i
                    ></span>
                  </div>
                  <div class="title-name">
                    Cartilla:
                    <span v-if="text.courseCard" class="text-size main-text">{{
                      course.cartilla
                    }}</span>
                    <span v-if="form.courseCard">
                      <textarea
                        class="form-control"
                        v-model="formInput.courseCard"
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                      ></textarea>
                    </span>
                    <span @click="editCard"
                      ><i class="icon-pencil fas fa-pencil-alt"></i
                    ></span>
                  </div>
                  <div
                    class="input-errors"
                    v-for="error of v$.formInput.courseCard.$errors"
                    :key="error.$uid"
                  >
                    <div class="error">{{ error.$message }}</div>
                  </div>
                  <div class="title-name">
                    Descripción:
                    <span
                      v-if="text.courseDescription"
                      class="text-size main-text"
                      >{{ course.descripcion }}
                    </span>
                    <span v-if="form.courseDescription">
                      <textarea
                        class="form-control"
                        v-model="formInput.courseDescription"
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                      ></textarea>
                    </span>
                    <span @click="editDescription"
                      ><i class="icon-pencil fas fa-pencil-alt"></i
                    ></span>
                  </div>
                  <div
                    class="input-errors"
                    v-for="error of v$.formInput.courseDescription.$errors"
                    :key="error.$uid"
                  >
                    <div class="error">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="col-4 offset-1">
                <div class="content-img">
                  <img
                    class="video-img img-thumbnail img-responsive"
                    :src="formInput.courseImage"
                  />
                  <input
                    class="form-control form-control-lg"
                    type="file"
                    id="formFile"
                    @change="fileChange"
                    accept="image/*"
                  />
                </div>
              </div>
            </div>

            <!-- <button class="btn-save-course">Guardar cambios del curso</button> -->
          </div>
        </form>

        <!-- <button class="btn-save-course" @click="question()"> Ver Cuestionarios</button> -->
        <!-- <div class="container"></div> -->

        <!-- <router-link to="/question" class="btn-save-course">Voy a home</router-link> -->
        <div class="container">
          <div class="">
            <div class="title2 text-green">Lecciones</div>
            <button
              type="button"
              @click="insertFormLesson"
              class="btn-form-lesson"
            >
              Agregar nueva lección
              <span v-show="ifFormLesson == false">+</span
              ><span v-show="ifFormLesson == true">-</span>
            </button>
          </div>
          <div class="list-lessons">
            <ul>
              <li v-for="(lesson, index) in trueLessons" :key="lesson._id">
                <form
                  @submit.prevent="handleSubmitLesson(lesson._id.$oid, index)"
                >
                  <p class="title-name">
                    <span v-for="form in formLesson" :key="form">
                      <span v-if="lesson._id.$oid == form.id"
                        ><span v-if="form.showName"
                          >{{ index }}. {{ lesson.nombre }}</span
                        ></span
                      >
                    </span>
                    <span v-for="form in formLesson" :key="form">
                      <span
                        v-if="form.id == lesson._id.$oid && form.showInputName"
                        ><input
                          class="form-control lesson-form-text lesson-nameform"
                          type="text"
                          v-model="form.name"
                          required
                        />
                        <div
                          class="main-text"
                          v-for="error of v$.formLesson[0].name.$errors"
                          :key="error.$uid"
                        >
                          <div class="error">{{ error.$message }}</div>
                        </div>
                      </span>
                    </span>
                    <span class="mx-1" @click="editLesson(lesson._id.$oid)"
                      ><i class="icon-pencil fas fa-pencil-alt"></i></span
                    ><span class="mx-1" @click="delete_lesson(index)"
                      ><i class="delete-icon fas fa-trash-alt"></i
                    ></span>
                  </p>
                  <div
                    class="form-lesson"
                    v-for="form in formLesson"
                    :key="form"
                  >
                    <span v-if="form.id == lesson._id.$oid && form.showInputs">
                      <div class="lesson-subtitle">
                        <div
                          class="justify-content-center text-center"
                          :class="
                            is_portrait == false && is_landscape == true
                              ? 'content-multimedia'
                              : ''
                          "
                        >
                          <div class="m-auto">
                            <p class="text-center">
                              Imagen: {{ lesson.imagen }}
                            </p>
                            <img
                              v-if="lesson.imagen != '' || isChangeImage"
                              class="lesson-img img-thumbnail img-responsive"
                              :src="form.image"
                              alt=""
                            />
                            <input
                              @change="previewFiles(form.id + 'img', $event)"
                              :id="form.id + 'img'"
                              type="file"
                              hidden
                              accept="image/*"
                            />
                            <button
                              class="btn-lesson"
                              type="button"
                              @click="chooseFiles(form.id + 'img')"
                            >
                              Imagen
                            </button>
                          </div>
                          <div class="m-auto">
                            <p class="text-center">Video: {{ lesson.video }}</p>
                            <video
                              v-if="lesson.video != '' || isChangeVideo"
                              class="lesson-img"
                              :src="form.video"
                              controls
                              width="320"
                              height="190"
                            ></video>
                            <input
                              @change="previewFiles(form.id + 'video', $event)"
                              :id="form.id + 'video'"
                              type="file"
                              hidden
                              accept="video/*"
                            />
                            <button
                              class="btn-lesson"
                              type="button"
                              @click="chooseFiles(form.id + 'video')"
                            >
                              Video
                            </button>
                          </div>
                        </div>
                        <p class="my-3">
                          YOUTUBE:
                          <span class="d-flex text-youtube">
                            https://www.youtube.com/watch?v=
                            <input
                              class="form-control lesson-form-text lesson-nameform"
                              type="text"
                              v-model="form.url"
                            />
                          </span>
                        </p>

                        <!-- <p class="error" v-if="form.errorUrl == true">
                          El campo debe ser un enlace de youtube.com
                        </p> -->
                        <p>
                          Descripción multimedia :
                          <textarea
                            class="form-control lesson-form-text description-area"
                            placeholder="Descripción del video o imágen..."
                            v-model="form.content"
                            name=""
                            id=""
                            cols="30"
                            rows="2"
                          ></textarea>
                        </p>
                        <div
                          class="input-errors"
                          v-for="error of v$.formLesson[0].content.$errors"
                          :key="error.$uid"
                        >
                          <div class="error">{{ error.$message }}</div>
                        </div>
                        <div class="d-flex">
                          <label class="lesson-subtitle">
                            Inserte las secciones de la lección, pueden ser
                            maximo 3:
                          </label>
                          <div
                            @click="addFormBlock(index)"
                            v-if="form.bloques.length == 0"
                          >
                            <i class="plus-icon fas fa-plus"></i>
                          </div>
                        </div>
                        <hr class="hr-main-blocks my-3" />
                        <div v-if="form.bloques.length != 0">
                          <div
                            class="row mx-5"
                            v-for="(bloque, blockIndex) in form.bloques"
                            :key="blockIndex"
                          >
                            <div class="cont-btn-block text-center">
                              <span class="next-block d-inline"
                                >Siguiente sección
                              </span>
                              <div
                                class="d-inline"
                                @click="addFormBlock(index)"
                              >
                                <i class="plus-icon fas fa-plus"></i>
                              </div>
                              <span
                                class="d-inline"
                                @click="putOffBlock(index, blockIndex)"
                              >
                                <i class="icon-put-off fas fa-times"></i>
                              </span>
                            </div>
                            <div class="col-6 form-grpup my-1">
                              <div class="text-lessons">Nombre:</div>
                              <input
                                v-model="bloque.titulo"
                                type="text"
                                autocomplete="off"
                                class="form-control"
                                placeholder="Nombre de la sección"
                                required
                              />
                              <div class="text-lessons">Concepto: :</div>
                              <input
                                class="form-control"
                                v-model="bloque.concepto"
                                required
                                placeholder="Concepto de la sección..."
                              />
                              <div class="text-lessons">TIKTOK:</div>
                              <input
                                v-model="bloque.enlaceTikTok"
                                type="text"
                                autocomplete="off"
                                class="form-control"
                                placeholder="id del video TIKTOK..."
                              />
                            </div>
                            <div class="col-6 my-1">
                              <div class="text-lessons">Contenido:</div>
                              <textarea
                                class="form-control"
                                v-model="bloque.contenido"
                                cols="8"
                                rows="3"
                                required
                                placeholder="Contenido de la sección..."
                              >
                              </textarea>
                              <div class="text-lessons">Definición:</div>
                              <textarea
                                class="form-control"
                                v-model="bloque.definicion"
                                cols="8"
                                rows="3"
                                required
                                placeholder="Definición de la sección..."
                              >
                              </textarea>
                            </div>
                            <hr class="hr-blocks my-3" />
                          </div>
                        </div>
                        <!-- <div class="row" v-else></div> -->
                      </div>
                      <div class="text-center">
                        <button class="btn-save-lesson">
                          Actualizar lección
                        </button>
                      </div>
                      <!--  -->
                    </span>
                  </div>
                </form>
              </li>
            </ul>
            <!-- <p class="main-text">
              Para agregar mas lecciones, pulse el boton de "Formulario de
              lecciones"
            </p> -->

            <form-lessons v-if="ifFormLesson" :idCourse="id" />
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="text-center" v-else>Cargando Course Details Teacher...</div>
</template>

<script>
import NavMain from "@/components/NavMain.vue";
import formLessons from "@/components/formLessons.vue";
import axios from "axios";
// Vuelidate
import useValidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
export default {
  props: ["id"],
  components: {
    NavMain,
    formLessons
  },
  data() {
    return {
      v$: useValidate(),
      course: null,
      students: 0,
      lessons: null,
      trueLessons: null,
      isMessage: true,
      isSave: false,
      btnBlock: true,
      text: {
        courseName: true,
        courseAuthor: true,
        courseDuration: true,
        courseCard: true,
        courseDescription: true
      },
      form: {
        courseName: false,
        courseAuthor: false,
        courseDuration: false,
        courseCard: false,
        courseDescription: false
      },
      formInput: {
        courseName: "",
        courseAuthor: "",
        // courseDuration: "",
        courseHours: "",
        courseMinutes: "",
        courseCard: "",
        courseDescription: "",
        courseImage: "",
        courseImageName: ""
      },
      selectedFile: null,
      selectedFileImg: null,
      selectedFileVideo: null,
      formLesson: [
        {
          id: "",
          // course_id: "",
          name: "",
          content: "",
          url: "",
          image: "",
          imageName: "",
          video: "",
          videoName: "",
          // Valores de Verdad
          showName: "",
          showInputName: "",
          showInputs: "",
          errorUrl: false,
          bloques: [
            {
              titulo: "",
              contenido: "",
              enlaceTikTok: "",
              concepto: "",
              definicion: ""
            }
          ]
        }
      ],
      ifFormLesson: false,
      questions: null,
      url: process.env.VUE_APP_URL,
      is_portrait: false,
      is_landscape: false,
      isChangeImage: false,
      isChangeVideo: false
    };
  },
  validations() {
    return {
      formInput: {
        courseName: { required, minLength: minLength(4) },
        courseAuthor: { required },
        // courseDuration: "",
        courseHours: { required },
        courseMinutes: { required },
        courseCard: { required },
        courseDescription: { required, minLength: minLength(4) }
      },
      formLesson: [
        {
          // id: "",
          // course_id: "",
          name: {},
          content: {}
          // image: "",
          // imageName: "",
          // video: "",
          // videoName: "",
          // Valores de Verdad
          // showName: "",
          // showInputName: "",
          // showInputs: "",
        }
      ]
    };
  },
  methods: {
    async handleSumbitCourse() {
      // validate
      this.v$.$touch();
      if (!this.v$.$invalid) {
        if (
          this.formInput.courseName == this.course.nombre &&
          this.formInput.courseAuthor == this.course.autor &&
          this.formInput.courseHours +
            "h" +
            " " +
            this.formInput.courseMinutes +
            "m" ==
            this.course.duracion &&
          this.formInput.courseCard == this.course.cartilla &&
          this.formInput.courseDescription == this.course.descripcion &&
          this.formInput.courseImageName == this.course.imagen
        ) {
          // console.log(this.course);
          this.$swal({
            icon: "info",
            title: "No se han realizado cambios",
            // text: "¿ Está seguro de CREAR este curso ?",
            // showCancelButton: true,
            confirmButtonText: "OK",
            confirmButtonColor: "#7AB929",
            // cancelButtonColor: "#CB3234",
            focusConfirm: true
            // cancelButtonText: "Cancelar",
          });
        } else {
          this.$swal({
            icon: "warning",
            title: "Cambiar los datos de Curso!",
            text: "Está seguro de CAMBIAR los datos de este curso?",
            showCancelButton: true,
            confirmButtonText: "OK",
            confirmButtonColor: "#7AB929",
            cancelButtonColor: "#CB3234",
            focusConfirm: true,
            cancelButtonText: "Cancelar"
          }).then(async result => {
            if (result.isConfirmed) {
              // Editando Imagen de curso
              // editando imagen
              if (this.selectedFile != null) {
                var fd = new FormData();
                fd.append("archivo", this.selectedFile, this.selectedFile.name);
                // console.log(this.selectedFile);
                await axios
                  .post(this.url + "/api/archivos", fd, {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Credentials": true,
                      "Access-Control-Allow-Methods":
                        "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                      Authorization: "Bearer " + localStorage.getItem("token")
                    }
                  })
                  .then(response => {
                    // console.log(response);
                    this.formInput.courseImageName = response.data.archivo;
                    // console.log(this.formInput.courseImageName)
                  })
                  .catch(e => {
                    console.log(e);
                  });
              }
              // Editando datos del curso
              await axios
                .put(
                  this.url + "/api/cursos/" + this.id,
                  {
                    nombre: this.formInput.courseName,
                    autor: this.formInput.courseAuthor,
                    cartilla: this.formInput.courseCard,
                    duracion:
                      this.formInput.courseHours +
                      "h" +
                      " " +
                      this.formInput.courseMinutes +
                      "m",
                    descripcion: this.formInput.courseDescription,
                    imagen: this.formInput.courseImageName
                  },
                  {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Credentials": true,
                      "Access-Control-Allow-Methods":
                        "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                      Authorization: "Bearer " + localStorage.getItem("token")
                    }
                  }
                )
                .then(response => {
                  // console.log(response);
                })
                .catch(e => {
                  console.log(e);
                });
              this.$swal({
                title: "Actualizando Curso",
                text:
                  "Porfavor espere mientras se actualizan los datos del curso",
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: false
              }).then(result => {
                // this.$router.push({ name: "MyCoursesTeacher" });
                this.$router.replace({
                  params: { id: this.id },
                  path: "/processing/:" + this.id,
                  name: "Processing"
                });
                this.$swal({
                  icon: "success",
                  title: "Los datos se han actualizado satisfactoriamente!",
                  confirmButtonText: "Gracias!",
                  confirmButtonColor: "#7AB929"
                });
                // location.reload();
                // .then((result) => {
                //   this.$router.push({ name: "Home" });
                // });
              });
            }
          });
        }
      }
      // else {
      //   alert("invalid");
      // }
    },
    async handleSubmitLesson(id, index) {
      // console.log("form: ", this.formLesson[1].bloques.length);
      // console.log("true: ",this.trueLessons[1].bloques.toString())
      // validate
      this.isMessage = true;
      this.v$.$touch();
      if (!this.v$.$invalid) {
        var re = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/watch\?v=([^&]+)/m;
        var urlID = this.formLesson[index].url;
        // console.log("urlID: ", urlID);
        // if (urlID.match(re) || urlID == "") {
          this.formLesson[index].errorUrl = false;
          // el.errorUrl = false
          // Pregunta si hay cambios, si hay cambios , ejecuta la actualizacion
          if (this.formLesson[index].bloques != undefined) {
            // console.log("tamaño form: ",this.formLesson[index].bloques.length)
            // console.log("tamaño tamaño: ",this.trueLessons[index].bloques.length)
            for (let i = 0; i < this.formLesson[index].bloques.length; i++) {
              if (
                this.trueLessons[index].bloques != undefined &&
                this.formLesson[index].bloques.length ==
                  this.trueLessons[index].bloques.length
              ) {
                if (
                  this.formLesson[index].bloques[i].titulo ===
                    this.trueLessons[index].bloques[i].titulo &&
                  this.formLesson[index].bloques[i].contenido ===
                    this.trueLessons[index].bloques[i].contenido &&
                  this.formLesson[index].bloques[i].enlaceTikTok ===
                    this.trueLessons[index].bloques[i].enlaceTikTok &&
                  this.formLesson[index].bloques[i].concepto ==
                    this.trueLessons[index].bloques[i].concepto &&
                  this.formLesson[index].bloques[i].definicion ===
                    this.trueLessons[index].bloques[i].definicion
                ) {
                  // console.log("son iguales:", i)
                } else {
                  // console.log("no son iguales:", i);
                  this.isMessage = false;
                }
              } else {
                //
                this.isSave = true;
                this.isMessage = false;
              }
            }
          }
          if (
            this.formLesson[index].name == this.trueLessons[index].nombre &&
            this.formLesson[index].content ==
              this.trueLessons[index].contenido &&
            this.formLesson[index].url == this.trueLessons[index].videoUrl &&
            this.formLesson[index].imageName ==
              this.trueLessons[index].imagen &&
            this.formLesson[index].videoName == this.trueLessons[index].video
          ) {
            // this.isMessage = true;
            //
          } else {
            this.isMessage = false;
          }
          if (this.isMessage === true) {
            this.$swal({
              icon: "info",
              title: "No se han realizado cambios",
              // text: "¿ Está seguro de CREAR este curso ?",
              // showCancelButton: true,
              confirmButtonText: "OK",
              confirmButtonColor: "#7AB929",
              // cancelButtonColor: "#CB3234",
              focusConfirm: true
              // cancelButtonText: "Cancelar",
            });
          } else {
            // Pregunta si esta seguro de cambiar los datos
            this.$swal({
              icon: "warning",
              title: "Cambiar los datos de la Lección!",
              text: "Está seguro de CAMBIAR los datos de esta lección?",
              showCancelButton: true,
              confirmButtonText: "OK",
              confirmButtonColor: "#7AB929",
              cancelButtonColor: "#CB3234",
              focusConfirm: true,
              cancelButtonText: "Cancelar"
            }).then(async result => {
              if (result.isConfirmed) {
                // Actializando Imagen si se cambio
                if (this.selectedFileImg != null) {
                  var fd = new FormData();
                  fd.append(
                    "archivo",
                    this.selectedFileImg,
                    this.selectedFileImg.name
                  );
                  // console.log(this.selectedFile);
                  await axios
                    .post(this.url + "/api/archivos", fd, {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Credentials": true,
                        "Access-Control-Allow-Methods":
                          "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                        Authorization: "Bearer " + localStorage.getItem("token")
                      }
                    })
                    .then(response => {
                      // console.log(response);
                      this.formLesson[index].imageName = response.data.archivo;
                      // console.log(this.formInput.courseImageName)
                    })
                    .catch(e => {
                      console.log(e);
                    });
                }
                // actualizando video si se cambio
                if (this.selectedFileVideo != null) {
                  var fd = new FormData();
                  fd.append(
                    "archivo",
                    this.selectedFileVideo,
                    this.selectedFileVideo.name
                  );
                  await axios
                    .post(this.url + "/api/archivos", fd, {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Credentials": true,
                        "Access-Control-Allow-Methods":
                          "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                        Authorization: "Bearer " + localStorage.getItem("token")
                      }
                    })
                    .then(response => {
                      // console.log(response);
                      this.formLesson[index].videoName = response.data.archivo;
                      // console.log(this.formInput.courseImageName)
                    })
                    .catch(e => {
                      console.log(e);
                    });
                }
                var urlID = this.formLesson[index].url;
                var start = urlID.indexOf("=");
                this.formLesson[index].url = this.formLesson[
                  index
                ].url.substring(start + 1);
                if (this.formLesson[index].url.indexOf("&") != -1) {
                  var end = this.formLesson[index].url.indexOf("&");
                  this.formLesson[index].url = this.formLesson[
                    index
                  ].url.substring(0, end);
                }
                // console.log(this.formLesson[index].url)
                // Actializando datos de leccion
                await axios
                  .put(
                    this.url + "/api/lecciones/" + id,
                    {
                      // curso_id: this.formLesson.course_id,
                      // icono: this.form.iconSelect,
                      nombre: this.formLesson[index].name,
                      contenido: this.formLesson[index].content,

                      // tipo_leccion: this.form.type,
                      imagen: this.formLesson[index].imageName,
                      video: this.formLesson[index].videoName,
                      videoUrl: this.formLesson[index].url
                      // bloques: this.formLesson[index].bloques
                    },
                    {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                      }
                    }
                  )
                  .then(response => {
                    // console.log(response);
                    // location.reload();
                    // this.courses = response.data
                  })
                  .catch(e => {
                    console.log(e);
                  });
                // Actualiza bloques
                if (this.isSave === true) {
                  // var size_first_blocks = 0
                  // var pos_block = 0;
                  //  Pregunta si no tiene bloques
                  for (
                    let i = 0;
                    i < this.formLesson[index].bloques.length;
                    i++
                  ) {
                    if (this.trueLessons[index].bloques != undefined) {
                      // CUANDO TIENE BLOQUES
                      if (this.trueLessons[index].bloques[i] != undefined) {
                        // Actualiza
                        await axios
                          .put(
                            this.url + "/api/lecciones/" + id + "/bloques/" + i,
                            {
                              titulo: this.formLesson[index].bloques[i].titulo,
                              contenido: this.formLesson[index].bloques[i]
                                .contenido,
                              enlaceTikTok: this.formLesson[index].bloques[i]
                                .enlaceTikTok,
                              concepto: this.formLesson[index].bloques[i]
                                .concepto,
                              definicion: this.formLesson[index].bloques[i]
                                .definicion
                            },
                            {
                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("token")
                              }
                            }
                          )
                          .then(response => {
                            // console.log(response);
                            // location.reload();
                            // this.courses = response.data
                          })
                          .catch(e => {
                            console.log(e);
                          });
                      } else {
                        await axios
                          .post(
                            this.url + "/api/lecciones/" + id + "/bloques/" + i,
                            {
                              titulo: this.formLesson[index].bloques[i].titulo,
                              contenido: this.formLesson[index].bloques[i]
                                .contenido,
                              enlaceTikTok: this.formLesson[index].bloques[i]
                                .enlaceTikTok,
                              concepto: this.formLesson[index].bloques[i]
                                .concepto,
                              definicion: this.formLesson[index].bloques[i]
                                .definicion
                            },
                            {
                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("token")
                              }
                            }
                          )
                          .then(response => {
                            // console.log(response);
                          })
                          .catch(e => {
                            console.log(e);
                          });
                      }
                    } else {
                      // CUANDO NO TIENE BLOQUES
                      await axios
                        .post(
                          this.url + "/api/lecciones/" + id + "/bloques/" + i,
                          {
                            titulo: this.formLesson[index].bloques[i].titulo,
                            contenido: this.formLesson[index].bloques[i]
                              .contenido,
                            enlaceTikTok: this.formLesson[index].bloques[i]
                              .enlaceTikTok,
                            concepto: this.formLesson[index].bloques[i]
                              .concepto,
                            definicion: this.formLesson[index].bloques[i]
                              .definicion
                          },
                          {
                            headers: {
                              Authorization:
                                "Bearer " + localStorage.getItem("token")
                            }
                          }
                        )
                        .then(response => {
                          // console.log(response);
                        })
                        .catch(e => {
                          console.log(e);
                        });
                    }
                  }
                  // console.log("no tiene bloques");
                } else {
                  for (
                    let i = 0;
                    i < this.formLesson[index].bloques.length;
                    i++
                  ) {
                    // console.log("bloque: ",this.formLesson[index].bloques[i])
                    await axios
                      .put(
                        this.url + "/api/lecciones/" + id + "/bloques/" + i,
                        {
                          titulo: this.formLesson[index].bloques[i].titulo,
                          contenido: this.formLesson[index].bloques[i]
                            .contenido,
                          enlaceTikTok: this.formLesson[index].bloques[i]
                            .enlaceTikTok,
                          concepto: this.formLesson[index].bloques[i].concepto,
                          definicion: this.formLesson[index].bloques[i]
                            .definicion
                        },
                        {
                          headers: {
                            Authorization:
                              "Bearer " + localStorage.getItem("token")
                          }
                        }
                      )
                      .then(response => {
                        // console.log(response);
                        // location.reload();
                        // this.courses = response.data
                      })
                      .catch(e => {
                        console.log(e);
                      });
                  }
                }
                this.$swal({
                  title: "Actualizando Lección",
                  text:
                    "Porfavor espere mientras se actualizan los datos de la lección",
                  timer: 3000,
                  timerProgressBar: true,
                  showConfirmButton: false
                }).then(result => {
                  // this.$router.push({ name: "MyCoursesTeacher" });
                  // this.$router.go(0)
                  this.$router.replace({
                    params: { id: this.id },
                    path: "/processing/:" + this.id,
                    name: "Processing"
                  });

                  this.$swal({
                    icon: "success",
                    title: "Los datos se han actualizado satisfactoriamente!",
                    confirmButtonText: "Gracias!",
                    confirmButtonColor: "#7AB929"
                  });
                  // location.reload();
                  // .then((result) => {
                  //   this.$router.push({ name: "Home" });
                  // });
                });
              }
            });
          }
        // } else {
        //   this.formLesson[index].errorUrl = true;
        // }
      } else {
        alert("invalid");
      }
    },
    goQuestions() {
      this.$router.push({ name: "QuestionDetails", params: { id: this.id } });
    },
    fileChange(e) {
      this.selectedFile = e.target.files[0];
      const file = e.target.files[0];
      this.formInput.courseImage = URL.createObjectURL(file);
      this.formInput.courseImageName = file.name;
      // console.log(this.formInput.courseImageName)
    },
    previewFiles(id, event) {
      var input = document.getElementById(id);
      // console.log("preF_input: ", input)
      var file = input.value.split("\\");
      // console.log("preF_file: ", file)
      var fileName = file[file.length - 1];
      // console.log(fileName);
      // console.log("formlesson: ", this.formLesson);
      this.formLesson.forEach(el => {
        if (el.id + "img" == id) {
          // console.log("img")
          this.selectedFileImg = event.target.files[0];
          const image = event.target.files[0];
          el.image = URL.createObjectURL(image);
          el.imageName = fileName;
          this.isChangeImage = true;
          // this.formLesson.ifImage = true;
          // console.log(el.valueImage)
        }
        if (el.id + "video" == id) {
          // console.log("video")
          this.selectedFileVideo = event.target.files[0];
          // console.log("selectedFV: ",this.selectedFileVideo)
          const video = event.target.files[0];
          // console.log("video_evento: ",video)
          el.video = URL.createObjectURL(video);
          el.videoName = fileName;
          this.isChangeVideo = true;
          // this.formLesson.ifVideo = true;
          // console.log(el.valueImage)
        }
      });
    },
    chooseFiles(id) {
      // console.log("chooseFlies: ",id)
      document.getElementById(id).click();
    },
    editName() {
      if (this.text.courseName == true) {
        this.text.courseName = false;
        this.form.courseName = true;
      } else {
        this.text.courseName = true;
        this.form.courseName = false;
      }
    },
    editAuthor() {
      if (this.text.courseAuthor == true) {
        this.text.courseAuthor = false;
        this.form.courseAuthor = true;
      } else {
        this.text.courseAuthor = true;
        this.form.courseAuthor = false;
      }
    },
    editDuration() {
      if (this.text.courseDuration == true) {
        this.text.courseDuration = false;
        this.form.courseDuration = true;
      } else {
        this.text.courseDuration = true;
        this.form.courseDuration = false;
      }
    },
    editCard() {
      if (this.text.courseCard == true) {
        this.text.courseCard = false;
        this.form.courseCard = true;
      } else {
        this.text.courseCard = true;
        this.form.courseCard = false;
      }
    },
    editDescription() {
      if (this.text.courseDescription == true) {
        this.text.courseDescription = false;
        this.form.courseDescription = true;
      } else {
        this.text.courseDescription = true;
        this.form.courseDescription = false;
      }
    },
    editLesson(id) {
      this.formLesson.forEach(el => {
        if (el.id == id) {
          // console.log(el)
          if (el.showName == true) {
            el.showName = false;
            el.showInputName = true;
            el.showInputs = true;
          } else {
            el.showName = true;
            el.showInputName = false;
            el.showInputs = false;
          }
        }
      });
    },
    insertFormLesson() {
      if (this.ifFormLesson == false) {
        this.ifFormLesson = true;
      } else {
        this.ifFormLesson = false;
      }
    },
    delete_lesson(index) {
      // console.log(this.trueLessons);
      this.$swal({
        icon: "warning",
        title: "Borrar Leccion!",
        text: "¿ Está seguro de Borrar esta lección ?",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        confirmButtonColor: "#7AB929",
        cancelButtonColor: "#CB3234",
        focusCancel: true,
        cancelButtonText: "Cancelar"
      }).then(async result => {
        if (result.isConfirmed) {
          await axios
            .delete(
              this.url + "/api/lecciones/" + this.trueLessons[index]._id.$oid,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  Authorization: "Bearer " + localStorage.getItem("token")
                }
              }
            )
            .then(response => {
              // console.log(response);
            })
            .catch(e => {
              console.log(e);
            });
          this.trueLessons.splice(index, 1);
          this.$swal({
            icon: "success",
            title: "Los datos se han eliminado satisfactoriamente!",
            confirmButtonText: "Gracias!",
            confirmButtonColor: "#7AB929"
          });
        }
      });
    },
    delete_course() {
      // console.log("curso: ",this.id);
      // console.log("lecciones: ",this.trueLessons)
      // console.log("preguntas: ",this.questions)
      this.$swal({
        icon: "warning",
        title: "Borrar Curso!",
        text:
          "¿ Está seguro de Borrar este curso y todo su contenido incluyendo sus lecciones y cuestionarios ?",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        confirmButtonColor: "#7AB929",
        cancelButtonColor: "#CB3234",
        focusCancel: true,
        cancelButtonText: "Cancelar"
      }).then(async result => {
        if (result.isConfirmed) {
          // Borrando lecciones
          this.trueLessons.forEach(async el => {
            await axios
              .delete(this.url + "/api/lecciones/" + el._id.$oid, {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  Authorization: "Bearer " + localStorage.getItem("token")
                }
              })
              .then(response => {
                // console.log(response);
              })
              .catch(e => {
                console.log(e);
              });
          });
          // Borrando preguntas
          this.questions.forEach(async el => {
            await axios
              .delete(this.url + "/api/preguntas/" + el._id.$oid, {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  Authorization: "Bearer " + localStorage.getItem("token")
                }
              })
              .then(response => {
                // console.log(response);
              })
              .catch(e => {
                console.log(e);
              });
          });
          // Borrando Curso
          await axios
            .delete(this.url + "/api/cursos/" + this.id, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + localStorage.getItem("token")
              }
            })
            .then(response => {
              // console.log(response);
            })
            .catch(e => {
              console.log(e);
            });
          // this.trueLessons.splice(index, 1);
          this.$swal({
            icon: "success",
            title: "Los datos se han eliminado satisfactoriamente!",
            confirmButtonText: "Gracias!",
            confirmButtonColor: "#7AB929"
          }).then(result => {
            this.$router.push({ name: "Home" });
          });
        }
      });
    },
    addFormBlock(index) {
      if (this.formLesson[index].bloques.length < 3) {
        this.formLesson[index].bloques.push({
          titulo: "",
          contenido: "",
          enlaceTikTok: "",
          concepto: "",
          definicion: ""
        });
      }
    },
    putOffBlock(index, blockIndex) {
      if (this.trueLessons[index].bloques != undefined) {
        if (this.trueLessons[index].bloques[blockIndex] != undefined) {
          this.$swal({
            icon: "warning",
            title: "Borrar Sección!",
            text: "Está seguro de BORRAR esta sección?",
            showCancelButton: true,
            confirmButtonText: "OK",
            confirmButtonColor: "#7AB929",
            cancelButtonColor: "#CB3234",
            focusConfirm: true,
            cancelButtonText: "Cancelar"
          }).then(async result => {
            if (result.isConfirmed) {
              this.formLesson[index].bloques.splice(blockIndex, 1);
              axios
                .delete(
                  this.url +
                    "/api/lecciones/" +
                    this.formLesson[index].id +
                    "/bloques/" +
                    blockIndex,
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                    }
                  }
                )
                .then(response => {
                  // console.log(response);
                })
                .catch(e => {
                  console.log(e);
                });
              this.$swal({
                icon: "success",
                text: "La Lección se ha borrado satisfactoriamente",
                confirmButtonColor: "#8FD53B",
                focusConfirm: true
              });
            }
          });
        } else {
          this.formLesson[index].bloques.splice(blockIndex, 1);
        }
      } else {
        this.formLesson[index].bloques.splice(blockIndex, 1);
      }
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
        // if (screen.width <= 360 && screen.height <= 760) {
        // this.val_ratio = 1 / 2.5;
        // }
        this.is_portrait = true;
        this.is_landscape = false;
      } else if (orientation === "landscape-primary") {
        // landscape mode
        this.is_landscape = true;
        this.is_portrait = false;
      }
    }
  },

  async mounted() {
    //
    if (window.innerHeight > window.innerWidth) {
      // alert("Please use Landscape!");
      this.is_portrait = true;
    } else {
      this.is_portrait = false;
      this.is_landscape = true;
      // if (
      //   (screen.width <= 800 && screen.height <= 360) ||
      //   (screen.width <= 1080 && screen.height <= 810)
      // ) {
      //   this.is_landscape = true
      // }
    }
    window.addEventListener("orientationchange", this.handleOrientationChange);
    //
    var responseCourse = await axios.get(this.url + "/api/cursos/" + this.id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    this.course = responseCourse.data;
    this.students = this.course.inscritos.length;
    // console.log(this.students);
    this.formInput.courseName = responseCourse.data.nombre;
    this.formInput.courseAuthor = responseCourse.data.autor;
    let cadena = responseCourse.data.duracion;
    let indiceHoras = cadena.indexOf("h");
    let horas = cadena.substring(0, indiceHoras);
    this.formInput.courseHours = horas;
    let indiceMinutos1 = cadena.indexOf(" ");
    let indiceMinutos2 = cadena.indexOf("m");
    let minutos = cadena.substring(indiceMinutos1, indiceMinutos2);
    minutos = minutos.substring(1);
    this.formInput.courseMinutes = minutos;
    this.formInput.courseCard = responseCourse.data.cartilla;
    this.formInput.courseDescription = responseCourse.data.descripcion;
    this.formInput.courseImage =
      this.url + "/api/archivos/" + responseCourse.data.imagen;
    this.formInput.courseImageName = responseCourse.data.imagen;
    // Lecciones
    var responseLessons = await axios.get(this.url + "/api/lecciones", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    this.lessons = responseLessons.data.lecciones;
    var arrayLessons = [];
    // console.log(this.lessons);
    this.lessons.forEach(el => {
      if (el.curso.$oid == this.course.id) {
        arrayLessons.push(el);
      }
    });
    this.trueLessons = arrayLessons;
    // console.log("arraylessons: ", arrayLessons);
    // console.log(this.trueLessons);
    for (let i = 0; i < this.trueLessons.length; i++) {
      // sacando los blockes de cada leccion
      var bloques = [];
      if (this.trueLessons[i].bloques != undefined) {
        this.trueLessons[i].bloques.forEach(el => {
          // console.log("b: ",el)
          bloques.push({
            titulo: el.titulo,
            contenido: el.contenido,
            enlaceTikTok: el.enlaceTikTok,
            concepto: el.concepto,
            definicion: el.definicion
          });
        });
        // console.log("bloques: ", bloques);
      }
      // bloques.forEach(el => {
      //   // var bloqu = []
      //   bloques = el
      //   console.log("bloques: ",bloques)
      // });
      if (arrayLessons[i].imagen != "") {
        this.formLesson.push({
          id: this.trueLessons[i]._id.$oid,
          // course_id: this.trueLessons[i].curso_id,
          name: this.trueLessons[i].nombre,
          content: this.trueLessons[i].contenido,
          // url:
          //   "https://www.youtube.com/watch?v=" + this.trueLessons[i].videoUrl,
          url: this.trueLessons[i].videoUrl,
          image: this.url + "/api/archivos/" + this.trueLessons[i].imagen,
          imageName: this.trueLessons[i].imagen,
          video: this.url + "/api/archivos/" + this.trueLessons[i].video,
          videoName: this.trueLessons[i].video,
          showName: true,
          showInputName: false,
          showInputs: false,
          bloques: bloques
        });
        // console.log("url: ",  this.trueLessons[i].videoUrl)
        // if(this.trueLessons[i].videoUrl == ""){
        //   this.formLesson[i].url = "asd"
        //   console.log("url: ",  this.formLesson[i].url)
        // }
      } else {
        this.formLesson.push({
          id: this.trueLessons[i]._id.$oid,
          // course_id: this.trueLessons[i].curso_id,
          name: this.trueLessons[i].nombre,
          content: this.trueLessons[i].contenido,
          // url:
          //   "https://www.youtube.com/watch?v=" + this.trueLessons[i].videoUrl,
          url: this.trueLessons[i].videoUrl,
          image: "",
          video: this.url + "/api/archivos/" + this.trueLessons[i].video,
          videoName: this.trueLessons[i].video,
          showName: true,
          showInputName: false,
          showInputs: false,
          bloques: bloques
        });
        // if(this.trueLessons[i].videoUrl == ""){
        //   this.formLesson[i].url = ""
        //   console.log("url: ",  this.formLesson[i].url)
        // }
      }
    }

    // ELIMINA EL PRIMER ELEMENTO DE UN ARRAY
    this.formLesson.shift();
    // console.log("formlesson all: ", this.formLesson);
    // questions
    var responseQuestions = await axios.get(
      this.url + "/api/preguntas?curso_id=" + this.id,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }
    );
    this.questions = responseQuestions.data.preguntas;
  }
};
</script>

<style scoped>
.content-all {
  margin-bottom: 50vmin;
}
.hr-main-blocks {
  color: #7ab929;
  font-weight: bold;
  height: 5px;
  background-color: #7ab929;
  border: none;
}
.hr-blocks {
  color: red;
  font-weight: bold;
  height: 5px;
  background-color: red;
  border: none;
}
/* .main-title {
  margin-top: 12rem;
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  font-size: 5rem;
} */
.bar {
  /* margin-top: 12rem; */
  margin-top: 18vmin;
}
.bar-title {
  /* margin-top: 12rem; */
  background: #d7dade;
  margin-top: 3rem;
}
.title2 {
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  /* font-size: 3rem; */
  font-size: 5vmin;
  /* cursor: pointer; */
}
.main-text {
  font-family: "Poiret One", cursive !important;
  color: #1d2945 !important;
  /* font-size: 30px; */
  font-size: 3.5vmin;
}
.lesson-subtitle {
  font-family: "Fredoka One", cursive;
  color: #1d2945 !important;
  /* font-size: 1.4rem; */
  font-size: 2.8vmin;
  font-weight: bold;
}
.lesson-form-text {
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  /* font-size: 0.9rem; */
  font-size: 2.5vmin;
}
.text-green {
  color: #7ab929 !important;
}
.text-size {
  word-wrap: break-Word;
}
/* Edit Form */
.title-name {
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  /* font-size: 2rem; */
  font-size: 3vmin;
}
.icon-pencil {
  /* position: absolute; */
  right: auto;
  margin-top: 14px;
  /* border: 1px solid #1d2945; */
  border-radius: 5px;
  /* padding: 3px; */
  padding: 0px 0.5vmin 0px 0.5vmin;
  color: #fff;
  background-color: #1d2945;
  margin-left: 10px;
  /* font-size: 1.6rem; */
  font-size: 3.4vmin;
  cursor: pointer;
}
.delete-icon {
  /* margin: 2px; */
  color: #ffffff;
  cursor: pointer;
  /* font-size: 1.8rem; */
  font-size: 3.5vmin;
  background-color: #cc0000;
  border-radius: 5px;
  /* padding: 4px; */
  /* padding: 0.4vmin; */
  padding: 0px 0.5vmin 0px 0.5vmin;
}
.plus-icon {
  margin-left: 10px;
  margin-bottom: 5px;
  background: #7ab929;
  color: #fff;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  padding: 0px 0.5vmin 0px 0.5vmin;
  /* padding-left: 4px; */
  /* padding-right: 4px; */
  /* font-size: 28px; */
  font-size: 3.5vmin;
  vertical-align: middle;
  border-radius: 5px;
  cursor: pointer;
  /* max-width: 20px; */
}
.content-img {
  /* display: block; */
  float: right;
  margin: auto;
  max-width: 25.5rem;
  max-height: 25.5.5rem;
}
.img-course {
  width: 25.5rem;
  height: 25.5rem;
}
.list-lessons {
  background: #d7dade;
  border-radius: 15px;
  padding: 15px;
  overflow-y: auto;
  overflow-x: auto;
}
/* FORM */
input,
textarea {
  display: inline;
  border-radius: 15px;
  color: #1d2945;
  /* text-transform: uppercase; */
  text-align: start;
  font-size: 2.5vmin;
}
input[type="file"] {
  border: 2px solid #fff;
  font-family: "Poiret One", cursive !important;
  font-weight: bold !important;
  font-size: 2.5vmin;
}
.description-area {
  max-width: 77rem !important;
}
.lesson-nameform {
  max-width: 54rem;
  margin: auto;
  /* max-width: 78vmin; */
}
.text-youtube {
  font-size: 2vmin;
}
option,
select {
  font-size: 18px;
  font-family: "Fredoka One", cursive;
  border-radius: 15px;
}
.content-multimedia {
  display: flex;
}
.select-course {
  width: 4.5rem;
}
.btns-options {
  /* display: flex; */
  justify-content: right;
}
.btn-save-course {
  margin: auto;
  color: #fff;
  background: #7ab929;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.4rem; */
  font-size: 2vmin;
  text-align: center;
  border: none;
}
.btn-questions {
  margin: auto;
  color: #fff;
  background: #1d2945;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.4rem; */
  font-size: 2vmin;
  text-align: center;
  border: none;
}
.btn-delete-course {
  margin: auto;
  color: #fff;
  background: #cc0000;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.4rem; */
  font-size: 2vmin;
  text-align: center;
  border: none;
}
.lesson-img {
  display: block;
  margin: auto;
  width: 13.5rem;
  height: 9rem;
  border-radius: 15px;
}
.btn-lesson {
  background: #7c8393;
  color: #fff;
  display: block;
  margin: auto;
  border-radius: 20px;
  text-align: center;
  font-family: "Fredoka One", cursive;
  /* font-size: 1rem; */
  font-size: 2.5vmin;
  border: none;
  /* padding: 18px; */
  padding: 1vmin;
}
.btn-save-lesson {
  /* margin: auto; */
  /* width: auto; */
  /* float: right; */
  color: #fff;
  background: #7ab929;
  padding: 5px;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.5rem; */
  font-size: 3vmin;
  text-align: center;
  display: inline-block;
  border: none;
}
.btn-form-lesson {
  float: right;
  /* text-align: right; */
  /* width: auto; */
  /* margin-top: -45px; */
  margin-top: -8vmin;
  color: #fff;
  background: #7ab929;
  padding: 0.5vmin;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.2rem; */
  font-size: 2.5vmin;
  text-align: center;
  /* display: inline-block;  */
  border: none;
  cursor: pointer;
}
.btn-new-lesson {
  color: #fff;
  background: #7ab929;
  padding: 5px;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  font-size: 1rem;
  text-align: center;
  display: inline-block;
  border: none;
  cursor: pointer;
}
.cont-btn-block {
  float: right;
  text-align: right;
}
.icon-put-off {
  margin-left: 10px;
  margin-bottom: 6px;
  background: red;
  color: #fff;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  /* padding: 0.1vmin 0.5vmin 0.1vmin 0.5vmin; */
  padding: 0px 0.5vmin 0px 0.5vmin;
  /* font-size: 29px; */
  font-size: 3.5vmin;
  vertical-align: middle;
  border-radius: 5px;
  cursor: pointer;
}
.next-block {
  /* font-size: 20px; */
  font-size: 2.8vmin;
  font-weight: bold;
}
.btn-redirect {
  margin: auto;
  width: auto;
  color: #fff;
  background: #7ab929;
  padding: 5px;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  font-size: 1.5rem;
  text-align: center;
  display: inline-block;
  border: none;
}
</style>
