<template>
  <NavMain @search="handleSearch" />
  <div class="content-all">
    <section>
      <div>
        <div class="container">
          <div class="row">
            <div class="main-title">Todos mis cursos</div>
            <p class="main-text">Verifica o crea tus cursos</p>
          </div>
        </div>
        <div class="bar-title">
          <div class="container">
            <div @click="newCourse" class="title2">
              Crear <span class="text-green">Nuevo Curso </span>
              <span v-show="formCourse == false">+</span
              ><span v-show="formCourse == true">-</span>
            </div>
          </div>
        </div>
        <formCourse v-if="formCourse" />
        <!--  -->
        <div class="bar-title">
          <div class="container">
            <div @click="myCoursesShow" class="title2">
              Cursos <span class="text-green">Actuales </span>
              <span v-show="coursesView == false">+</span
              ><span v-show="coursesView == true">-</span>
            </div>
          </div>
        </div>
        <div class="blue" v-if="coursesView">
          <div class="container my-5">
            <vueper-slides
              ref="vueperslides1"
              @slide="
                $refs.vueperslides2 &&
                  $refs.vueperslides2.goToSlide($event.currentSlide.index, {
                    emit: false
                  })
              "
              :slide-ratio="val_ratio"
              :bullets="false"
              :touchable="false"
              slide-image-inside
              class="no-shadow py-2"
              :visible-slides="val_slides"
              slide-multiple
              :dragging-distance="70"
              :gap="3"
            >
              <vueper-slide
                v-for="course in trueCourses"
                :key="course._id"
                :title="
                  `<p style='color:#74A800; font-size:2.5vmin; text-transform:uppercase; font-family: Fredoka One, cursive;margin-top: 20px;'>${course.nombre}</p>`
                "
                :content="
                  `<p style=' font-size:2.3vmin; font-weight: bold;'>${course.autor}</p><p style=' font-size:2.3vmin; font-weight: bold;'>${course.duracion}</p>`
                "
                :image="url + '/api/archivos/' + course.imagen"
                :style="
                  `box-shadow: 0px 10px 10px black;
                        height: 90%; color: #fff; font-family: Fredoka One, cursive;
                        font-size:25px; cursor: pointer;`
                "
                @click="link(course._id.$oid)"
              />
            </vueper-slides>
            <!--  :image="require('@/assets/img/img2.jpg')" -->
            <vueper-slides
              class="no-shadow"
              ref="vueperslides2"
              :arrows="false"
              slide-multiple
              :slide-ratio="val_ratio"
              :dragging-distance="70"
              @slide="
                $refs.vueperslides1 &&
                  $refs.vueperslides1.goToSlide($event.currentSlide.index, {
                    emit: false
                  })
              "
              :visible-slides="val_slides"
              :gap="3"
              :fixed-height="val_fixed"
            >
              <vueper-slide
                v-for="inscritos in inscriptions"
                :key="inscritos"
                @click="
                  $refs.vueperslides2 &&
                    $refs.vueperslides2.goToSlide(inscritos - 1)
                "
              >
                <template #content>
                  <div class="vueperslide__content-wrapper">
                    <div class="vueperslide__title">
                      <div class="row">
                        <div class="text-slide col">
                          inscritos
                        </div>
                        <div class="col-1 fw-bold inscritos-text">
                          <span> {{ inscritos }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import NavMain from "@/components/NavMain.vue";
import formCourse from "@/components/formCourse.vue";
import axios from "axios";
// Vueper
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  props: ["id"],
  components: {
    NavMain,
    formCourse,
    // Vueper
    VueperSlides,
    VueperSlide
  },
  data() {
    return {
      formCourse: false,
      coursesView: false,
      myCourses: null,
      filteredMyCourses: null,
      userCourses: null,
      trueCourses: [],
      inscriptions: [],
      url: process.env.VUE_APP_URL,
      // vueperslideds valores
      val_slides: 3,
      val_ratio: 1 / 4,
      val_fixed: "50px"
    };
  },
  methods: {
    newCourse() {
      if (this.formCourse == false) {
        this.formCourse = true;
      } else {
        this.formCourse = false;
      }
    },
    myCoursesShow() {
      if (this.coursesView == false) {
        this.coursesView = true;
      } else {
        this.coursesView = false;
      }
    },
    handleSearch(value) {
      if (value && value.length > 0) {
        // console.log(value)
        this.trueCourses = this.filteredMyCourses.filter(i => {
          const val = value.toLowerCase();
          // console.log(val)
          const nombre = i.nombre && i.nombre.toLowerCase();
          // console.log(nombre)
          if (val && nombre.indexOf(val) !== -1) {
            // console.log(nombre)
            return true;
          }
          return false;
        });
      } else {
        this.trueCourses = this.filteredMyCourses;
      }
    },
    link(id_course) {
      this.$router.push({
        name: "CourseDetailsTeacher",
        params: { id: id_course }
      });
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
        // if (screen.width <= 360 && screen.height <= 760) {
        this.val_slides = 1;
        // this.val_ratio = 1 / 2.5;
        this.val_ratio = 0.8 / 1.3;
        // }
      } else if (orientation === "landscape-primary") {
        // landscape mode
        this.val_slides = 2;
        this.val_ratio = 1 / 3;
      }
    }
  },
  mounted() {
    // tamaño de pantalla
    if (window.innerHeight > window.innerWidth) {
      // alert("Please use Landscape!");
      this.val_slides = 1;
      this.val_ratio = 0.8 / 1.3;
      this.val_fixed = "25px";
    } else {
      if (
        (screen.width <= 800 && screen.height <= 360) ||
        (screen.width <= 1080 && screen.height <= 810)
      ) {
        this.val_slides = 2;
        this.val_ratio = 1 / 3;
      }
    }
    window.addEventListener("orientationchange", this.handleOrientationChange);
    //
    axios
      .get(this.url + `/api/cursos`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      })
      .then(response => {
        // console.log(response);
        this.myCourses = response.data.cursos;
        this.filteredMyCourses = response.data.cursos;
        this.myCourses.forEach(el => {
          // console.log(el.usuario.$oid)
          if (el.usuario.$oid == localStorage.getItem("id")) {
            this.trueCourses.push(el);
            if (el.inscritos != undefined) {
              this.inscriptions.push(el.inscritos.length);
            } else {
              this.inscriptions.push(0);
            }
            // console.log("curso: ", el);
          }
        });
        this.myCourses = this.trueCourses;
        this.filteredMyCourses = this.trueCourses;
        // axios
        //   .get(
        //     this.url + `/api/usuarios/` + localStorage.getItem("id"),
        //     {
        //       headers: {
        //         Authorization: "Bearer " + localStorage.getItem("token"),
        //       },
        //     }
        //   )
        //   .then((response) => {
        //     console.log("user: ", response.data.cursos);
        //     this.userCourses = response.data.cursos;
        //     // Agrupando cursos del usaurio
        //     for (let i = 0; i < this.userCourses.length; i++) {
        //       var found = this.myCourses.find(
        //         (element) => element._id.$oid === this.userCourses[i].$oid
        //       );
        //       this.trueCourses.push(found);
        //     }
        //     this, (this.numero = this.trueCourses.length);
        //     console.log("found: ", this.trueCourses);
        //     this.myCourses = this.trueCourses;
        //     this.filteredMyCourses = this.trueCourses;
        //   })
        //   .catch((e) => {
        //     console.log(e);
        //   });
      })
      .catch(e => {
        console.error(e);
      });
  }
};
</script>

<style scoped>
.content-all {
  margin-bottom: 30vmin;
}
.main-title {
  /* margin-top: 12rem; */
  margin-top: 15.5vmin;
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  /* font-size: 5rem; */
  font-size: 9vmin;
}
.bar-title {
  background: #d7dade;
  margin-top: 3rem;
}
.title2 {
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  /* font-size: 3rem; */
  font-size: 5vmin;
  cursor: pointer;
}
.main-text {
  font-family: "Poiret One", cursive !important;
  color: #1d2945;
  /* font-size: 30px; */
  font-size: 3.5vmin;
}
.text-green {
  color: #7ab929 !important;
}
.text-slide {
  color: #7ab929;
  font-family: "Fredoka One", cursive;
  /* font-size: 22px; */
  font-size: 3vmin;
}
.inscritos-text {
  color: #7ab929;
  font-family: "Fredoka One", cursive;
  /* font-size: 22px; */
  font-size: 3vmin;
}
.blue {
  background: url(../../assets/img/LINEA-AZUL.png) no-repeat fixed center;
  background-position: 0 10px !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* height: 10% !important; */
  /* width: 100%; */
  background-size: 100% 98% !important;
}
</style>