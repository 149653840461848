<template>
  <NavMain @search="handleSearch" />
  <div class="container">
    <div>
      <div class="main-title">Mis certificados</div>
      <p class="article">
        Tu esfuerzo y tu compromiso tienen una recompensa. Una vez concluido un
        curso podrás descargar tu certificado
      </p>
    </div>
  </div>
  <section class="content-title">
    <div class="container">
      <div class="row">
        <div class="title d-flex py-2">
          <div class="">Certificados</div>
          <div class="title2">Totales</div>
          <!-- <div class="col-2">
            <select class="filter py-3">
              <optgroup>
                <option value="Ver todo" selected>Ver todo</option>
                <option value="1">Clásificación de drogas</option>
                <option value="2">Concecuencias de consumir drogas</option>
                <option value="3">Métodos de prevención</option>
              </optgroup>
            </select>
          </div> -->
        </div>
      </div>
    </div>
  </section>

  <div class="main">
    <div class="container">
      <div class="list">
        <ul>
          <li v-for="course in courses" :key="course._id">
            <div class="row">
              <div class="col bar-green">
                <span class="text-green">
                  Certificado del curso: {{ course.nombre }}
                </span>
              </div>
              <div class="col-4 bar-blue">
                Descarga <img src="../../assets/img/icon-pdf.png" alt="" />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="fixed-bottom">
      <div class="corner-green position-absolute bottom-0 start-0">
        <img src="../../assets/img/ESQUINA-VERDE.png" alt="" class="green" />
      </div>
      <div class="corner-blue position-absolute bottom-0 end-0">
        <img src="../../assets/img/ESQUINA-AZUL.png" alt="" class="blue" />
      </div>
    </div>
  </div>
</template>

<script>
import NavMain from "@/components/NavMain.vue";
import axios from "axios";
export default {
  components: {
    NavMain,
  },
  data() {
    return {
      courses: null,
      filteredCourses: null,
      url: process.env.VUE_APP_URL,
    };
  },
  methods: {
    handleSearch(value) {
      if (value && value.length > 0) {
        // console.log(value)
        this.courses = this.filteredCourses.filter((i) => {
          const val = value.toLowerCase();
          // console.log(val)
          const nombre = i.nombre && i.nombre.toLowerCase();
          // console.log(nombre)
          if (val && nombre.indexOf(val) !== -1) {
            // console.log(nombre)
            return true;
          }
          return false;
        });
      } else {
        this.courses = this.filteredCourses;
      }
    },
  },
  mounted() {
    axios
      .get(this. url + `/api/cursos`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        // console.log(response);
        this.courses = response.data.cursos;
        this.filteredCourses = response.data.cursos;
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");
.main-title {
  margin-top: 12rem;
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  font-size: 5rem;
  z-index: 2;
}
.article {
  font-family: "Poiret One", cursive !important;
  color: #1d2945;
  font-size: 25px;
}
.content-title {
  /* margin-top: 12rem; */
  /* position: absolute; */
  background: #d7dade;
  z-index: 2;
}
.title {
  font-size: 30px;
  font-weight: bold;
  font-family: "Fredoka One", cursive;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.title2 {
  margin-left: 10px;
  color: #78b829;
}
.main-list {
  position: relative;
}
.list {
  position: absolute;
  z-index: 2;
  margin-top: 50px;
  /* width: auto; */
}
.bar-green {
  background: #a2e048;
  color: #fff;
  /* max-width: 90rem; */
  font-family: "Poiret One", cursive !important;
  font-size: 32px;
  font-weight: bold;
  border-radius: 10px;
  margin-top: 15px;
  width: auto;
}
.bar-blue {
  background: #1d2945;
  color: #fff;
  width: auto;
  font-family: "Fredoka One", cursive;
  font-weight: bold;
  font-size: 28px;
  border-radius: 15px;
  margin-top: 15px;
  margin-left: -20px;
  cursor: pointer;
}
.text-green {
  margin-right: 20px;
}
/* FONDO */
.corner-green {
  width: 64%;
  /* width: 86rem;
  height: 60rem; */
}
.green {
  width: 100%;
  height: 100%;
}
.corner-blue {
  width: 80%;
}
.blue {
  width: 100%;
  height: 100%;
}
.fixed-bottom {
  z-index: 1;
}
</style>