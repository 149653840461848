<template>
  <form @submit.prevent="loginSubmit">
    <div class="login container">
      <div class="form-log">
        <div>
          <div class="text-form text-start">Correo Electrónico</div>
          <input
            type="email"
            required
            autocomplete="off"
            placeholder="Correo Electrónico..."
            class="form-control"
            v-model="login.email"
          />
        </div>
        <div>
          <div class="text-form text-start">Rol</div>
          <select class="form-select" v-model="login.role">
            <option selected>Seleccione rol</option>
            <option value="Estudiante">Estudiante</option>
            <option value="Docente">Docente</option>
            <option value="Administrador">Administrador</option>
          </select>
        </div>
        <p class="error" v-if="incorrectRole">El rol de usuario es incorrecto</p>
        <div>
          <div class="text-form text-start">Contraseña</div>
          <div class="row">
            <div class="col">
              <input
                :type="login.showPass1"
                required
                autocomplete="off"
                placeholder="Contraseña..."
                class="form-control"
                v-model="login.pass"
              />
              <p class="error" v-if="noUser">
                No existe usuario con la direccion {{ login.email }}
              </p>
              <p class="error" v-if="noUser">ó la contraseña es incorrecta</p>
            </div>
            <div class="col-1 my-1">
              <span @click="changeEye1">
                <span v-show="login.eye1"
                  ><i class="icon-input fas fa-eye-slash"></i
                ></span>
                <span v-show="login.noEye1"
                  ><i class="icon-input far fa-eye"></i
                ></span>
              </span>
            </div>
          </div>
        </div>
        <div class="content-button-send">
          <button class="buttom-send">ENVIAR</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      login: {
        email: "",
        pass: "",
        role: "Seleccione rol",
        emailExist: false,
        showPass1: "password",
        eye1: true,
        noEye1: false,
      },
      user: null,
      noUser: false,
      // incorrectPass: false,
      incorrectRole: false,
      url: process.env.VUE_APP_URL,
    };
  },
  methods: {
    async loginSubmit() {
      await axios
        .post(this.url + "/login", {
          correo: this.login.email,
          contrasena: this.login.pass,
        })
        .then(async (response) => {
          // console.log(response);
          if (response.status === 200) {
            var responseUser = await axios.get(
              this.url + "/api/usuarios/" + response.data.usuario_id
            );
            this.user = responseUser.data;
            // console.log(this.user)
            if (this.user.rol == this.login.role) {
              // guarda token
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("id", response.data.usuario_id);
              // console.log(localStorage.getItem('token'))
              this.$router.push({
                name: "Home",
                params: { id: response.data.usuario_id },
              });
            }else{
              this.incorrectRole = true
            }
          }
        })
        .catch((e) => {
          // console.log(e.response.status);
          if (e.response.status === 401) {
            this.noUser = true;
          }
          if (e.response.status === 404) {
            this.noUser = true;
          }
        });
    },
    changeEye1() {
      if (this.login.showPass1 === "password") {
        this.login.showPass1 = "text";
        this.login.eye1 = false;
        this.login.noEye1 = true;
      } else {
        this.login.showPass1 = "password";
        this.login.noEye1 = false;
        this.login.eye1 = true;
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
.login {
  max-width: 30rem;
  /* height: 30rem; */
  margin-top: 3.5rem;
  border-radius: 15px;
  background: #d7dade;
  /* padding: 5% 0; */
  /* align-items: center; */
}
.form-log {
  padding-top: 10px !important;
  /* padding: 10% 0; */
  color: #1d2945;
}
.text-form {
  font-family: "Poiret One", cursive;
  color: #1d2945 !important;
  font-size: 2.4vmin;
  margin-left: 2px;
}

.icons {
  margin-top: -10px;
}
.icon-register {
  cursor: pointer;
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* display: inline-block; */
}
.icon-input {
  font-size: 2.4vmin;
  cursor: pointer;
  /* margin-right: 20px; */
}
.col-1 {
  padding: 0px;
}
.content-button-send {
  margin-top: 10px;
  padding-bottom: 10px;
}
.buttom-send {
  /* width: 110px; */
  border: none;
  background: #1d2945;
  color: white;
  font-family: "Fredoka One", cursive;
  /* font-size: 20px; */
  font-size: 2.3vmin;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 15px;
  /* font-weight: bold; */
}
.buttom-send:hover {
  font-weight: bold;
  /* font-size: 21px; */
}
.error {
  color: red;
}
.form-control{
  font-size: 2.5vmin;
}
.form-select{
  font-size: 2.5vmin;
}
</style>