import { createRouter, createWebHistory } from 'vue-router'
// Users
// import Login from '../views/users/Login.vue'
import AdminUsers from '../views/users/AdminUsers.vue'
import AddUser from '../views/users/AddUser.vue'
import UpdateUser from '../views/users/UpdateUser.vue'
// import SLogin from '../views/users/SLogin.vue'
import NLogin from '../views/users/NLogin.vue'
import MyProfile from '../views/users/myProfile.vue'
// Courses
// import Home from '../views/Home.vue'
import Courses from '../views/courses/Courses.vue'
import CourseDetails from '../views/courses/CourseDetails.vue'
import Course from '../views/courses/Course.vue'
import AdminCourses from '../views/courses/AdminCourses.vue'
// import AddCourse from '../views/courses/AddCourse.vue'
// import EditCourse from '../views/courses/EditCourse.vue'
// import UpdateCourse from '../views/courses/UpdateCourse.vue'
import MyCourses from '../views/courses/MyCourses.vue'
import MyCoursesTeacher from '../views/courses/MyCoursesTeacher.vue'
import CourseDetailsTeacher from '../views/courses/CourseDetailsTeacher.vue'
// Lessons
import AdminLessons from '../views/lessons/AdminLessons.vue'
// import AddLesson from '../views/lessons/AddLesson.vue'
// import UpdateLesson from '../views/lessons/UpdateLesson.vue'
// Downloadable
import Certificates from '../views/downloadable/Certificates.vue'
import Materials from '../views/downloadable/Materials.vue'
// NotFond
import NotFound from '../views/NotFound.vue'
// Processing
import Processing from '../views/Processing.vue'
import ProcessingUser from '../views/ProcessingUser.vue'
import ProcessingQuestion from '../views/ProcessingQuestion.vue'
import ProcessingCard from '../views/ProcessingCard.vue'
// principal
import Home from '../views/principal/Home.vue'
// Questions
import Question from "../views/questions/question.vue"
import Questionary from '../views/questions/questionary.vue'
// Games
import Hangman from '../views/games/hangman.vue'
import CardMemory from '../views/games/cardMemory.vue'


const routes = [
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    props: true
  },
  {
    path: '/courses',
    name: 'Courses',
    component: Courses
  },
  {
    path: '/courses/:id',
    name: 'CourseDetails',
    component: CourseDetails,
    props: true
  },
  {
    path: '/course/:id/:index_show',
    name: 'Course',
    component: Course,
    props: true
  },
  {
    path: '/adminCourses',
    name: 'AdminCourses',
    component: AdminCourses,
  },
  // {
  //   path: '/addCourse',
  //   name: 'AddCourse',
  //   component: AddCourse,
  // },
  // {
  //   path: '/editCourse/:id/:nombre_titulo',
  //   name: 'EditCourse',
  //   component: EditCourse,
  //   props: true
  // },
  // {
  //   path: '/updateCourse/:id/:nombre_titulo',
  //   name: 'UpdateCourse',
  //   component: UpdateCourse,
  //   props: true
  // },
  {
    path: '/myCourses',
    name: 'MyCourses',
    component: MyCourses,
    props: true
  },
  {
    path: '/myCoursesTeacher',
    name: 'MyCoursesTeacher',
    component: MyCoursesTeacher,
    props: true
  },
  {
    path: '/coursesTeacher/:id',
    name: 'CourseDetailsTeacher',
    component: CourseDetailsTeacher,
    props: true
  },
  {
    path: '/coursesTeacher/:id/question',
    name: 'QuestionDetails',
    component: Question,
    props: true
  },
  // Users 
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: Login
  // },
  {
    path: '/adminUsers',
    name: 'AdminUsers',
    component: AdminUsers,
  },
  {
    path: '/addUser',
    name: 'AddUser',
    component: AddUser,
  },
  {
    path: '/updateUser/:id/:nombre_titulo',
    name: 'UpdateUser',
    component: UpdateUser,
    props: true
  },
  // {
  //   path: '/slogin',
  //   name: 'SLogin',
  //   component: SLogin
  // },
  {
    path: '/',
    name: 'NLogin',
    component: NLogin
  },
  {
    path: '/myProfile',
    name: 'MyProfile',
    component: MyProfile,
  },
  // Lecciones
  {
    path: '/adminLessons',
    name: 'AdminLessons',
    component: AdminLessons,
  },
  // {
  //   path: '/addLesson',
  //   name: 'AddLesson',
  //   component: AddLesson,
  //   props: true
  // },
  // {
  //   path: '/updateLesson/:id/:nombre_titulo',
  //   name: 'UpdateLesson',
  //   component: UpdateLesson,
  //   props: true
  // },
  // questionary
  {
    path: '/questionary/:id/:nombre_titulo/:last_lesson',
    name: 'Questionary',
    component: Questionary,
    props: true
  },
  // dowloadable
  {
    path: '/certificates',
    name: 'Certificates',
    component: Certificates,
    props: true
  },
  {
    path: '/materials/:id',
    name: 'Materials',
    component: Materials,
    props: true
  },
  // NotFound
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
  // PROCESOS
  {
    path: '/processing/:id',
    name: 'Processing',
    component: Processing,
    props: true
  },
  // Processing user
  {
    path: '/processingUser/:id',
    name: 'ProcessingUser',
    component: ProcessingUser,
    props: true
  },
  // processing addQuestion
  {
    path: '/processingQuestion/:id',
    name: 'ProcessingQuestion',
    component: ProcessingQuestion,
    props: true
  },
  // processing CardMemory
  {
    path: '/processingCard/:id_course/:id_leccion/:index_show',
    name: 'ProcessingCard',
    component: ProcessingCard,
    props: true
  },
  // GAMES
  {
    path: '/hangman/:id_course/:id_leccion/:block_pos/:index_show',
    name: 'Hangman',
    component: Hangman,
    props: true
  },
  {
    path: '/cardMemory/:id_course/:id_leccion/:index_show',
    name: 'CardMemory',
    component: CardMemory,
    props: true
  }
  // PageBlank
  // {
  //   path: '/courses/:catchAll(.*)',
  //   name: 'NotFound',
  //   component: NotFound,
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
