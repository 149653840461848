<template>
  <NavMain @search="handleSearch" />
  <div class="content-all">
    <section class="content-title">
      <div class="container">
        <div class="row">
          <div class="title d-flex">
            <div class="col-2 py-2">Contenido</div>
            <div class="col-2">
              <select class="filter">
                <optgroup>
                  <option value="Ver todo" selected>Ver todo</option>
                  <option value="1">Clásificación de drogas</option>
                  <option value="2">Concecuencias de consumir drogas</option>
                  <option value="3">Métodos de prevención</option>
                </optgroup>
              </select>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container py-5">
      <vueper-slides
        :touchable="false"
        class="no-shadow"
        slide-multiple
        :bullets="true"
        :visible-slides="val_slides"
        :slide-ratio="val_ratio"
        :dragging-distance="70"
        :gap="10"
      >
        <vueper-slide
          v-for="course in courses"
          :key="course._id"
          :title="
            `<p style='
        color:#74A800; 
        font-size:2.5vmin; 
        text-transform:uppercase; 
        font-family: 
        Fredoka One, cursive; margin-top: 20px'>${course.nombre}</p>`
          "
          :content="
            `<p style='
        font-size:2.3vmin; 
        font-weight: bold;'>${course.autor}</p>
        <p style=' 
        font-size:2.3vmin; 
        font-weight: bold;'>${course.duracion}</p>`
          "
          :image="url + '/api/archivos/' + course.imagen"
          :style="
            `box-shadow: 0px 10px 20px black;
          height: 85%; color: #fff;
          font-size:2.3vmin; cursor: pointer`
          "
          @click="link(course._id.$oid)"
        />
      </vueper-slides>
    </div>

    <section class="content-recommended">
      <div class="container">
        <div class="row">
          <div class="title d-flex py-2">
            <div class="">Mis</div>
            <div class="title2">Cursos</div>
          </div>
        </div>
      </div>
    </section>

    <div class="blue">
      <!-- <div class="container py-5">
      <vueper-slides
        :touchable="false"
        slide-multiple
        class="no-shadow py-2"
        :visible-slides="3"
        :slide-ratio="1 / 4"
        :dragging-distance="70"
        :gap="3"
      >
        <vueper-slide
          v-for="course in courses"
          :key="course._id"
          :image="url + '/api/archivos/' + course.imagen"
          :title="`<p style='
          color:#74A800; 
          font-size:2.5vmin; 
          text-transform:uppercase; 
          font-family: 
          Fredoka One, cursive; margin-top: 20px;'>${course.nombre}</p>`"
          :content="`<p style=' 
          font-size:2.3vmin; 
          font-weight: bold;'>${course.autor}</p>
          <p style='
          font-size:2.3vmin; 
          font-weight: bold;'>${course.duracion}</p>`"
          :style="`box-shadow: 0px 10px 10px black;
          height: 85%; color: #fff;
          font-size:2.3vmin; cursor: pointer;`"
          @click="link(course._id.$oid)"
        />
      </vueper-slides>
    </div> -->
      <div class="container py-5">
        <vueper-slides
          ref="vueperslides1"
          @slide="
            $refs.vueperslides2 &&
              $refs.vueperslides2.goToSlide($event.currentSlide.index, {
                emit: false
              })
          "
          :slide-ratio="val_ratio"
          :bullets="true"
          :touchable="false"
          slide-image-inside
          class="no-shadow py-2"
          :visible-slides="val_slides"
          slide-multiple
          :dragging-distance="70"
          :gap="4"
        >
          <vueper-slide
            v-for="course in trueCourses"
            :key="course._id"
            :title="
              `<p style='color:#74A800; font-size:2.5vmin; text-transform:uppercase; font-family: Fredoka One, cursive;margin-top: 20px;'>${course.nombre}</p>`
            "
            :content="
              `<p style=' font-size:2.3vmin; font-weight: bold;'>${course.autor}</p><p style=' font-size:2.3vmin; font-weight: bold;'>${course.duracion}</p>`
            "
            :image="url + '/api/archivos/' + course.imagen"
            :style="
              `box-shadow: 0px 10px 10px black;
          height: 90%; color: #fff; 
          font-size:25px; cursor: pointer;`
            "
            @click="link(course._id.$oid)"
          />
        </vueper-slides>

        <vueper-slides
          class="no-shadow"
          ref="vueperslides2"
          :arrows="false"
          slide-multiple
          :slide-ratio="val_ratio"
          :dragging-distance="70"
          :bullets="false"
          @slide="
            $refs.vueperslides1 &&
              $refs.vueperslides1.goToSlide($event.currentSlide.index, {
                emit: false
              })
          "
          :visible-slides="val_slides"
          :gap="10"
          :fixed-height="val_fixed"
        >
          <vueper-slide
            v-for="(course, i_course) in trueCourses"
            :key="i_course"
            @click="
              $refs.vueperslides2 && $refs.vueperslides2.goToSlide(course - 1)
            "
          >
            <template #content>
              <span v-for="(por, i_por) in percents" :key="i_por">
                <div
                  v-if="i_por == i_course"
                  class="vueperslide__content-wrapper"
                  :style="
                    'background-color: ' + ['#ff5252', '#42b983'][course % 2]
                  "
                >
                  <div class="vueperslide__title">
                    <div class="row">
                      <div
                        class="progress col-4 "
                        style="
                      width: 25vmin;
                      background: #e2f9c0;
                      padding-left: 2px;
                      padding-right: 2px;
                      margin-top: 8px;
                    "
                      >
                        <div
                          class="progress-bar"
                          :style="{ width: (por * 25) / 100 + 'vmin' }"
                          style="background: #a2e048"
                          role="progressbar"
                          aria-valuenow="20"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div
                        class="col-1"
                        style="color: #a2e048; font-size: 2.3vmin; font-weight: bold;"
                      >
                        {{ por }}%
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </template>
          </vueper-slide>
        </vueper-slides>
        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
import NavMain from "@/components/NavMain.vue";
import axios from "axios";
//
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  components: {
    NavMain,
    //
    VueperSlides,
    VueperSlide
  },
  props: ["id"],
  data() {
    return {
      courses: null,
      searchInput: "",
      filteredCourses: null,
      // mis cursos
      myCourses: null,
      userCourses: null,
      trueCourses: [],
      userCourses: null,
      trueMyCourses: null,
      cant_lessons: null,
      complete: null,
      percents: null,
      filteredMyCourses: null,
      // valores vueper
      val_slides: 3,
      val_ratio: 1 / 4,
      val_fixed: "50px",
      //
      // slides: [
      //   {
      //     title: "Slide #1",
      //     content: "Slide content."
      //   },
      //   {
      //     title: "Slide #2",
      //     content: "Slide content."
      //   },
      //   {
      //     title: "Slide #3",
      //     content: "Slide content."
      //   },
      //   {
      //     title: "Slide #4",
      //     content: "Slide content."
      //   },
      //   {
      //     title: "Slide #5",
      //     content: "Slide content."
      //   }
      // ],
      // user: null,
      user: {
        name: "",
        last_name: "",
        email: "",
        age: "",
        pass: "",
        c_pass: "",
        role: "",
        letter: ""
      },
      like: true,
      noLike: false,
      url: process.env.VUE_APP_URL
      // bg: '',
    };
  },
  // async created() {
  //   const response = await axios
  //     .get(this.url + "/api/usuarios/" + localStorage.getItem("id"), {
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     })
  //     .then((response) => {
  //       // localStorage.setItem('token', response.data)
  //       // console.log(response)
  //       // this.user = response.data
  //       this.user.name = response.data.nombre;
  //       this.user.last_name = response.data.apellido;
  //       this.user.email = response.data.correo;
  //       this.user.age = response.data.edad;
  //       this.user.pass = response.data.contrasena;
  //       this.user.c_pass = response.data.confirmar_contrasena;
  //       this.user.role = response.data.rol;
  //       this.user.letter = response.data.nombre.charAt(0);
  //       // console.log(this.letter)
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // },
  async mounted() {
    // Orientacion de pantalla
    if (window.innerHeight > window.innerWidth) {
      // alert("Please use Landscape!");
      this.val_slides = 1;
      this.val_ratio = 0.8 / 1.3;
      this.val_fixed = "25px";
    } else {
      if (
        (screen.width <= 800 && screen.height <= 360) ||
        (screen.width <= 1080 && screen.height <= 810)
      ) {
        this.val_slides = 2;
        this.val_ratio = 1 / 3;
      }
    }
    window.addEventListener("orientationchange", this.handleOrientationChange);
    //
    axios
      .get(this.url + `/api/cursos`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      })
      .then(response => {
        // console.log(response.data.cursos);
        this.courses = response.data.cursos;
        this.filteredCourses = response.data.cursos;
        // console.log(this.courses.cursos[0].nombre)
      })
      .catch(e => {
        console.log(e);
      });
    // Mis cursos
    // ///////
    axios
      .get(this.url + `/api/cursos`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      })
      .then(response => {
        // console.log(response.data.cursos);
        this.myCourses = response.data.cursos;
        // console.log("myCourses: ",this.myCourses)
        // this.filteredMyCourses = response.data.cursos;

        this.myCourses.forEach(el => {
          if (el.inscritos != undefined) {
            var inscritos = el.inscritos;

            for (let i = 0; i < inscritos.length; i++) {
              // console.log("inscritos: ", inscritos[i]);
              if (inscritos[i].$oid == localStorage.getItem("id")) {
                this.trueCourses.push(el);
              }
            }
          }
        });
        // console.log("trueCouses: ",this.trueCourses)
        this.filteredMyCourses = this.trueCourses;
        // this.numero = this.trueCourses.length;
      })
      .catch(e => {
        console.log(e);
      });
    // Obtenemos los cursos del usuario
    // console.log("asd: ",this.myCourses)
    var responseCourse = await axios.get(
      this.url + "/api/usuarios/" + localStorage.getItem("id"),
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }
    );
    this.userCourses = responseCourse.data;
    // Obteniando mis cursos
    // FORMA 1 ORDEN DE USERCOURSES
    // console.log("asd: ", this.userCourses);
      // if (this.userCourses.cursos != undefined) {
      //   this.trueMyCourses = _.range(0, this.userCourses.cursos.length);
      //   var id_course = 0;
      //   // console.log("userCourses: ",this.userCourses)
      //   this.userCourses.cursos.forEach((el, index) => {
      //     id_course = el.$oid;
      //     this.trueMyCourses[index] = id_course;
      //     // console.log("el: ",id_course)
      //   });
      //   // console.log("truemy: ",this.trueMyCourses)
      // }
    //END FORMA 1 
    // 
    // FORMA 2 MISMO ORDEN DE TRUECOURSES
    if (this.userCourses.cursos != undefined) {
      this.trueMyCourses = _.range(0, this.trueCourses);
      var id_course = 0;
      // console.log("userCourses: ",this.userCourses)
      this.trueCourses.forEach((el, index) => {
        id_course = el._id.$oid;
        this.trueMyCourses[index] = id_course;
        // console.log("el: ",id_course)
      });
      // console.log("truemy: ",this.trueMyCourses)
    }
    // END FORMA 2
    // 
    // console.log("trueMyCourses :", this.trueMyCourses);
    // hallando la cantidad de lecciones de cada curso
    this.cant_lessons = _.range(0, this.trueMyCourses.length);
    // this.userCourses = responseCourse.data;
    for (let i = 0; i < this.trueMyCourses.length; i++) {
      var responseCant = await axios.get(
        this.url + "/api/lecciones?curso_id=" + this.trueMyCourses[i],
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }
      );
      this.cant_lessons[i] = responseCant.data.lecciones.length;
      // console.log("cant_less: ",this.cant_lessons[i])
      // this.userCourses = responseCourse.data;
    }
    // console.log("cant: ", this.cant_lessons);
    // obteniendo el avance de las lecciones
    this.complete = _.range(0, this.trueMyCourses.length);
    for (let i = 0; i < this.trueMyCourses.length; i++) {
      var responseCompletar = await axios.get(
        this.url +
          "/api/cursos/" +
          this.trueMyCourses[i] +
          "/lecciones-completas/" +
          localStorage.getItem("id"),
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }
      );
      this.complete[i] = responseCompletar.data.length;
    }
    // console.log("complete: ",this.complete)
    // Obeniendo porcentajes
    this.percents = _.range(0, this.trueMyCourses.length);
    var porcentaje = 0;
    for (let i = 0; i < this.trueMyCourses.length; i++) {
      porcentaje = (this.complete[i] * 100) / this.cant_lessons[i];
      // console.log("porcent: ",porcentaje)
      this.percents[i] = porcentaje;
    }
  },
  methods: {
    link(id_course) {
      this.$router.push({
        name: "CourseDetails",
        params: { id: id_course }
      });
    },
    handleSearch(value) {
      if (value && value.length > 0) {
        // console.log(value)
        this.courses = this.filteredCourses.filter(i => {
          const val = value.toLowerCase();
          // console.log(val)
          const nombre = i.nombre && i.nombre.toLowerCase();
          // console.log(nombre)
          if (val && nombre.indexOf(val) !== -1) {
            // console.log(nombre)
            return true;
          }
          return false;
        });
      } else {
        this.courses = this.filteredCourses;
        // this.trueCourses = this.filteredMyCourses
      }
      // mis cursos
      if (value && value.length > 0) {
        // console.log(value)
        this.trueCourses = this.filteredMyCourses.filter(i => {
          const val = value.toLowerCase();
          // console.log(val)
          const nombre = i.nombre && i.nombre.toLowerCase();
          // console.log(nombre)
          if (val && nombre.indexOf(val) !== -1) {
            // console.log(nombre)
            return true;
          }
          return false;
        });
      } else {
        // this.courses = this.filteredCourses;
        this.trueCourses = this.filteredMyCourses;
      }
    },
    changeLike(id) {
      // var icon = document.getElementById('icon');
      if (this.like == true) {
        // icon.classList.toggle('far');
        // icon.classList.toggle('fas');
        this.like = false;
        this.noLike = true;
      } else {
        // icon.classList.toggle('fas');
        // icon.classList.toggle('far');
        this.like = true;
        this.noLike = false;
      }
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
        // if (screen.width <= 360 && screen.height <= 760) {
        this.val_slides = 1;
        // this.val_ratio = 1 / 2.5;
        this.val_ratio = 0.8 / 1.3;
        // }
      } else if (orientation === "landscape-primary") {
        // landscape mode
        this.val_slides = 2;
        this.val_ratio = 1 / 3;
      }
    }
  }
};
</script>

<style scoped>
.content-all{
  margin-bottom: 20vmin;
}
.content-title {
  margin-top: 14vmin;
  /* padding-top: 16vmin; */
  background: #d7dade;
}
.content-recommended {
  /* margin-top: 12rem; */
  background: #d7dade;
}
.title {
  font-size: 3.5vmin;
  font-weight: bold;
  font-family: "Fredoka One", cursive;
  align-items: center;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.title2 {
  margin-left: 10px;
  color: #78b829;
}
.filter {
  color: #9da0a3 !important;
  background: #d7dade !important;
  border-style: none;
  font-size: 2.5vmin;
  align-items: center;
  margin-left: 35px !important;
  margin-bottom: 6.5px !important;
}
optgroup {
  font-family: "Poiret One", cursive !important;
  font-size: 2.5vmin;
  color: #000;

  /* background: #fff !important; */
}
option {
  background: #fff;
  color: #1d2945;
}
option:hover {
  background-color: red;
}
.vueperslides__arrow .vueperslides__arrow--prev {
  color: #000 !important;
}
/* CARDS CURSOS */
/* ------------ */
.card-cursos {
  border: #78be20 2px solid !important;
  max-width: 240px;
  max-height: 240px;
  /* width: 10rem; */
  margin-right: 20px;
  margin-top: 20px;
  position: relative;
}
.card-body-main {
  width: 250px;
  height: 80px;
}
.card-hover {
  visibility: hidden;
  width: 18rem;
  z-index: 2;
}
.card-pointer {
  position: relative;
}
/* .card-cursos:hover .card-hover {
  visibility: visible;
  transition: opacity 1s ease;
  transform: translate(72%, -12%) !important;
} */
.card-pointer:hover .card-hover {
  visibility: visible;
  transition: opacity 1s ease;
  transform: translate(72%, -12%) !important;
}
/* icons */
.book {
  font-size: 4rem;
  color: #90d53c;
  padding-top: 12px;
}
.book2 {
  display: none;
  font-size: 4rem;
  color: #90d53c;
  padding-top: 12px;
}
.card-cursos:hover .book {
  display: none;
}
.card-cursos:hover .book2 {
  display: inline;
}
.stars {
  width: 200px;
  display: inline;
  font-size: 18px;
  color: #ffdf00;
  margin-bottom: 30px;
  position: absolute;
  bottom: 0px;
  /* text-align: center; */
}
.icon-likes {
  display: inline;
  /* float: right; */
  color: #262d46;
  margin: 2px;
  /* margin-top: 20px; */
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  bottom: 0px;
}
.icon-like {
  margin-right: 8px;
}
.icon-like:hover {
  /* font-weight: bold; */
  font-size: 22px;
  margin-right: 10px;
}
.btn-2 {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  background-color: #8fd53b;
  border-radius: 0.25rem;
  color: white;
  width: 150px;
  margin-top: 4px;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.btn-2:hover {
  color: #ffffff;
  font-weight: bold;
}
/*  */
.green {
  background: url(../../assets/img/LINEA-VERDE.png) no-repeat fixed center;
  background-position: 0 24px !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* height: 10% !important; */
  /* width: 100%; */
  background-size: 100% 120% !important;
}
.blue {
  background: url(../../assets/img/LINEA-AZUL.png) no-repeat fixed center;
  background-position: 0 24px !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* height: 10% !important; */
  /* width: 100%; */
  background-size: 100% 120% !important;
}
</style>