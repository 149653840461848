<template>
  <div v-if="tableLessons">
    <section class="article py-5">
      <div class="container">
        <div class="row">
          <div class="text-center text-white">
            <h1 class="display-4 fw-bold">Administración de Lecciones</h1>
          </div>
        </div>
      </div>
    </section>

    <div class="container mb-5">
      <div class="row">
        <div class="col-lg-12">
          <div class="tabla">
            <div class="text-white text-left fs-2 fw-bold">
              Contenido
              <router-link :to="{ name: 'AddLesson' }">
                <span class="d-flex d-row justify-content-end button_icon_add">
                  <i class="fas fa-plus text-white"></i>
                </span>
              </router-link>
            </div>

            <table id="example" class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Contenido</th>
                  <th>Tipo</th>
                  <th>Curso</th>
                  <th>Imagen</th>
                  <th>Video</th>
                  <th>Opciones</th>
                  <!-- <th>Salary</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="tableLesson in tableLessons" :key="tableLesson._id" >
                  <td>{{ tableLesson.nombre }}</td>
                  <td>{{ tableLesson.contenido }}</td>
                  <td>{{ tableLesson.tipo_leccion }}</td>
                  <td>
                    <span v-for="curso in cursos" :key="curso._id" >
                      <span v-if="curso._id.$oid == tableLesson.curso_id">
                        {{ curso.nombre }}
                      </span>
                    </span>
                  </td>
                  <td>{{ tableLesson.imagenes }}</td>
                  <td>{{ tableLesson.videos }}</td>
                  <td>
                    <router-link :to="{ name: 'UpdateLesson', params: { id: tableLesson._id.$oid, nombre_titulo: tableLesson.nombre } }">
                      <span class="button_icon btn-info">
                        <i class="fas fa-edit"></i>
                      </span>
                    </router-link>
                    <span @click="deleteRow(tableLesson._id.$oid, tableLesson.nombre)" class="button_icon btn-danger">
                      <i class="fas fa-trash-alt"></i>
                    </span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Nombre</th>
                  <th>Contenido</th>
                  <th>Tipo</th>
                  <th>Curso</th>
                  <th>Imagen</th>
                  <th>Video</th>
                  <th>Opciones</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-white text-center" v-else>
    Cargando AdminLessons...
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css';
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
// axios
import axios from "axios";

export default {
  data() {
    return {
      tableLessons: null,
      cursos: null,
      course_lesson: [],
      url: process.env.VUE_APP_URL,
    };
  },
  methods: {
    deleteRow(id, name){
      
      this.$swal({
        icon: "warning",
        title: "Borrar Lección!",
        text: "¿ Está seguro que desea BORRAR la Lección ?"+'  '+name,
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#8FD53B',
        cancelButtonColor: '#CB3234',
        focusCancel: true,
        cancelButtonText: 'Cancelar',
      }).then(result => {
        // Si se hace click en el boton que confirma entonces
        // otro alerta indica q se borro satisfactoriamente y procede a borrar
        if(result.isConfirmed){
          // Borrando...
          // console.log('tableCourses',this.tableCourses.length)
          for(let i = 0; i < this.tableLessons.length; i++){
            if(this.tableLessons[i]._id.$oid==id){
              this.tableLessons.splice(i,1)
            }
          }
          axios.delete(this.url + '/api/lecciones/'+id)
          .then(response => {
            // console.log(response)
          })
          .catch(e =>{
            console.log(e)
          })
          this.$swal({
            icon: 'success',
            text: 'La Lección se ha borrado satisfactoriamente',
            confirmButtonColor: '#8FD53B',
            focusConfirm: true
          })
        }
      });
    },
  },
  mounted() {
    axios
      .get(this. url + `/api/lecciones`)
      .then(async (response) => {
        this.tableLessons = response.data;

        var responseCursos = await axios.get(this.url + '/api/cursos')
        this.cursos = responseCursos.data;
        
        $(document).ready(function () {
          $("#example").DataTable({
            info: false,
            responsive: true,
            language: {
              decimal: "",
              emptyTable: "No hay información",
              info: "Mostrando _START_ a _END_ de _TOTAL_ Entradas",
              infoEmpty: "Mostrando 0 to 0 of 0 Entradas",
              infoFiltered: "(Filtrado de _MAX_ total entradas)",
              infoPostFix: "",
              thousands: ",",
              lengthMenu: "  Mostrar   _MENU_   Entradas  ",
              loadingRecords: "Cargando...",
              processing: "Procesando...",
              search: "Buscar:  ",
              zeroRecords: "Sin resultados encontrados",
              paginate: {
                first: "Primero",
                last: "Ultimo",
                next: "Siguiente",
                previous: "Anterior",
              },
            },
          });
        });
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>

<style scoped>
.tabla {
  border: 5px solid #00dece !important;
  border-radius: 20px;
  /* background: linear-gradient(to right,#fff,  #cceba1, #8fd53b); */
  background: #91d63e;
  padding-left: 10px;
  padding-right: 10px;
}
.button_icon {
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 25px;
  border-radius: 10px;
  margin-right: 5px;
  color: white;
  cursor: pointer;
}
.button_icon_add {
  /* width: 40px; */
  display: block;
  background: #009d00 !important;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 25px;
  border-radius: 10px;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
  float: right;
}
.button_icon:hover,
.button_icon_add:hover {
  /* background: #253755; */
  color: #ffffff !important;
  font-size: 23px;
}
</style>