<template>
  <form @submit.prevent="registerSubmit">
    <div class="register container">
      <div class="form-reg">
        <div class="text-form text-start">
          Crea tu cuenta con:
          <span class="icons">
            <img
              src="../assets/img/facebook.png"
              alt=""
              width="50"
              class="icon-register mx-2"
            />
            <img
              src="../assets/img/google.png"
              alt=""
              width="50"
              class="icon-register mx-2"
            />
          </span>
        </div>

        <div>
          <div class="text-form text-start">Nombre</div>
          <input
            type="text"
            autocomplete="off"
            placeholder="Nombre de usuario..."
            maxlength="45"
            class="form-control"
            onkeypress="return (event.charCode == 08 || event.charCode >=  65 && event.charCode <= 90 || event.charCode >=  97 && event.charCode <= 122 || event.charCode == 241 || event.charCode == 209)"
            v-model="register.name"
          />
          <div
            class="input-errors"
            v-for="error of v$.register.name.$errors"
            :key="error.$uid"
          >
            <div class="error">{{ error.$message }}</div>
          </div>
        </div>
        <div>
          <div class="text-form text-start">Apellido</div>
          <input
            type="text"
            autocomplete="off"
            placeholder="Apellido..."
            maxlength="45"
            class="form-control"
            v-model="register.last_name"
            onkeypress="return (event.charCode == 08 || event.charCode >=  65 && event.charCode <= 90 || event.charCode >=  97 && event.charCode <= 122 || event.charCode == 241 || event.charCode == 209)"
          />
        </div>
        <div
          class="input-errors"
          v-for="error of v$.register.last_name.$errors"
          :key="error.$uid"
        >
          <div class="error">{{ error.$message }}</div>
        </div>
        <div>
          <div class="text-form text-start">Correo</div>
          <input
            autocomplete="off"
            placeholder="Correo..."
            maxlength="64"
            class="form-control"
            v-model="register.email"
            onkeypress="return (event.charCode == 08 || event.charCode >=  64 && event.charCode <= 90 || event.charCode >=  97 && event.charCode <= 122 || event.charCode == 241 || event.charCode == 209 || event.charCode == 46 || event.charCode == 95 || event.charCode == 45)"
          />
          <div
            class="input-errors"
            v-for="error of v$.register.email.$errors"
            :key="error.$uid"
          >
            <div class="error">{{ error.$message }}</div>
          </div>
        </div>
        <div>
          <div class="text-form text-start">Edad</div>
          <input
            type="text"
            autocomplete="off"
            placeholder="Edad..."
            maxlength="2"
            class="form-control"
            v-model="register.age"
            onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
          />
          <div
            class="input-errors"
            v-for="error of v$.register.age.$errors"
            :key="error.$uid"
          >
            <div class="error">{{ error.$message }}</div>
          </div>
        </div>
        <div>
          <div class="text-form text-start">Contraseña</div>
          <div class="row">
            <div class="col">
              <input
                :type="register.showPass1"
                autocomplete="off"
                placeholder="Contraseña..."
                class="form-control"
                v-model="register.pass"
              />
              <div
                class="input-errors"
                v-for="error of v$.register.pass.$errors"
                :key="error.$uid"
              >
                <div class="error">{{ error.$message }}</div>
              </div>
            </div>
            <div class="col-1 my-1">
              <span @click="changeEye1">
                <span v-show="register.eye1"
                  ><i class="icon-input fas fa-eye-slash"></i
                ></span>
                <span v-show="register.noEye1"
                  ><i class="icon-input far fa-eye"></i
                ></span>
              </span>
            </div>
          </div>
        </div>
        <div>
          <div class="text-form text-start">Confirmar Contraseña</div>
          <div class="row">
            <div class="col">
              <input
                :type="register.showPass2"
                autocomplete="off"
                placeholder="Confirmar Contraseña..."
                class="form-control"
                v-model="register.confirm_pass"
              />
              <div
                class="input-errors"
                v-for="error of v$.register.confirm_pass.$errors"
                :key="error.$uid"
              >
                <div class="error">{{ error.$message }}</div>
              </div>
            </div>
            <div class="col-1 my-1">
              <span @click="changeEye2">
                <span v-show="register.eye2"
                  ><i class="icon-input fas fa-eye-slash"></i
                ></span>
                <span v-show="register.noEye2"
                  ><i class="icon-input far fa-eye"></i
                ></span>
              </span>
            </div>
          </div>
        </div>
        <div class="content-button-send">
          <button class="buttom-send">ENVIAR</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import useValidate from "@vuelidate/core";
import { required, minLength, email, sameAs } from "@vuelidate/validators";
import { reactive } from "vue-demi";

export default {
  data() {
    return {
      v$: useValidate(),
      register: {
        name: "",
        last_name: "",
        email: "",
        age: "",
        pass: "",
        confirm_pass: "",
        showPass1: "password",
        showPass2: "password",
        eye1: true,
        eye2: true,
        noEye1: false,
        noEye2: false
      },
      url: process.env.VUE_APP_URL
    };
  },
  validations() {
    return {
      register: {
        name: {
          required,
          minLength: minLength(4)
        },
        last_name: {
          required
        },
        email: {
          required,
          email
        },
        age: {
          required
        },
        pass: {
          required,
          minLength: minLength(6)
        },
        confirm_pass: {
          required,
          sameAs: sameAs(this.register.pass, "contraseña")
        }
      }
    };
  },
  methods: {
    async registerSubmit() {
      // validate
      this.v$.$touch();
      if (!this.v$.$invalid) {
        // alert("valid");
        await axios
          .post(this.url + "/api/usuarios", {
            nombre: this.register.name,
            apellido: this.register.last_name,
            correo: this.register.email,
            edad: this.register.age,
            contrasena: this.register.pass,
            estado: "Activo",
            confirmar_contrasena: this.register.confirm_pass,
            // creation_date: moment().format("l"),
            rol: "Estudiante"
          })
          .then(response => {
            // console.log(response);
            if (response.data.errores) {
              this.$swal({
                icon: "error",
                text: "Este correo electrónico ya fue registrado",
                confirmButtonText: "Ok",
                confirmButtonColor: "#78B829",
                focusConfirm: true
              });
            } else {
              this.$swal({
                icon: "success",
                text: "Registro completado",
                confirmButtonText: "Confirmar",
                confirmButtonColor: "#78B829",
                focusConfirm: true
              }).then(result => {
                if (result.isConfirmed) {
                  location.reload();
                }
              });
            }
          })
          .catch(e => {
            console.log(e);
          });
      }
    },
    changeEye1() {
      if (this.register.showPass1 === "password") {
        this.register.showPass1 = "text";
        this.register.eye1 = false;
        this.register.noEye1 = true;
      } else {
        this.register.showPass1 = "password";
        this.register.noEye1 = false;
        this.register.eye1 = true;
      }
    },
    changeEye2() {
      if (this.register.showPass2 === "password") {
        this.register.showPass2 = "text";
        this.register.eye2 = false;
        this.register.noEye2 = true;
      } else {
        this.register.showPass2 = "password";
        this.register.noEye2 = false;
        this.register.eye2 = true;
      }
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
.register {
  max-width: 30rem;

  /* height: 30rem; */
  /* margin-top: 15px; */
  border-radius: 15px;
  background: #d7dade;
}
.form-reg {
  padding-top: 10px !important;
  /* max-height: 10%; */
  /* padding-left: 15px; */
  color: #1d2945;
}
.text-form {
  font-family: "Poiret One", cursive;
  color: #1d2945 !important;
  font-size: 2.4vmin;
  margin-left: 2px;
}

.icons {
  margin-top: -10px;
}
.icon-register {
  cursor: pointer;
  width: 2.5vmin;
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* display: inline-block; */
}
.icon-input {
  font-size: 2.4vmin;
  cursor: pointer;
  /* margin-right: 20px; */
}
.col-1 {
  padding: 0px;
}
.content-button-send {
  margin-top: 10px;
  padding-bottom: 10px;
}
.buttom-send {
  /* width: 110px; */
  border: none;
  background: #1d2945;
  color: white;
  font-family: "Fredoka One", cursive;
  /* font-size: 20px; */
  font-size: 2.3vmin;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 15px;
  /* font-weight: bold; */
}
.buttom-send:hover {
  font-weight: bold;
  /* font-size: 21px; */
}
.form-control {
  font-size: 2.4vmin;
}
</style>