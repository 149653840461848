<template></template>

<script>
export default {
  props: ["id_course","id_leccion","index_show"],
  data() {
    this.$router.replace({
      path: "/cardMemory/:"+this.id_course+"/:"+this.id_leccion+"/:"+this.index_show,
      name: "CardMemory",
    });
    return {};
  },
};
</script>

<style scoped>
</style>