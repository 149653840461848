<template>
  <div class="main" v-if="course">
    <NavMain @search="handleSearch" />
    <section>
      <div class="container">
        <div class="row">
          <div class="pos">
            <div class="content">
              <div class="main-title">
                Descarga el material
                <span class="icon-dowload"
                  ><i class="fas fa-download"></i
                ></span>
              </div>
              <p class="main-text">
                Este material le ayudará a reforzar lo aprendido
              </p>
              <p class="course-title">
                Curso: <span class="course-name">{{ course.nombre }}</span>
              </p>
              <div class="">
                <ul>
                  <li
                    v-for="lesson in trueLessons"
                    :key="lesson._id"
                    class="title-lesson"
                  >{{ lesson.nombre }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="fixed-bottom">
      <div class="corner position-absolute bottom-0 end-0">
        <img
          src="../../assets/img/ESQUINA-VERDE.png"
          alt=""
          class="green rotate"
        />
      </div>
    </div>
  </div>
  <div class="text-center" v-else>
    <p>Cargando Materials...</p>
  </div>
</template>

<script>
import NavMain from "@/components/NavMain.vue";
import axios from "axios";
export default {
  props: ["id"],
  components: {
    NavMain,
  },
  data() {
    return {
      course: null,
      lessons: null,
      trueLessons: null,
      url: process.env.VUE_APP_URL,
    };
  },
  async mounted() {
    var responseCourse = await axios.get(
      this.url + "/api/cursos/" + this.id,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    this.course = responseCourse.data;
    // console.log(responseCourse.data)
    var responseLessons = await axios.get(
      this.url + "/api/lecciones",
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    this.lessons = responseLessons.data.lecciones;
    // console.log(this.lessons)
    var arrayLessons = [];
    this.lessons.forEach((el) => {
      if (el.curso.$oid == this.course.id) {
        arrayLessons.push(el);
      }
    });
    this.trueLessons = arrayLessons;
    // this.id_lesson = arrayLessons[0]._id.$oid
  },
};
</script>

<style scoped>
.main {
  position: relative;
}
.content {
  margin-top: 12rem;
}
.main-title {
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  font-size: 5rem;
  z-index: 2;
}
.icon-dowload {
  color: #7ab929;
}
.main-text {
  color: #1d2945;
  font-family: "Poiret One", cursive !important;
  font-size: 20px;
}
.course-title {
  font-family: "Fredoka One", cursive;
  color: #7ab929;
  font-size: 3rem;
}
.course-name {
  color: #1d2945;
}
.title-lesson {
  background: #D7DADE;
  color: #1d2945;
}
/* POSICION Y FONDO */
.fixed-bottom {
  z-index: 1;
}
.pos {
  /* position: absolute; */
  z-index: 2;
  display: flex;
}
.rotate {
  transform: scaleX(-1);
}
.corner {
  width: 80%;
}
.green {
  width: 100%;
  height: 100%;
}
</style>