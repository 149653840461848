<template>
  <div class="container py-5">
    <div class="">
      <div class="form-course">
        <form @submit.prevent="handleSumbit">
          <label class="d-block">Nombre del curso</label>
          <input
            v-model="formCourse.name"
            type="text"
            autocomplete="off"
            class="form-control"
          />
          <div
            class="input-errors"
            v-for="error of v$.formCourse.name.$errors"
            :key="error.$uid"
          >
            <div class="error">{{ error.$message }}</div>
          </div>
          <label class="d-block">Autor del curso</label>
          <input
            v-model="formCourse.author"
            type="text"
            autocomplete="off"
            class="form-control"
          />
          <div
            class="input-errors"
            v-for="error of v$.formCourse.author.$errors"
            :key="error.$uid"
          >
            <div class="error">{{ error.$message }}</div>
          </div>
          <label class="d-block">Duración del curso</label>
          <div class="row">
            <div class="col">
              <span class="main-text">Hora:</span>
              <select
                class="form-select mb-3"
                onfocus="this.size=4;"
                onblur="this.size=1;"
                onchange="this.size=1; this.blur();"
                v-model="formCourse.hours"
              >
                <option selected>Seleccione cuantas horas dura el curso</option>
                <option v-for="h in 23" :key="h">
                  {{ h }}
                </option>
              </select>
              <div
                class="input-errors"
                v-for="error of v$.formCourse.hours.$errors"
                :key="error.$uid"
              >
                <div class="error">{{ error.$message }}</div>
              </div>
            </div>
            <div class="col">
              <span class="main-text">Minutos:</span>
              <select
                class="form-select mb-3"
                aria-label=".form-select-lg example"
                onfocus="this.size=4;"
                onblur="this.size=1;"
                onchange="this.size=1; this.blur();"
                v-model="formCourse.minutes"
              >
                <option selected>Seleccione los minutos</option>
                <option v-for="m in 59" :key="m">{{ m }}</option>
              </select>
              <div
                class="input-errors"
                v-for="error of v$.formCourse.minutes.$errors"
                :key="error.$uid"
              >
                <div class="error">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <label class="d-block">Descripción del curso</label>
          <textarea
            type="text"
            autocomplete="off"
            class="form-control"
            v-model="formCourse.description"
          >
          </textarea>
          <div
            class="input-errors"
            v-for="error of v$.formCourse.description.$errors"
            :key="error.$uid"
          >
            <div class="error">{{ error.$message }}</div>
          </div>
          <label class="d-block">Temas principales (cartilla)</label>
          <textarea
            type="text"
            autocomplete="off"
            class="form-control"
            v-model="formCourse.card"
          >
          </textarea>
          <div
            class="input-errors"
            v-for="error of v$.formCourse.card.$errors"
            :key="error.$uid"
          >
            <div class="error">{{ error.$message }}</div>
          </div>
          <div class="row">
            <label class="d-block">Selecciona la imagen del curso</label>
            <div class="col-6">
              <img :src="image" class="preview-image" />
              <input
                class="form-control form-control-lg"
                type="file"
                id="formFile"
                ref="inputImage"
                required
                accept="image/*"
                @change="fileChange"
              />
            </div>
            <div class="col-1">
              <div @click="resetImage" v-show="ifImage">
                <i class="far fa-times-circle icon-cross icon-cross"></i>
              </div>
            </div>
          </div>
          <button class="btn-save my-3">Guardar Curso</button>
        </form>
        <button
          :disabled="disabledFormLessons == false"
          :class="{ 'btn-disabled': disabledFormLessons == false }"
          type="button"
          @click="insertFormLesson"
          class="btn-form-lesson"
        >
          Formulario de lecciones
          <label v-show="ifFormLesson == false">+</label
          ><label v-show="ifFormLesson == true">-</label>
        </button>
        <form-lessons v-if="ifFormLesson" :idCourse="formCourse.id" />
      </div>
    </div>
  </div>
</template>

<script>
import formLessons from "@/components/formLessons.vue";

import axios from "axios";
import moment from "moment";
// Vuelidate
import useValidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
export default {
  components: {
    formLessons
  },
  data() {
    return {
      v$: useValidate(),
      image: "",
      ifImage: false,
      ifFormLesson: false,
      disabledFormLessons: false,
      selectedFile: null,
      formCourse: {
        id: "",
        name: "",
        author: "",
        card: "",
        hours: "",
        minutes: "",
        description: "",
        imageName: ""
      },
      url: process.env.VUE_APP_URL
    };
  },
  validations() {
    return {
      formCourse: {
        name: {
          required,
          minLength: minLength(4)
        },
        author: {
          required
        },
        card: {
          required
        },
        hours: {
          required
        },
        minutes: {
          required
        },
        description: {
          required,
          minLength: minLength(4)
        }
      }
    };
  },
  methods: {
    async handleSumbit() {
      // validate
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.$swal({
          icon: "warning",
          title: "Crear Curso!",
          text: "¿ Está seguro de CREAR este curso ?",
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          confirmButtonColor: "#7AB929",
          cancelButtonColor: "#CB3234",
          focusCancel: true,
          cancelButtonText: "Cancelar"
        }).then(async result => {
          if (result.isConfirmed) {
            // Guardando imagen del curso
            var fd = new FormData();
            fd.append("archivo", this.selectedFile, this.selectedFile.name);
            // console.log(this.selectedFile);
            await axios
              .post(this.url + "/api/archivos", fd, {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Credentials": true,
                  "Access-Control-Allow-Methods":
                    "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                  Authorization: "Bearer " + localStorage.getItem("token")
                }
              })
              .then(response => {
                // console.log(response);
                this.formCourse.imageName = response.data.archivo;
                // console.log(this.formCourse.imageName)
              })
              .catch(e => {
                console.log(e);
              });
            // Guardando datos del curso
            await axios
              .post(
                this.url + "/api/cursos",
                {
                  nombre: this.formCourse.name,
                  autor: this.formCourse.author,
                  cartilla: this.formCourse.card,
                  duracion:
                    this.formCourse.hours +
                    "h" +
                    " " +
                    this.formCourse.minutes +
                    "m",
                  fecha_inicio: moment().format("l"),
                  puntuacion: 3.5,
                  descripcion: this.formCourse.description,
                  imagen: this.formCourse.imageName
                },
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": true,
                    "Access-Control-Allow-Methods":
                      "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                    Authorization: "Bearer " + localStorage.getItem("token")
                  }
                }
              )
              .then(response => {
                // console.log(response);
                this.formCourse.id = response.data.curso.id;
                // console.log("id del curso:", this.formLesson.course_id);
              })
              .catch(e => {
                console.log(e);
              });
            this.$swal({
              title: "Guardando Curso",
              text: "Porfavor espere mientras se guardan los datos del curso",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: false
            }).then(result => {
              this.$swal({
                icon: "success",
                title: "Los datos se han guardado satisfactoriamente!",
                confirmButtonText: "Gracias!",
                confirmButtonColor: "#7AB929"
              });
              this.disabledFormLessons = true;
              // .then((result) => {
              //   this.$router.push({ name: "Home" });
              // });
            });
          }
        });
      }
      // else {
      //   alert("invalid");
      // }
    },

    fileChange(e) {
      this.selectedFile = e.target.files[0];
      // console.log(this.selectedFile)
      const file = e.target.files[0];
      this.image = URL.createObjectURL(file);
      this.ifImage = true;
    },
    resetImage() {
      this.image = "";
      this.$refs.inputImage.value = null;
      this.ifImage = false;
    },
    insertFormLesson() {
      if (this.ifFormLesson == false) {
        this.ifFormLesson = true;
      } else {
        this.ifFormLesson = false;
      }
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");
.form-course {
  background: #d7dade;
  padding: 25px;
  border-radius: 15px;
  /* font-size: 3vmin !important; */
}
.main-text {
  font-family: "Poiret One", cursive !important;
  color: #1d2945;
  /* font-size: 25px; */
  font-size: 3vmin;
}
label {
  color: #1d2945 !important;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.5rem; */
  font-size: 3vmin;
}
input,
textarea {
  /* margin-top: 5px; */
  border-radius: 15px;
  font-family: "Poiret One", cursive;
  color: #1d2945;
  /* font-size: 18px; */
  font-size: 3vmin;
  font-weight: bold;
  text-align: start;
}
option,
select {
  /* font-size: 18px; */
  font-size: 3vmin;
  font-family: "Fredoka One", cursive;
  border-radius: 15px;
}
.preview-image {
  /* max-width: 327px; */
  /* max-height: 182px; */
  max-width: 40vmin;
  border-radius: 15px;
}
input[type="file"] {
  border: 2px solid #fff;
  font-family: "Poiret One", cursive !important;
  font-weight: bold !important;
  font-size: 2.5vmin;
}
.icon-cross {
  color: #1d2945;
  /* font-size: 28px; */
  font-size: 3vmin;
  font-weight: bold;
  /* margin-left: -50px; */
  margin-left: -2.8vmin;
  cursor: pointer;
}
.btn-save {
  margin: auto;
  background: #1d2945;
  color: #fff;
  font-family: "Fredoka One", cursive;
  font-size: 2.5vmin;
  border: none;
  display: block;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  /* font-size: 20px; */
}
.btn-form-lesson {
  color: #fff;
  background: #7ab929;
  padding: 5px;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.5rem; */
  font-size: 2.5vmin;
  text-align: center;
  display: inline-block;
  border: none;
  cursor: pointer;
}
.btn-disabled {
  cursor: not-allowed !important;
  background: #d7dade;
  border: 2px solid #fff;
  color: #1d2945;
}
</style>