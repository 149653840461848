<template>
  <NavMain />
  <div class="main" v-if="course">
    <section>
      <div class="container">
        <div class="row">
          <!-- <div class="left"> -->
          <div class="pos">
            <div class="col-7">
              <div class="main-title">
                <p>
                  <span class="text-green">{{ course.nombre }}</span>
                  
                </p>
                <div class="article">
                  <p>
                    {{ course.descripcion }}
                  </p>
                </div>
                <p class="title2">Docente</p>
                <div class="article">
                  <div class="cont-img">
                    <img
                    class="rounded-circle img-teacher mx-4 img-fluid"
                    src="@/assets/img/img2.jpg"
                    alt=""
                  />
                  </div>
                  {{ course.autor }}
                </div>
                <p class="title2">¿Qué aprenderás en este curso?</p>
                <span v-for="lesson in lessonsCourse" :key="lesson">
                  <ul class="article list">
                    <li class="list-element"> {{ lesson.nombre }}</li>
                  </ul>
                </span>
              </div>
            </div>
            <div class="col-5 offset-md-1">
              <div class="video-card">
                <div class="d-flex justify-content-center">
                  <img
                    class="video-img img-thumbnail img-responsive"
                    :src="url + '/api/archivos/' + course.imagen"
                  />
                </div>
                <div v-if="first_lesson">
                  <router-link :to="{ name: 'Course', params: { id: id, index_show: 0 } }">
                  <div @click="Asign" class="btn-start">¡EMPIEZA YA!</div>
                </router-link>
                </div>
                <p class="info">Información del curso</p>
                <div class="info-list">
                  <p><i class="far fa-clock"></i> {{ course.duracion }}</p>
                  <!-- <p><i class="fab fa-youtube"></i> 5 videos</p> -->
                  <p class="text-size">
                    <i class="far fa-check-circle"></i> {{ course.cartilla }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="fixed-bottom">
      <!-- <div class="corner-green position-absolute bottom-0 start-0">
        <img src="../../assets/img/ESQUINA-VERDE.png" alt="" class="green" />
      </div> -->
      <div class="corner position-absolute bottom-0 end-0">
        <img
          src="../../assets/img/ESQUINA-VERDE.png"
          alt=""
          class="green rotate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NavMain from "@/components/NavMain.vue";
import axios from "axios";
export default {
  components: {
    NavMain
  },
  props: ["id"],
  data() {
    return {
      course: null,
      // lessons: null,
      lessonsCourse: null,
      first_lesson: null,
      user: null,
      firstTime: false,
      url: process.env.VUE_APP_URL
    };
  },
  methods: {
    async Asign() {
      var i = 0;
      while (i < this.user.length) {
        if (this.user[i].$oid == this.id) {
          // console.log("si esta")
          this.firstTime = true;
          i = this.user.length;
        }
        i++;
      }

      if (this.firstTime != true) {
        // console.log("no existe, se debe iscribir");
        axios
          .post(
            this.url + "/api/asignar",
            { curso_id: this.id },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
              }
            }
          )
          .then(response => {
            // console.log(response);
          })
          .catch(e => {
            console.log("error: ", e);
          });
      }
      // else{

      //   console.log("ya existe, no se inscribe")
      // }
    }
  },
  async mounted() {
    var responseCourse = await axios.get(this.url + "/api/cursos/" + this.id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    this.course = responseCourse.data;
    // console.log("response course: ", this.course);
    // var responseLessons = await axios.get(this.url + "/api/lecciones", {
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("token")
    //   }
    // });
    // this.lessons = responseLessons.data.lecciones;
    // console.log(this.lessons);
    var responseUser = await axios.get(
      this.url + "/api/usuarios/" + localStorage.getItem("id"),
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }
    );
    this.user = responseUser.data.cursos;
    //
    var responseLessonsCourse = await axios.get(
      this.url + `/api/lecciones?curso_id=${this.course.id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }
    );
    this.lessonsCourse = responseLessonsCourse.data.lecciones;
    // console.log("lecciones del curso: ",this.lessonsCourse);
    this.first_lesson = this.lessonsCourse[0];
    // console.log("first: ",this.first_lesson);
    // console.log(this.lessons.curso.$oid)
    // this.lessons.forEach(el => {
    // if(el._id.$oid == '6127e621885bc6f92ceefeda'){
    // console.log(el.curso.$oid)
    // }
    // el.curso.forEach(el => {
    //   console.log(el)
    // });
    // Mostrar las curso_id de las lecciones
    // console.log(el)
    // });
  }
};
</script>

<style scoped>
.main {
  position: relative;
  margin-bottom: 30vmin;
}
.main-title {
  /* margin-top: 12rem; */
  margin-top: 16vmin;
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  /* font-size: 5rem; */
  font-size: 8vmin;
  z-index: 2;
}
.title2 {
  /* margin-top: 12rem; */
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  /* font-size: 3rem; */
  font-size: 4vmin;
  z-index: 2;
}
.text-green {
  color: #7ab929 !important;
}
.article {
  font-family: "Poiret One", cursive !important;
  color: #1d2945;
  /* font-size: 30px; */
  font-size: 3.5vmin;
}
.text-size {
  word-wrap: break-Word;
}
.cont-img{
  max-width: 20vmin;
}
.img-teacher {
  /* width: 150px; */
  /* height: 150px; */
  width: 100%;
  height: 18vmin;
}
ul {
  list-style: none;
  padding: 0px;
  /* padding-top: -15px; */
  margin-left: 10px;
}
ul li::before {
  content: "\2022";
  color: #7ab929;
  display: inline-block;
  padding-right: 20px;
  font-size: 60px;
  line-height: 25px;
  vertical-align: -11px;
  width: 15px;
}
.video-card {
  /* margin-top: 12rem; */
  margin-top: 16vmin;
  padding-bottom: 15px;
  background-color: rgba(215, 218, 222, 0.5);
  /* background: #d7dade; */
  /* max-width: 15rem; */
  height: auto;
  /* max-height: 30rem; */
}
/* .content-img {
  display: block;
  margin: auto;
  max-width: 25.5rem;
  max-height: 25.5.5rem;
} */
.video-img {
  margin: auto;
  /* width: 25.5rem; */
  /* height: 25.5rem; */
  width: 50vmin;
  height: 50vmin;
}
.btn-start {
  background: #7ab929;
  color: #fff;
  max-width: 20rem;
  font-family: "Fredoka One", cursive;
  /* font-size: 2.2rem; */
  font-size: 4vmin;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}
.info {
  color: #1d2945;
  font-family: "Fredoka One", cursive;
  /* font-size: 2rem; */
  font-size: 3.4vmin;
  /* padding-left: 50px; */
  padding-left: 2.5vmin;
}
.info-list {
  font-family: "Poiret One", cursive !important;
  /* font-size: 30px; */
  font-size: 3.3vmin;
  /* padding-left: 70px; */
  padding-left: 2.5vmin;
  color: #1d2945;
}
.fixed-bottom {
  z-index: 1;
}
.pos {
  /* position: absolute; */
  z-index: 2;
  display: flex;
}
.rotate {
  transform: scaleX(-1);
}
.corner {
  width: 80%;
}
.green {
  width: 100%;
  height: 100%;
}
</style>