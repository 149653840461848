<template>
  <div class="main" v-if="course">
    <NavMain @search="handleSearch" />
    <section>
      <div></div>
      <div class="container-fluid">
        <div class="row">
          <button v-if="is_portrait" @click="show_lessons" class="btn-lessons">
            Lecciones
          </button>
          <!-- <button v-if="is_landscape" @click="show_lessons_landscape" class="btn-lessons">
            Lecciones
          </button> -->
          <div class="pos">
            <div class="col-9">
              <div class="main-video">
                <div v-for="(lesson, index) in trueLessons" :key="lesson._id">
                  <!--  -->

                  <div v-if="id_lesson == index">
                    <p v-if="lesson.contenido" class="video-title">
                      {{ lesson.nombre }}
                    </p>
                    <div v-if="lesson.videoUrl" class="ratio ratio-21x9">
                      <iframe
                        width="560"
                        height="315"
                        :src="
                          'https://www.youtube.com/embed/' + lesson.videoUrl
                        "
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div v-if="lesson.imagen" class="my-3 ratio ratio-21x9">
                      <img
                        :src="url + '/api/archivos/' + lesson.imagen"
                        width="1200"
                        class="img-fluid"
                      />
                    </div>
                    <p v-if="lesson.contenido" class="video-text">
                      {{ lesson.contenido }}
                    </p>
                    <div v-if="lesson.video" class="my-3 ratio ratio-21x9">
                      <video
                        :src="url + '/api/archivos/' + lesson.video"
                        width="900"
                        height="500"
                        controls
                      ></video>
                    </div>
                    <div class="row" v-if="lesson.bloques">
                      <div :class="is_portrait == true ? '' : 'col-7'">
                        <div
                          class="my-5"
                          v-for="block in lesson.bloques"
                          :key="block"
                        >
                          <p class="block-title">{{ block.titulo }}</p>
                          <p class="block-text">
                            <!-- <span class="block-subtitle">Contenido: </span> -->
                            <span
                              class="block-text"
                              v-html="`${block.contenido}`"
                            ></span>
                          </p>
                          <div class="alert alert-info">
                            <span class="block-text">
                              <!-- <span class="block-subtitle">Concepto: </span> -->
                              <span class="block-subtitle">
                                {{ block.concepto }}
                              </span>
                            </span>
                            <p class="block-text">
                              <!-- <span class="block-subtitle">Definición: </span> -->
                              <span class="">
                                {{ block.definicion }}
                              </span>
                            </p>
                          </div>
                          <!-- tiktok en portrait -->
                          <div
                            class="tiktok-portrait"
                            v-if="is_portrait == true || is_landscape == true"
                          >
                            <blockquote
                              class="tiktok-embed"
                              :data-video-id="block.enlaceTikTok"
                              style="max-width: 605px;min-width: 325px;"
                            >
                              <section>
                                <!-- <a
                              target="_blank"
                              title="@micadoradogil"
                              href="https://www.tiktok.com/@micadoradogil"
                              >@micadoradogil</a
                            > -->
                                <p>
                                  <!-- Son la hostia -->
                                  <!-- <a
                                title="consejo"
                                target="_blank"
                                href="https://www.tiktok.com/tag/consejo"
                                >#consejo</a
                              > -->
                                  <!-- <a
                                title="bolivia"
                                target="_blank"
                                href="https://www.tiktok.com/tag/bolivia"
                                >#bolivia</a
                              > -->
                                </p>
                                <!-- <a
                              target="_blank"
                              title="♬ sonido original - Nina Lurtzun🐢"
                              href="https://www.tiktok.com/music/sonido-original-7000393071070415622"
                              >♬ sonido original - Nina Lurtzun🐢</a
                            > -->
                              </section>
                            </blockquote>
                          </div>
                          <hr />
                        </div>
                      </div>
                      <div class="col-5">
                        <div
                          class="my-5"
                          v-for="block in lesson.bloques"
                          :key="block"
                        >
                          <div
                            v-if="is_portrait == false && is_landscape == false"
                          >
                            <blockquote
                              class="tiktok-embed"
                              :data-video-id="block.enlaceTikTok"
                              style="max-width: 605px;min-width: 325px;"
                            >
                              <section>
                                <!-- <a
                              target="_blank"
                              title="@micadoradogil"
                              href="https://www.tiktok.com/@micadoradogil"
                              >@micadoradogil</a
                            > -->
                                <p>
                                  <!-- Son la hostia -->
                                  <!-- <a
                                title="consejo"
                                target="_blank"
                                href="https://www.tiktok.com/tag/consejo"
                                >#consejo</a
                              > -->
                                  <!-- <a
                                title="bolivia"
                                target="_blank"
                                href="https://www.tiktok.com/tag/bolivia"
                                >#bolivia</a
                              > -->
                                </p>
                                <!-- <a
                              target="_blank"
                              title="♬ sonido original - Nina Lurtzun🐢"
                              href="https://www.tiktok.com/music/sonido-original-7000393071070415622"
                              >♬ sonido original - Nina Lurtzun🐢</a
                            > -->
                              </section>
                            </blockquote>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--btn Game -->
                    <div class="block-title" v-if="btn_questionary == false">
                      <div v-if="lesson.bloques != undefined">
                        Hora de jugar!
                      </div>
                      <hr />
                      <p class="block-text" v-if="lesson.bloques != undefined">
                        Ahora prueba tus habilidades según lo aprendido, gana y
                        pasa a la siguiente lección!!
                      </p>
                      <div
                        class="content-btn-game justify-content-center"
                        v-if="btn_questionary == false"
                      >
                        <div
                          v-if="lesson.bloques != undefined"
                          class="square-left-game"
                        ></div>
                        <div
                          v-if="lesson.bloques != undefined"
                          @click="game(lesson._id.$oid, lesson.bloques, index)"
                          class="btn-game"
                        >
                          JUGAR!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- landscape -->
            <div v-if="is_portrait == false" class="col-3 position-fixed end-0">
              <div class="main-tabs">
                <div class="content-top-btns justify-content-center">
                  <!-- <router-link :to="{ name: 'Materials', params: { id: id } }"> -->
                  <div class="mx-4 btn-top">
                    <!-- <div class="tag-left-des"></div> -->
                    <a
                      href="https://drive.google.com/drive/u/0/folders/1hWkzTrc2oWnZreOSfA9TmafD7U732RMt"
                      target="_blank"
                      ><div class="tag-right-des">
                        Descarga material
                      </div></a
                    >
                  </div>
                  <!-- </router-link> -->
                  <div class="mx-4 btn-top">
                    <!-- <div class="tag-left-rep rep-color"></div> -->
                    <a
                      href="https://drive.google.com/drive/u/0/folders/1NhRrBxdbQoBByAadNEKOKDFfMi3nV5j-"
                      target="_blank"
                      ><div class="tag-right-rep">Repasa lo aprendido</div></a
                    >
                  </div>
                </div>
                <ul>
                  <p class="title-menu">Lecciones</p>
                  <p class="line"></p>
                  <span v-for="(com, index_com) in checks" :key="index_com">
                    <li
                      class=""
                      v-for="(lesson, index) in trueLessons"
                      :key="lesson._id"
                    >
                      <div
                        v-if="index_com == index"
                        :class="[
                          com.active == true ? 'no-disabled' : 'disabled'
                        ]"
                        class="item-list"
                        @click="show(index)"
                      >
                        <span class="">{{ index }}</span
                        >. {{ lesson.nombre }}
                        <div class="check" v-if="com.check == true">
                          <i class="far fa-check-square"></i>
                        </div>
                      </div>
                    </li>
                  </span>
                  <div class="test-content">
                    <p class="title-menu">Cuestionario</p>
                    <p class="line"></p>
                    <p class="text-test">
                      En la lección final, resuelve el cuestionario y pon a
                      prueba todo lo aprendido
                    </p>
                  </div>
                  <div
                    class="content-btn-test justify-content-center"
                    v-if="btn_questionary == true"
                  >
                    <!-- <div class="square-left"></div> -->
                    <div @click="questionary(id)" class="btn-test">
                      CUESTIONARIO
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <!-- portrait -->
            <div v-if="show_btn == true" class="position-fixed end-0 col-5">
              <!-- collapse -->
              <div class="main-tabs-portrait ">
                <div class="content-top-btns justify-content-center">
                  <!-- <router-link :to="{ name: 'Materials', params: { id: id } }"> -->
                  <div class="mx-4 btn-top">
                    <!-- <div class="tag-left-des"></div> -->
                    <a
                      href="https://drive.google.com/drive/u/0/folders/1hWkzTrc2oWnZreOSfA9TmafD7U732RMt"
                      target="_blank"
                      ><div class="tag-right-des">
                        Descarga material
                      </div></a
                    >
                  </div>
                  <!-- </router-link> -->
                  <div class="mx-4 btn-top">
                    <!-- <div class="tag-left-rep rep-color"></div> -->
                    <a
                      href="https://drive.google.com/drive/u/0/folders/1NhRrBxdbQoBByAadNEKOKDFfMi3nV5j-"
                      target="_blank"
                      ><div class="tag-right-rep">Repasa lo aprendido</div></a
                    >
                  </div>
                </div>
                <ul>
                  <p class="title-menu">Lecciones</p>
                  <p class="line"></p>
                  <span v-for="(com, index_com) in checks" :key="index_com">
                    <li
                      class=""
                      v-for="(lesson, index) in trueLessons"
                      :key="lesson._id"
                    >
                      <div
                        v-if="index_com == index"
                        :class="[
                          com.active == true ? 'no-disabled' : 'disabled'
                        ]"
                        class="item-list"
                        @click="show(index)"
                      >
                        <span class="">{{ index }}</span
                        >. {{ lesson.nombre }}
                        <div class="check" v-if="com.check == true">
                          <i class="far fa-check-square"></i>
                        </div>
                      </div>
                    </li>
                  </span>
                  <div class="test-content">
                    <p class="title-menu">Cuestionario</p>
                    <p class="line"></p>
                    <p class="text-test">
                      En la lección final, resuelve el cuestionario y pon a
                      prueba todo lo aprendido
                    </p>
                  </div>
                  <div
                    class="content-btn-test justify-content-center"
                    v-if="btn_questionary == true"
                  >
                    <!-- <div class="square-left"></div> -->
                    <div @click="questionary(id)" class="btn-test">
                      CUESTIONARIO
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="fixed-bottom">
      <div class="corner position-absolute bottom-0 start-0">
        <img src="../../assets/img/ESQUINA-VERDE.png" alt="" class="green" />
      </div>
    </div>
  </div>
  <div class="text-center" v-else>
    <p>Cargando Course...</p>
  </div>
</template>


<script>
// import tiktok_embed from "@/assets/js/tiktok-embed.js"
import NavMain from "@/components/NavMain.vue";
import axios from "axios";
import _ from "lodash";
export default {
  components: {
    NavMain
  },
  props: ["id", "index_show"],
  data() {
    return {
      course: null,
      lessons: null,
      trueLessons: null,
      filteredLessons: null,
      id_lesson: "",
      first_show: true,
      complete_lessons: null,
      disabled: true,
      checks: [],
      first_lesson: null,
      last_lesson_id: 0,
      last_id: "",
      lesson_show: null,
      // index_show: 0,
      btn_questionary: false,
      is_portrait: false,
      is_landscape: false,
      show_btn: false,
      show_btn_landscape: false,
      url: process.env.VUE_APP_URL
    };
  },
  methods: {
    handleSearch(value) {
      if (value && value.length > 0) {
        // console.log(value)
        this.trueLessons = this.filteredLessons.filter(i => {
          const val = value.toLowerCase();
          // console.log(val)
          const nombre = i.nombre && i.nombre.toLowerCase();
          // console.log(nombre)
          if (val && nombre.indexOf(val) !== -1) {
            // console.log(nombre)
            return true;
          }
          return false;
        });
      } else {
        this.trueLessons = this.filteredLessons;
      }
    },
    async show(index) {
      let tiktokScript = await document.createElement("script");
      tiktokScript.type = "text/javascript";
      tiktokScript.async = true;
      tiktokScript.defer = true;
      tiktokScript.src =
        "https://sf16-scmcdn-sg.ibytedtos.com/goofy/tiktok/falcon/embed/embed_v0.0.8.js";
      await document.head.appendChild(tiktokScript);
      if (this.checks[index].active == true) {
        this.id_lesson = index;
        this.first_show = false;
        if (index == this.last_lesson_id) {
          this.btn_questionary = true;
        } else {
          this.btn_questionary = false;
        }
      }
    },
    fullChecks() {
      this.checks = _.range(0, this.trueLessons.length);

      // Llenando checks
      for (let i = 0; i < this.checks.length; i++) {
        if (i == 0) {
          this.checks[i] = {
            check: false,
            active: true
          };
        } else {
          this.checks[i] = {
            check: false,
            active: false
          };
        }
        if (this.complete_lessons[i] != undefined) {
          this.checks[i] = {
            check: true,
            active: true
          };
        }
      }
    },
    questionary(id) {
      // console.log(id)
      this.$swal({
        icon: "info",
        title: "Empezar cuestionario!",
        text:
          "Concluído el curso, resuelva el siguiente cuestionario para finalizar y aprobar el curso. Se le entregará un certificado despues de aprobar el cuestionario. Lea atentamente y conteste las preguntas, hay 2 tipos de preguntas, verdadero-falso y de selección multiple, tenga encuenta aveces existen mas de 2 respuestas correctas. Existe un límite de tiempo para resolver todo el cuestionario. Al aceptar este mensaje comenzaará a correr el tiempo. Suerte!",
        showCancelButton: true,
        confirmButtonText: "Comencemos!",
        confirmButtonColor: "#8FD53B",
        cancelButtonColor: "#CB3234",
        focusCancel: true,
        cancelButtonText: "Aun no estoy listo"
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.push({
            name: "Questionary",
            params: { id: id, nombre_titulo: this.course.nombre, last_lesson: this.last_id }
          });
        }
      });
    },
    game(id_lesson_game, bloques, index_lesson) {
      // console.log("index_lesson: ", index_lesson);
      // console.log("bloques: ",bloques);
      // saca un bloque aleatorio
      var pos = 0;
      pos = Math.floor(Math.random() * bloques.length);
      if (this.trueLessons[index_lesson].bloques.length < 3) {
        // console.log("valor: ",index_lesson % 2 == 0)
        this.$router.push({
          name: "Hangman",
          params: {
            id_course: this.id,
            id_leccion: id_lesson_game,
            block_pos: pos,
            index_show: index_lesson
          }
        });
      } else {
        this.$router.push({
          name: "CardMemory",
          params: {
            id_course: this.id,
            id_leccion: id_lesson_game,
            index_show: index_lesson
          }
        });
      }
      // console.log("pos: ",pos)
      // var bloque_aleatorio =
      // bloques[Math.floor(Math.random() * bloques.length)];
      // this.$router.push({
      //   name: "Hangman",
      //   params: { id_leccion: id_lesson_game, block_pos: pos }
      // });
    },
    async handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
        let tiktokScript = await document.createElement("script");
        tiktokScript.type = "text/javascript";
        tiktokScript.async = true;
        tiktokScript.defer = true;
        tiktokScript.src =
          "https://sf16-scmcdn-sg.ibytedtos.com/goofy/tiktok/falcon/embed/embed_v0.0.8.js";
        await document.head.appendChild(tiktokScript);
        // if (screen.width <= 360 && screen.height <= 760) {
        // this.val_slides = 1;
        // this.val_ratio = 0.8 / 1.2;
        //
        this.is_portrait = true;
        // }
      } else if (orientation === "landscape-primary") {
        // landscape mode
        let tiktokScript = await document.createElement("script");
        tiktokScript.type = "text/javascript";
        tiktokScript.async = true;
        tiktokScript.defer = true;
        tiktokScript.src =
          "https://sf16-scmcdn-sg.ibytedtos.com/goofy/tiktok/falcon/embed/embed_v0.0.8.js";
        await document.head.appendChild(tiktokScript);
        if (
          (screen.width <= 760 && screen.height <= 360) ||
          (screen.width <= 1080 && screen.height <= 810)
        ) {
          this.is_landscape = true;
          this.show_btn = false;
        }
        this.is_portrait = false;
        this.show_btn = false;
      }
    },
    show_lessons() {
      if (this.show_btn === false) {
        this.show_btn = true;
      } else {
        this.show_btn = false;
      }
      // console.log("sho: ", this.show_btn);
    },
    show_lessons_landscape() {
      if (this.show_btn_landscape === false) {
        this.show_btn_landscape = true;
      } else {
        this.show_btn_landscape = false;
      }
    }
  },
  async mounted() {
    if (window.innerHeight > window.innerWidth) {
      //
      this.is_portrait = true;
    } else {
      this.is_portrait = false;
      if (
        (screen.width <= 760 && screen.height <= 360) ||
        (screen.width <= 1080 && screen.height <= 810)
      ) {
        this.is_landscape = true;
      }
    }
    // console.log("land:", this.is_landscape);
    window.addEventListener("orientationchange", this.handleOrientationChange);
    // Script Tiktok
    // console.log("id: ",this.id)
    let tiktokScript = await document.createElement("script");
    tiktokScript.type = "text/javascript";
    tiktokScript.async = true;
    tiktokScript.defer = true;
    tiktokScript.src =
      "https://sf16-scmcdn-sg.ibytedtos.com/goofy/tiktok/falcon/embed/embed_v0.0.8.js";
    await document.head.appendChild(tiktokScript);
    // Data
    var responseCourse = await axios.get(this.url + "/api/cursos/" + this.id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    this.course = responseCourse.data;
    // console.log(responseCourse.data)
    var responseLessons = await axios.get(this.url + "/api/lecciones", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    this.lessons = responseLessons.data.lecciones;
    // console.log("leccioness: ", this.lessons);
    var arrayLessons = [];
    this.lessons.forEach(el => {
      if (el.curso.$oid == this.course.id) {
        arrayLessons.push(el);
      }
    });
    this.trueLessons = arrayLessons;
    this.filteredLessons = this.trueLessons;
    this.last_lesson_id = this.trueLessons.length - 1;
    // console.log("last: ",this.trueLessons[this.last_lesson_id]._id.$oid)
    this.last_id = this.trueLessons[this.last_lesson_id]._id.$oid
    this.first_lesson = this.trueLessons[0];
    // console.log("first: ", this.first_lesson);
    // console.log("lecciones: ", this.trueLessons);
    // lee la tabla completar-leccion para ver los cursos completados
    var responseCompletar = await axios.get(
      this.url +
        "/api/cursos/" +
        this.id +
        "/lecciones-completas/" +
        localStorage.getItem("id"),
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }
    );
    this.complete_lessons = responseCompletar.data;
    // Mostrar leccion
    // console.log("completar: ", responseCompletar);
    // console.log("true: ",this.trueLessons.length)

    // console.log(this.checks)
    // Llena checks
    this.fullChecks();
    // Abilitando la leccion siguiente lección
    for (let i = 0; i < this.checks.length; i++) {
      if (this.checks[i].check == true && this.checks[i].active == true) {
        if (i != this.checks.length - 1) {
          this.checks[i + 1].active = true;
        }
      }
    }
    // console.log("checks: ", this.checks);
    if (this.index_show < this.trueLessons.length) {
      this.show(this.index_show);
    }
  }
};
</script>


<style scoped>
hr {
  color: #7ab929;
  height: 5px;
  font-weight: bold;
}
.main {
  position: relative;
  margin-bottom: 30vmin;
}
.main-video {
  /* margin-top: 12rem; */
  margin-top: 12vmin;
  /* margin-left: 10rem; */
  /* margin-left: 8vmin; */
}
/* CONTENIDO DERECHO */
.main-tabs {
  /* margin-top: 12rem; */
  margin-top: 15vmin;
  padding-bottom: 2rem;
  /* background: #1D2945; */
  background-color: rgba(29, 41, 69, 0.99);
  /* max-height: calc(100vh - 6.5rem); */
  max-height: calc(100vh - 14vmin);
  z-index: 5;
  overflow-y: auto;
}
.portrait-menu {
  position: absolute;
  /* float: right; */
  right: 0;
  /* transform: translate(117%, 0) !important; */
}
.main-tabs-portrait {
  /* margin-top: 12rem; */
  margin-top: 26vmin;
  padding-bottom: 2rem;
  /* background: #1D2945; */
  background-color: rgba(29, 41, 69, 0.99);
  /* max-height: calc(100vh - 6.5rem); */
  max-height: calc(100vh - 14vmin);
  overflow-y: auto;
}
.btn-lessons {
  margin-top: 20.5vmin;
  /* margin-bottom: -40px; */
  width: auto;
  border: none;
  border-radius: 15px;
  color: #fff;
  background-color: #7ab929;
  font-family: "Fredoka One", cursive;
  font-size: 3vmin;
  position: fixed;
  right: 0;
  float: right;
  z-index: 3;
}
.content-top-btns {
  display: flex;
}
.btn-top {
  cursor: pointer;
  display: flex;
  margin-top: 1.5vmin;
  /* height: auto; */
}
.tag-right-des {
  background-color: #fff;
  color: #1d2945;
  max-width: 10rem;
  font-family: "Poiret One", cursive !important;
  /* font-size: 18px; */
  font-size: 2vmin;
  /* margin-top: 15px; */
  margin-top: 1.6vmin;
  /* margin-bottom: 15px; */
  margin-bottom: 1.6vmin;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  padding-left: 10px;
  padding-right: 5px;
  text-align: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-left: 1.5vmin solid #7ab929;
}
/* .tag-left-des {
  background-color: #7ab929;
  margin-top: 1.6vmin;
  margin-bottom: 1.5vmin;
  padding-left: 2vmin;
} */
.tag-right-rep {
  background-color: #fff;
  color: #1d2945;
  max-width: 10rem;
  font-family: "Poiret One", cursive !important;
  /* font-size: 18px; */
  font-size: 2vmin;
  /* margin-top: 15px; */
  margin-top: 1.6vmin;
  /* margin-bottom: 15px; */
  margin-bottom: 1.6vmin;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  padding-left: 10px;
  padding-right: 5px;
  text-align: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-left: 1.5vmin solid #7c8393;
}
/* .tag-left-rep {
  background-color: #7ab929;
  margin-top: 1.6vmin;
  margin-bottom: 1.6vmin;
  padding-left: 2vmin;
} */
/* .rep-color {
  background-color: #7c8393 !important;
} */
.title-menu {
  color: #7ab929;
  /* font-size: 23px; */
  font-size: 2.5vmin;
  font-weight: bold;
  font-family: "Fredoka One", cursive;
  margin-bottom: 8px;
  /* padding-left: 20px; */
  padding-left: 2.3vmin;
}
.line {
  /* max-width: 27rem; */
  max-width: 45vmin;
  border-bottom: 0.2vmin solid #7c8393;
  margin-left: auto;
  margin-right: auto;
}
ul {
  padding-left: 0px;
}
.item-list {
  width: auto;
  color: #fff;
  padding-left: 15px;
  background: #7c8393;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: "Poiret One", cursive !important;
  /* font-size: 32px; */
  font-size: 3.4vmin;
  cursor: pointer;
}
.item-list:hover {
  color: #7ab929;
  font-weight: bold;
}
.check {
  /* font-size: 30px; */
  font-size: 3.2vmin;
  margin-left: 10px;
  font-weight: bold;
  display: inline;
  vertical-align: center;
  color: #7ab929;
}
.disabled {
  cursor: not-allowed;
}
.disabled:hover {
  opacity: 0.5;
}
.no-disabled {
  cursor: pointer;
}
.test-content {
  /* margin-top: 0.5rem; */
  margin-bottom: 3rem;
}
.text-test {
  color: #fff;
  /* font-size: 25px; */
  font-size: 2.5vmin;
  padding-left: 20px;
  font-family: "Poiret One", cursive !important;
}
.content-btn-test {
  display: flex;
  cursor: pointer;
}
.btn-test {
  background-color: #fff;
  color: #7ab929;
  font-family: "Fredoka One", cursive;
  /* font-size: 2.2rem; */
  font-size: 2.5vmin;
  /* margin-top: 20px; */
  /* margin-top: 1.5vmin; */
  /* margin-bottom: 20px; */
  /* margin-bottom: 7vmin; */
  padding-top: 3px;
  /* margin-right: 35px; */
  margin: auto;
  padding: auto;
  /* padding-bottom: 0px; */
  /* padding-left: 40px; */
  padding-left: 1.5vmin;
  /* padding-right: 40px; */
  padding-right: 1.5vmin;
  text-align: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-left: 1.4vmin solid #7ab929;
}
/* .square-left {
  background-color: #7ab929;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 55px;
  margin: auto;
  padding-left: 1.5vmin;
  margin-left: 50px;
  display: inline;
  padding-top: 10px;
  padding-bottom: 10px;
} */
.content-btn-game {
  display: flex;
  /* cursor: pointer; */
}
.btn-game {
  /* width: auto; */
  background-color: #7ab929;
  color: #fff;
  font-family: "Fredoka One", cursive;
  /* font-size: 2.2rem; */
  font-size: 3.8vmin;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  cursor: pointer;
}
.square-left-game {
  /* width: 5px; */
  background-color: #1d2945;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
/* CONTENIDO IZQUIERDO */
.video-title {
  /* margin-top: 5rem; */
  margin-top: 16vmin;
  color: #1d2945;
  font-family: "Fredoka One", cursive;
  /* font-size: 4rem; */
  font-size: 7vmin;
}
.video-text {
  color: #1d2945;
  font-family: "Poiret One", cursive;
  /* font-size: 1.8rem; */
  font-size: 4vmin;
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: center;
}
.block-title {
  color: #7ab929;
  font-family: "Fredoka One", cursive;
  /* font-size: 2rem; */
  font-size: 3.5vmin;
  text-align: left;
}
.block-subtitle {
  color: #0D5056;
  /* font-size: 23px; */
  font-size: 3.8vmin;
  /* font-weight: bold; */
  font-family: "Fredoka One", cursive;
  /* margin-bottom: 8px; */
  /* padding-left: 20px; */
}
.block-text {
  /* color: #1d2945; */
  color: #0D5056;
  font-family: "Poiret One", cursive;
  /* font-size: 1.8rem; */
  font-size: 3.5vmin;
  text-align: justify;
  word-wrap: break-word;
  white-space: pre-wrap;
  /* padding-left: 20px; */
}
.tiktok-portrait {
  font-size: 2.3vmin;
  /* margin-left: -22px; */
}
pre {
  /* width:100%; */
  border: solid;
  /* overflow-x:scroll; */
  word-wrap: break-word;
}
/* POSICION Y FONDO */
.fixed-bottom {
  z-index: 1;
}
.pos {
  z-index: 2;
  display: flex;
}
.corner {
  width: 70%;
}
.green {
  width: 100%;
  height: 100%;
}
span > ul > li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: "#100F7D"; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
</style>
