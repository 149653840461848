<template>
  <div class="container py-5">
    <div class="">
      <div class="form-course">
        <label class="d-block">Curso</label>
        <input
          v-model="nameCourse"
          type="text"
          autocomplete="off"
          class="form-control"
          disabled
        />
        <form @submit.prevent="handleSumbitQuestionary">
          <div
            class="formArea"
            v-for="(formQ, index) in formNewQuestion"
            :key="formQ.idLessonSelect"
          >
            <div class="my-2 d-flex">
              <div class="btn-new-lesson" @click="addForm(index)">
                Nuevo cuestionario
              </div>
              <span @click="putOff(index)">
                <i class="icon-put-off fas fa-times"></i>
              </span>
            </div>
            <label class="d-block">Leccion</label>
            <select
              :id="formQ.idLessonSelect"
              v-model="formQ.valueLessonSelect"
              autocomplete="off"
              class="form-control"
              required
            >
              <option
                v-for="leccion in lecciones"
                :key="leccion.nombre"
                :value="leccion._id.$oid"
              >
                {{ leccion.nombre }}
              </option>
            </select>
            <label class="d-block">tipo pregunta</label>
            <select
              :id="formQ.idTypeQuestion"
              v-model="formQ.valueTypeQuestion"
              autocomplete="off"
              class="form-control"
            >
              <option v-for="tipo in tipo_preguntas" :key="tipo" :value="tipo">
                {{ tipo }}
              </option>
            </select>
            <label class="d-block">Pregunta</label>
            <input
              :id="formQ.idQuestion"
              v-model="formQ.valueQuestion"
              type="text"
              autocomplete="off"
              class="form-control"
              required
            />
            <div class="" v-if="formQ.valueTypeQuestion == 'falso, verdadero'">
              <label class="d-block">Respuesta</label>
              <input
                :id="formQ.idResp"
                type="radio"
                value="true"
                v-model="formQ.valueResp"
                name="falso-verdadero"
                required
              /><label for="verdadero">&nbsp; &nbsp;Verdadero</label>
              <br />
              <input
                :id="formQ.idResp"
                type="radio"
                value="false"
                v-model="formQ.valueResp"
                name="falso-verdadero"
              /><label for="falso"> &nbsp; &nbsp; Falso</label>
            </div>

            <div class="" v-else>
              <label class="d-block">Respuesta</label>
              <div
                class="d-flex"
                v-for="(res, resIndex) in formQ.respOb"
                :key="resIndex"
              >
                <div class="col form-grpup">
                  <input
                    v-model="res.respuesta"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-3 mx-2">
                  <div class="d-flex btn-resp">
                    <!-- <span class="main-text fw-bold">Correcta: </span> -->
                    <input type="checkbox" class="mx-2" v-model="res.estado" />
                    <div class="" @click="addFormResp(index)">
                      <i class="plus-icon fas fa-plus"></i>
                    </div>
                    <span @click="putOffResp(index, resIndex)">
                      <i class="icon-put-off fas fa-times"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="btn-save my-3">Enviar</button>
        </form>
        <hr style="color: red" />
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import axios from "axios";

export default {
  name: "AddQuestion",
  props: ["id", "nameCourse"],
  data() {
    return {
      formQuestion: {
        curso: this.id,
        leccion: "",
        pregunta: "",
        respuesta: "",
        tipo_pregunta: "falso, verdadero"
      },
      lecciones: "",
      tipo_preguntas: ["falso, verdadero", "pregunta-respuesta"],
      counter: 0,
      formNewQuestion: [
        {
          idLessonSelect: "lesson0",
          valueLessonSelect: "",
          idTypeQuestion: "type0",
          valueTypeQuestion: "falso, verdadero",
          idQuestion: "question0",
          valueQuestion: "",
          idREsp: "resp0",
          valueResp: "",
          respOb: [
            {
              // idREsp: "resp0",
              respuesta: "",
              estado: false
            }
          ]
        }
      ],
      url: process.env.VUE_APP_URL
    };
  },
  validations() {
    return {
      formQuestion: {
        pregunta: {
          required,
          minLength: minLength(4)
        },
        respuesta: {
          required,
          minLength: minLength(4)
        },
        tipo_pregunta: null
      }
    };
  },
  async mounted() {
    let leccionesResponse = await axios.get(
      this.url + `/api/lecciones?curso_id=${this.id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }
    );
    this.lecciones = leccionesResponse.data.lecciones;
    // console.log(this.lecciones)
    this.formQuestion.leccion = this.lecciones[0]._id.$oid;
    this.formNewQuestion.forEach(el => {
      el.valueLessonSelect = this.lecciones[0]._id.$oid;
    });
    // console.log(this.formNewQuestion);
    // this.formNewQuestion[0].leccion = this.lecciones[0]._id.$oid
  },
  methods: {
    async handleSumbit() {
      this.formQuestion.respuesta =
        this.formQuestion.respuesta == "true" ? true : false;
      // console.log(this.formQuestion);
      this.$swal({
        title: "guardar",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Guardar"
      }).then();
      let examenes = await axios.post(
        this.url + `/api/preguntas`,
        this.formQuestion,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }
      );
      // console.log(examenes);
    },
    addForm() {
      this.counter++;
      this.formNewQuestion.push({
        idLessonSelect: `lesson${this.counter}`,
        valueLessonSelect: this.lecciones[0]._id.$oid,
        idTypeQuestion: `type${this.counter}`,
        valueTypeQuestion: "falso, verdadero",
        idQuestion: `question${this.counter}`,
        valueQuestion: "",
        idREsp: `resp${this.counter}`,
        valueResp: "",
        respOb: [
          {
            respuesta: "",
            estado: false
          }
        ]
      });
    },
    putOff(index) {
      this.counter--;
      this.formNewQuestion.splice(index, 1);
    },
    addFormResp(index) {
      this.formNewQuestion[index].respOb.push({
        respuesta: "",
        estado: false
      });
    },
    putOffResp(index, resIndex) {
      this.formNewQuestion[index].respOb.splice(resIndex, 1);
    },
    async handleSumbitQuestionary() {
      // console.log("new: ", this.id);
      this.$swal({
        icon: "warning",
        title: "Crear Cuestionario!",
        text: "¿ Está seguro de CREAR todas las preguntas añadidas ?",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        confirmButtonColor: "#7AB929",
        cancelButtonColor: "#CB3234",
        focusCancel: true,
        cancelButtonText: "Cancelar"
      }).then(async result => {
        if (result.isConfirmed) {
          this.formNewQuestion.forEach(async el => {
            // console.log(el);
            if (el.valueTypeQuestion == "falso, verdadero") {
              el.valueResp = el.valueResp == "true" ? true : false;
              await axios
                .post(
                  this.url + `/api/preguntas`,
                  {
                    curso: this.id,
                    leccion: el.valueLessonSelect,
                    pregunta: el.valueQuestion,
                    respuesta: el.valueResp,
                    tipo_pregunta: el.valueTypeQuestion,
                    estado: true
                  },
                  {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      Authorization: "Bearer " + localStorage.getItem("token")
                    }
                  }
                )
                .then(response => {
                  // console.log(response);
                })
                .catch(e => {
                  console.log(e);
                })
                .then(result => {
                  this.$router.replace({
                    params: { id: this.id },
                    path: "/processingQuestion/:" + this.id,
                    name: "ProcessingQuestion"
                  });
                  this.$swal({
                    icon: "success",
                    title: "Los datos se han guardado satisfactoriamente!",
                    confirmButtonText: "Gracias!",
                    confirmButtonColor: "#7AB929"
                  });
                });
            } else {
              // console.log(el)
              await axios
                .post(
                  this.url + `/api/preguntas`,
                  {
                    curso: this.id,
                    leccion: el.valueLessonSelect,
                    pregunta: el.valueQuestion,
                    respuesta: el.valueResp,
                    respuestas: el.respOb,
                    tipo_pregunta: el.valueTypeQuestion,
                    estado: true
                  },
                  {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      Authorization: "Bearer " + localStorage.getItem("token")
                    }
                  }
                )
                .then(response => {
                  // console.log(response);
                })
                .catch(e => {
                  console.log(e);
                })
                .then(result => {
                  this.$router.replace({
                    params: { id: this.id },
                    path: "/processingQuestion/:" + this.id,
                    name: "ProcessingQuestion"
                  });
                  this.$swal({
                    icon: "success",
                    title: "Los datos se han guardado satisfactoriamente!",
                    confirmButtonText: "Gracias!",
                    confirmButtonColor: "#7AB929"
                  });
                });
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");
.form-course {
  background: #d7dade;
  padding: 25px;
  border-radius: 15px;
}
.main-text {
  font-family: "Poiret One", cursive !important;
  color: #1d2945;
  /* font-size: 25px; */
  font-size: 3vmin;
}
label {
  color: #1d2945 !important;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.5rem; */
  font-size: 3.5vmin;
}
input,
textarea {
  margin-top: 5px;
  border-radius: 15px;
  font-family: "Poiret One", cursive;
  color: #1d2945;
  /* font-size: 18px; */
  font-size: 3vmin;
  font-weight: bold;
  /* text-transform: uppercase; */
  text-align: start;
}
option,
select {
  /* font-size: 18px; */
  font-size: 3vmin;
  font-family: "Fredoka One", cursive;
  border-radius: 15px;
  cursor: pointer;
}
.preview-image {
  max-width: 327px;
  max-height: 182px;
  border-radius: 15px;
}
input[type="file"] {
  border: 2px solid #fff;
  font-family: "Poiret One", cursive !important;
  font-weight: bold !important;
}
.btn-resp {
  float: right;
}
.icon-cross {
  color: #1d2945;
  font-size: 28px;
  /* font-size: 2vmin; */
  font-weight: bold;
  margin-left: -50px;
  cursor: pointer;
}
.plus-icon {
  margin-left: 10px;
  /* margin-bottom: 5px; */
  background: #7ab929;
  color: #fff;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  /* padding-left: 4px; */
  /* padding-right: 4px; */
  /* font-size: 28px; */
  vertical-align: middle;
  border-radius: 5px;
  cursor: pointer;
  padding: 0px 0.5vmin 0px 0.5vmin;
  font-size: 4.9vmin;
  /* max-width: 20px; */
}
.icon-check {
  color: #0000ff;
  margin-left: 10px;
  margin-bottom: 5px;
  background: cyan;
  padding-top: 5px;
  padding-bottom: 5px;
  /* padding-left: 4px; */
  /* padding-right: 4px; */
  font-size: 28px;
  vertical-align: middle;
  border-radius: 5px;
  cursor: pointer;
  /* max-width: 20px; */
}
.btn-save {
  margin: auto;
  background: #1d2945;
  color: #fff;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.5rem; */
  border: none;
  display: block;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  /* font-size: 20px; */
  font-size: 3vmin;
}
.btn-form-lesson {
  color: #fff;
  background: #7ab929;
  padding: 5px;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  font-size: 1.5rem;
  text-align: center;
  display: inline-block;
  border: none;
  cursor: pointer;
}
.btn-disabled {
  /* color: red; */
  cursor: not-allowed !important;
  background: #d7dade;
  border: 2px solid #fff;
  color: #1d2945;
}
/* clonar form */
.btn-new-lesson {
  color: #fff;
  background: #7ab929;
  padding: 5px;
  border-radius: 15px;
  font-family: "Fredoka One", cursive;
  /* font-size: 1rem; */
  font-size: 3vmin;
  text-align: center;
  display: inline-block;
  border: none;
  cursor: pointer;
}
.icon-put-off {
  margin-left: 10px;
  /* margin-bottom: 5px; */
  background: red;
  color: #fff;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  /* padding-left: 4px; */
  /* padding-right: 4px; */
  /* font-size: 28px; */
  padding: 0px 0.5vmin 0px 0.5vmin;
  font-size: 5vmin;
  vertical-align: middle;
  border-radius: 5px;
  cursor: pointer;
  /* max-width: 20px; */
}
</style>    