<template>
  <!-- <NavMain /> -->
  <div v-if="questions" class="content-all">
    <div class="text-center text-green">
      <section>
        <form @submit.prevent="handleSubmit">
          <nav class="fixed-top countdown">
            <div class="container">
              <div class="row">
                <div class="main-title text-center">
                  <p>
                    Cuestionario: {{ nombre_titulo }} -
                    <i class="text-green far fa-clock"></i>
                    <span class="text-green" id="countdown"></span>
                    <button class="btn-nav">Finalizar cuestionario</button>
                  </p>
                </div>
              </div>
            </div>
          </nav>
          <div class="container main">
            <div class="my-5" v-for="(quest, index) in questions" :key="quest">
              <p class="title2 container">
                {{ quest.pregunta }}
              </p>
              <div
                class="container text-center text-resp"
                v-for="(resp, index_resp) in resp_mark"
                :key="resp"
              >
                <p
                  v-if="
                    resp.lesson == quest.leccion.$oid &&
                      resp.question == quest.pregunta
                  "
                  @click="marked(quest._id.$oid, index, index_resp)"
                  :class="resp.mark == true ? 'text-resp2' : ''"
                >
                  {{ resp.name }}
                </p>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  </div>
  <div class="text-center" v-else>cargando questions...</div>
</template>

<script>
import NavMain from "@/components/NavMain.vue";
import axios from "axios";
export default {
  props: ["id", "nombre_titulo","last_lesson"],
  components: {
    NavMain,
  },
  data() {
    return {
      questions: null,
      tests: null,
      test_course: null,
      lessons: null,
      timer: "",
      counter: 0,
      finished: false,
      points: 0,
      state: false,
      result_question: "Reprobado",
      resp_mark: [],
      url: process.env.VUE_APP_URL,
      // check
      complete_lessons: null,
      isPost: true,
    };
  },
  async mounted() {
    var questionsResponse = await axios.get(
      this.url + "/api/preguntas?curso_id=" + this.id,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    this.questions = questionsResponse.data.preguntas;
    // console.log(this.questions);
    //
    var testsResponse = await axios.get(
      this.url + "/api/examenes?curso_id=" + this.id,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    this.tests = testsResponse.data.examenes;
    // console.log("test: ", this.tests);

    this.tests.forEach((el) => {
      if (el.usuario.$oid == localStorage.getItem("id")) {
        // console.log("examen curso usuario: ",el)
        this.test_course = el;
      }
    });
    // console.log("test_course: ", this.test_course);
    // this.questions.forEach((el) => {
    for (let i = 0; i < this.questions.length; i++) {
      for (let j = 0; j < this.questions[i].respuestas.length; j++) {
        this.resp_mark.push({
          id_question: this.questions[i]._id.$oid,
          name: this.questions[i].respuestas[j].respuesta,
          course: this.questions[i].curso.$oid,
          lesson: this.questions[i].leccion.$oid,
          question: this.questions[i].pregunta,
          mark: false,
          state: this.questions[i].respuestas[j].estado,
          pos: [i, j],
          n_question: i,
        });
      }
    }

    // });
    // console.log("resp_mark: ", this.resp_mark);
    // var lessonsResponse = await axios.get(
    //   this.url + "/api/lecciones?curso_id=" + this.id,
    //   {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    //   }
    // );
    // this.lessons = lessonsResponse.data.lecciones;
    // console.log(this.lessons);
    //
    var hor = 0;
    var min = 1;
    var today = new Date();
    var hours = "";

    var date =
      today.getMonth() +
      1 +
      "/" +
      today.getDate() +
      "/" +
      today.getFullYear() +
      " ";

    hours = today.getHours() + hor;

    if (min > 0) {
      if (today.getMinutes() + min > 59) {
        hours =
          today.getHours() +
          1 +
          ":" +
          (today.getMinutes() + (min - 59) + ":" + (today.getSeconds() + 1));
      } else {
        hours =
          today.getHours() +
          ":" +
          (today.getMinutes() + min) +
          ":" +
          (today.getSeconds() + 1);
      }
    }
    var end = new Date(`${date} ${hours}`);

    var _second = 1000;
    var _minute = _second * 60;
    var _hour = _minute * 60;
    var _day = _hour * 24;
    // var timer;
    this.timer = setInterval(() => {
      var now = new Date();
      var distance = end - now;
      if (distance < 0) {
        clearInterval(this.timer);
        // document.getElementById("countdown").innerHTML = "Finalizado!";
        // document.getElementById("main-page").innerHTML = " Finalizado! ";
        this.$swal({
          icon: "info",
          title: "El tiempo se ha terminado!",
          confirmButtonText: "OK",
          confirmButtonColor: "#7AB929",
        }).then((result) => {
          if (result.isConfirmed) {
            this.limitSubmit();
            // this.$router.push({ name: "Home" });
          }
        });
        return;
      }
      var days = Math.floor(distance / _day);
      var hours = Math.floor((distance % _day) / _hour);
      var minutes = Math.floor((distance % _hour) / _minute);
      var seconds = Math.floor((distance % _minute) / _second);

      // document.getElementById("countdown").innerHTML = days + " dias, ";
      // document.getElementById("countdown").innerHTML = hours + " horas, ";
      document.getElementById("countdown").innerHTML = " " + minutes + ":";
      document.getElementById("countdown").innerHTML += seconds;
    }, 1000);
  },
  watch: {
    $route(to, from) {
      if (this.timer != null) {
        clearInterval(this.timer);
        // localStorage.removeItem("bloques")
      }
    }
  },
  methods: {
    marked(id_quest, index, index_resp) {
      if (this.resp_mark[index_resp].mark === false) {
        this.resp_mark[index_resp].mark = true;
      } else {
        this.resp_mark[index_resp].mark = false;
      }
    },
    handleSubmit() {
      this.$swal({
        icon: "warning",
        title: "Enviar respuestas y finializar cuestionario",
        text: "¿ Esta seguro de enviar sus respuesstas y terminar el cuestionario ?",
        showCancelButton: true,
        confirmButtonText: "Sí, estoy seguro",
        confirmButtonColor: "#8FD53B",
        cancelButtonColor: "#CB3234",
        focusCancel: true,
        cancelButtonText: "No, aún no",
      }).then((result) => {
        if (result.isConfirmed) {
          clearInterval(this.timer);
          // Calculando puntaje
          // console.log("valor: ", (1 / this.questions.length) * 100);
          var a_correct = [];
          this.resp_mark.forEach((el) => {
            if (el.state == true && el.mark == true) {
              // correcto
              // a_correct.push(true);
              a_correct.push({
                correct: true,
                question: el.n_question,
              });
            } else {
              if (el.state == false && el.mark == true) {
                // incorrecto
                // a_correct.push(false);
                a_correct.push({
                  correct: false,
                  question: el.n_question,
                });
              } else {
                if (el.state == true && el.mark == false) {
                  // incorrecto
                  // a_correct.push(false);
                  a_correct.push({
                    correct: false,
                    question: el.n_question,
                  });
                } else {
                  if (el.state == false && el.mark == false) {
                    // correcto
                    // a_correct.push(true);
                    a_correct.push({
                      correct: true,
                      question: el.n_question,
                    });
                  }
                }
              }
            }
          });
          var indice = 0;
          var check = false;
          var inicio = 0;
          var limit = 0;
          // console.log(a_correct);

          while (indice < this.questions.length) {
            this.resp_mark.forEach((el) => {
              if (el.n_question == indice) {
                limit++;
              }
            });

            var result = [];
            while (inicio < limit) {
              if (a_correct[inicio].correct == true) {
                check = true;
                result.push(check);
              } else {
                check = false;

                result.push(check);
              }

              inicio++;
            }
            var calific = false;
            // console.log("result: ", result);
            var index_result = 0;
            while (index_result < result.length) {
              if (result[index_result] == true) {
                calific = true;
              } else {
                calific = false;
                index_result = result.length;
              }
              index_result++;
            }
            if (calific == true) {
              // this.points = this.points + ((1 / this.result_question.length) * 100);
              this.points++;
            }
            indice++;
          }
          // this.points.toFixed();
          // console.log(
          //   "points: ",
          //   ((this.points / this.questions.length) * 100).toFixed()
          // );
          // estado
          if (((this.points / this.questions.length) * 100).toFixed() > 51) {
            this.state = true;
            this.result_question = "Aprobado";
            // aprobado
            // check
            this.resolveCheck(this.id)
          }

          if (this.test_course != null) {
            // console.log("id examen: ", this.test_course._id.$oid);
            axios
              .put(
                this.url + "/api/examenes/" +
                  this.test_course._id.$oid,
                {
                  curso: this.id,
                  nombre: "Cuestionario: " + this.nombre_titulo,
                  nivel: "",
                  puntaje: ((this.points / this.questions.length) * 100)
                    .toFixed()
                    .toString(),
                  estado: this.state,
                },
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                }
              )
              .then((response) => {
                // console.log(response);
                this.$swal({
                  title: "Enviando Respuestas",
                  text: "Porfavor espere mientras se Envian las respuestas",
                  timer: 5000,
                  timerProgressBar: true,
                  showConfirmButton: false,
                }).then((result) => {
                  this.$swal({
                    icon: "success",
                    title: "Cuestionario finalizado!",
                    html: `<p style='font-weight:bold; color: #7AB929; font-family: "Fredoka One", cursive; font-size:1.5rem;'>Resultados</p>
                  <p style='font-weight:bold; color: #1D2945; font-family: "Poiret One", cursive; font-size:1.3rem;'>Puntaje: ${(
                    (this.points / this.questions.length) *
                    100
                  )
                    .toFixed()
                    .toString()}</p>
                  <p style='font-weight:bold; color: #1D2945; font-family: "Poiret One", cursive; font-size:1.3rem;'>Resultado: ${
                    this.result_question
                  }</p>`,
                    confirmButtonText: "Gracias!",
                    confirmButtonColor: "#7AB929",
                  }).then((result) => {
                    // this.$router.push({ name: "Home" });
                    this.$router.push({ name: "Course", params: { id: this.id, index_show: 0} });
                  });
                });
              })
              .catch((e) => {
                console.log("error: ", e);
              });
          } else {
            axios
              .post(
                this.url + "/api/examenes",
                {
                  nombre: "cuestionario: " + this.nombre_titulo,
                  nivel: "",
                  puntaje: ((this.points / this.questions.length) * 100)
                    .toFixed()
                    .toString(),
                  estado: this.state,
                  curso: this.id,
                },
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                }
              )
              .then((response) => {
                // console.log(response);
                this.$swal({
                  title: "Enviando Respuestas",
                  text: "Porfavor espere mientras se Envian las respuestas",
                  timer: 5000,
                  timerProgressBar: true,
                  showConfirmButton: false,
                }).then((result) => {
                  this.$swal({
                    icon: "success",
                    title: "Cuestionario finalizado!",
                    html: `<p style='font-weight:bold; color: #7AB929; font-family: "Fredoka One", cursive; font-size:1.5rem;'>Resultados</p>
                  <p style='font-weight:bold; color: #1D2945; font-family: "Poiret One", cursive; font-size:1.3rem;'>Puntaje: ${(
                    (this.points / this.questions.length) *
                    100
                  )
                    .toFixed()
                    .toString()}</p>
                  <p style='font-weight:bold; color: #1D2945; font-family: "Poiret One", cursive; font-size:1.3rem;'>Resultado: ${
                    this.result_question
                  }</p>`,
                    confirmButtonText: "Gracias!",
                    confirmButtonColor: "#7AB929",
                  }).then((result) => {
                    this.$router.push({ name: "Home" });
                  });
                });
              })
              .catch((e) => {
                console.log("error: ", e);
              });
          }
        }
      });
    },
    limitSubmit() {
      clearInterval(this.timer);
      // Calculando puntaje
      // console.log("valor: ", (1 / this.questions.length) * 100);
      var a_correct = [];
      this.resp_mark.forEach((el) => {
        if (el.state == true && el.mark == true) {
          // correcto
          // a_correct.push(true);
          a_correct.push({
            correct: true,
            question: el.n_question,
          });
        } else {
          if (el.state == false && el.mark == true) {
            // incorrecto
            // a_correct.push(false);
            a_correct.push({
              correct: false,
              question: el.n_question,
            });
          } else {
            if (el.state == true && el.mark == false) {
              // incorrecto
              // a_correct.push(false);
              a_correct.push({
                correct: false,
                question: el.n_question,
              });
            } else {
              if (el.state == false && el.mark == false) {
                // correcto
                // a_correct.push(true);
                a_correct.push({
                  correct: true,
                  question: el.n_question,
                });
              }
            }
          }
        }
      });
      var indice = 0;
      var check = false;
      var inicio = 0;
      var limit = 0;
      // console.log(a_correct);

      while (indice < this.questions.length) {
        this.resp_mark.forEach((el) => {
          if (el.n_question == indice) {
            limit++;
          }
        });

        var result = [];
        while (inicio < limit) {
          if (a_correct[inicio].correct == true) {
            check = true;
            result.push(check);
          } else {
            check = false;

            result.push(check);
          }

          inicio++;
        }
        var calific = false;
        // console.log("result: ", result);
        var index_result = 0;
        while (index_result < result.length) {
          if (result[index_result] == true) {
            calific = true;
          } else {
            calific = false;
            index_result = result.length;
          }
          index_result++;
        }
        if (calific == true) {
          // this.points = this.points + ((1 / this.resp_mark.length) * 100);
          this.points++;
        }
        indice++;
      }
      // this.points.toFixed();
      // console.log(
      //   "points: ",
      //   ((this.points / this.questions.length) * 100).toFixed().toString()
      // );
      // estado
      if (((this.points / this.questions.length) * 100).toFixed() > 51) {
        this.state = true;
        this.result_question = "Aprobado";
        // aprobado
      }
      if (this.test_course != null) {
        axios
          .put(
            this.url + "/api/examenes/" + this.test_course._id.$oid,
            {
              curso: this.id,
              nombre: "Cuestionario: " + this.nombre_titulo,
              nivel: "",
              puntaje: ((this.points / this.questions.length) * 100)
                .toFixed()
                .toString(),
              estado: this.state,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            // console.log(response);
            this.$swal({
              title: "Enviando Respuestas",
              text: "Porfavor espere mientras se Envian las respuestas",
              timer: 5000,
              timerProgressBar: true,
              showConfirmButton: false,
            }).then((result) => {
              this.$swal({
                icon: "success",
                title: "Cuestionario finalizado!",
                html: `<p style='font-weight:bold; color: #7AB929; font-family: "Fredoka One", cursive; font-size:1.5rem;'>Resultados</p>
                  <p style='font-weight:bold; color: #1D2945; font-family: "Poiret One", cursive; font-size:1.3rem;'>Puntaje: ${(
                    (this.points / this.questions.length) *
                    100
                  )
                    .toFixed()
                    .toString()}</p>
                  <p style='font-weight:bold; color: #1D2945; font-family: "Poiret One", cursive; font-size:1.3rem;'>Resultado: ${
                    this.result_question
                  }</p>`,
                confirmButtonText: "Gracias!",
                confirmButtonColor: "#7AB929",
              }).then((result) => {
                this.$router.push({ name: "Home" });
              });
            });
          })
          .catch((e) => {
            console.log("error: ", e);
          });
      } else {
        axios
          .post(
            this.url + "/api/examenes",
            {
              nombre: "cuestionario: " + this.nombre_titulo,
              nivel: "",
              puntaje: ((this.points / this.questions.length) * 100)
                .toFixed()
                .toString(),
              estado: this.state,
              curso: this.id,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            // console.log(response);
            this.$swal({
              title: "Enviando Respuestas",
              text: "Porfavor espere mientras se Envian las respuestas",
              timer: 5000,
              timerProgressBar: true,
              showConfirmButton: false,
            }).then((result) => {
              this.$swal({
                icon: "success",
                title: "Cuestionario finalizado!",
                html: `<p style='font-weight:bold; color: #7AB929; font-family: "Fredoka One", cursive; font-size:1.5rem;'>Resultados</p>
                  <p style='font-weight:bold; color: #1D2945; font-family: "Poiret One", cursive; font-size:1.3rem;'>Puntaje: ${(
                    (this.points / this.questions.length) *
                    100
                  )
                    .toFixed()
                    .toString()}</p>
                  <p style='font-weight:bold; color: #1D2945; font-family: "Poiret One", cursive; font-size:1.3rem;'>Resultado: ${
                    this.result_question
                  }</p>`,
                confirmButtonText: "Gracias!",
                confirmButtonColor: "#7AB929",
              }).then((result) => {
                this.$router.push({ name: "Home" });
              });
            });
          })
          .catch((e) => {
            // console.log("error: ", e);
          });
      }
    },
    async resolveCheck(id_course){
      // Verifica si ya paso el curso
      var responseCompletar = await axios.get(
        this.url +
          "/api/cursos/" +
          id_course +
          "/lecciones-completas/" +
          localStorage.getItem("id"),
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }
      );
      this.complete_lessons = responseCompletar.data;
      // console.log("completar: ", this.complete_lessons);
      // console.log("last_lesson: ", this.last_lesson);
      this.complete_lessons.forEach(el => {
        if (el == this.last_lesson) {
          this.isPost = false;
        }
      });
      if (this.isPost == true) {
        // // Guarda la primera lección y su usuario para que sea visible en el curso.
        // console.log("postea");
        var responseCursoUsuario = await axios.post(
          this.url + "/api/cursos/" + id_course + "/completar-leccion",
          {
            leccion_id: this.last_lesson,
            usuario_id: localStorage.getItem("id")
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        );
        // console.log(responseCursoUsuario.data);
      }

    }
  },
};
</script>

<style scoped>
.content-all {
  margin-bottom: 30vmin;
}
nav {
  background: #ffffff;
}
.text-green {
  color: #7ab929;
  /* font-size: 2rem; */
  font-size: 5vmin;
  font-family: "Fredoka One", cursive;
  font-weight: bold;
}
.main {
  margin-top: 6.5rem;
  /* margin-top: 11.7vmax; */
  border: 1px solid #ddd;
  /* background: #B8CF69; */
  overflow-y: auto;
}
.main-title {
  /* margin-top: 12rem; */
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  /* font-size: 3rem; */
  font-size: 5vmin;
}
.title2 {
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  /* font-size: 2rem; */
  font-size: 5vmin;
  margin-top: 3rem;
  /* cursor: pointer; */
}
.text-resp {
  /* width: ; */
  /* margin-left: 8rem; */
  /* margin-right: 8rem; */
  color: #1d2945;
  font-family: "Poiret One", cursive;
  /* font-size: 1.5rem; */
  font-size: 4.5vmin;
  font-weight: bold;
  cursor: pointer;
  /* display: inline-block; */
}
.text-resp:hover {
  color: #7ab929;
  /* font-size: 2rem; */
  font-size: 5.5vmin;
}
.text-resp2 {
  color: #7ab929;
  font-size: 2rem;
  text-decoration: underline solid 3px #7ab929;
}
.btn-nav {
  color: #7ab929;
  /* font-size: 1.5rem; */
  font-size: 4vmin;
  font-family: "Fredoka One", cursive;
  font-weight: bold;
  margin-left: 10px;
  background: transparent;
  border: solid 2px #1d2945;
  border-radius: 20px;
  padding: 10px;
}
.btn-nav:hover {
  background: #1d2945;
  color: #ffffff;
}
</style>