<template></template>

<script>
export default {
  props: ["id"],
  data() {
    this.$router.replace({
      path: "/coursesTeacher/:"+this.id+"/question",
      name: "QuestionDetails",
    });
    return {};
  },
};
</script>

<style scoped>
</style>