<template>
  <div
    v-if="tableUsers"
    class="content-all"
    :class="
      is_portrait == true && is_landscape == false ? 'portrait-rotate' : ''
    "
  >
    <NavMain />
    <section class="content-title">
      <div class="text-center">
        <div class="main-title">Administración de Usuarios</div>
      </div>
    </section>

    <div class="container-fluid mb-5">
      <!-- <div class="row"> -->
      <!-- <div class="col-lg-12"> -->
      <div class="tabla">
        <div class="word-left">
          Contenido
          <div class="d-flex justify-content-start">
            <!-- <div class="position-absolute end-0"> -->
            <input
              type="search"
              autofocus
              id="search"
              class="form-control"
              @change="handleSearch(search)"
              @keyup="handleSearch(search)"
              v-model="search"
            />
            <router-link :to="{ name: 'AddUser' }">
              <span class="d-flex  button_icon_add ">
                <i class="fas fa-plus text-white"></i>
              </span>
            </router-link>
          </div>
        </div>

        <table id="example" class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Correo</th>
              <th>Contraeña</th>
              <th>Edad</th>
              <th>Rol</th>
              <th>Estado</th>
              <th>Creado</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(tableUser, index) in tableUsers"
              :key="tableUser._id"
              v-show="
                (pag - 1) * NUM_RESULTS <= index && pag * NUM_RESULTS > index
              "
            >
              <td>{{ tableUser.nombre }}</td>
              <td>{{ tableUser.apellido }}</td>
              <td>{{ tableUser.correo }}</td>
              <td>{{ tableUser.confirmar_contrasena }}</td>
              <td>{{ tableUser.edad }}</td>
              <td>{{ tableUser.rol }}</td>
              <td v-if="tableUser.estado == true" class="text-center">
                <i class="fas fa-check"></i>
              </td>
              <td v-if="tableUser.estado == false" class="text-center">
                <i class="fas fa-times"></i>
              </td>
              <td>{{ tableUser.created.$date }}</td>
              <td class="text-center">
                <router-link
                  :to="{
                    name: 'UpdateUser',
                    params: {
                      id: tableUser._id.$oid,
                      nombre_titulo: tableUser.nombre
                    }
                  }"
                >
                  <span class="button_icon btn-info">
                    <i class="fas fa-edit"></i>
                  </span>
                </router-link>
                <span
                  @click="deleteRow(tableUser._id.$oid, tableUser.nombre)"
                  class="button_icon btn-danger"
                >
                  <i class="fas fa-trash-alt"></i>
                </span>
              </td>
            </tr>
          </tbody>
          <!-- <tfoot>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Correo</th>
              <th>Contraseña</th>
              <th>Edad</th>
              <th>Rol</th>
              <th>Estado</th>
              <th>Creado</th>
              <th>Opciones</th>
            </tr>
          </tfoot> -->
        </table>
      </div>
      <!-- </div> -->
      <!-- </div> -->
      <!-- Apagination -->
      <div>
        <!-- <ul class="list-group">
          <li
            class="list-group-item text-center"
            v-for="(user, index) in tableUsers"
            :key="index"
            v-show="
              (pag - 1) * NUM_RESULTS <= index && pag * NUM_RESULTS > index
            "
          >
            {{ user.nombre }} - {{ user.correo }}
          </li>
        </ul> -->
        <!-- Controles -->
        <nav aria-label="Page navigation" class="text-center">
          <ul
            class="pagination justify-content-center"
            v-if="Math.round(tableUsers.length % NUM_RESULTS) == 0"
          >
            <li class="page-item">
              <a class="page-link"
                href="#"
                aria-label="Previous"
                v-show="pag != 1"
                @click.prevent="pag -= 1"
              >
                <span aria-hidden="true">Anterior</span>
              </a>
            </li>
            <!-- page items -->
            <li
              class="page-item"
              v-for="(item, index_page) in Math.round(
                tableUsers.length / NUM_RESULTS
              )"
              :key="index_page"
            >
              <a class="page-link" href="#"
              @click.prevent="pag = item">{{ item }}</a>
            </li>
            <li class="page-item">
              <a class="page-link"
                href="#"
                aria-label="Next"
                v-show="(pag * NUM_RESULTS) / tableUsers.length < 1"
                @click.prevent="pag += 1"
              >
                <span  aria-hidden="true">Siguiente</span>
              </a>
            </li>
          </ul>
          <!-- decimal -->
          <ul
            class="pagination justify-content-center"
            v-if="Math.round(tableUsers.length % NUM_RESULTS) == 1"
          >
            <li class="page-item">
              <a class="page-link"
                href="#"
                aria-label="Previous"
                v-show="pag != 1"
                @click.prevent="pag -= 1"
              >
                <span aria-hidden="true">Anterior</span>
              </a>
            </li>
            <!--  -->
            <li
              class="page-item"
              v-for="(item, index_page) in Math.round(
                tableUsers.length / NUM_RESULTS
              )+1"
              :key="index_page"
            >
              <!-- {{item}} -->
              <a class="page-link" href="#"
              @click.prevent="pag = item">{{ item }}</a>
            </li>
            <li class="page-item">
              <a class="page-link"
                href="#"
                aria-label="Next"
                v-show="(pag * NUM_RESULTS) / tableUsers.length < 1"
                @click.prevent="pag += 1"
              >
                <span aria-hidden="true">Siguiente</span>
              </a>
            </li>
          </ul>
          
        </nav>
      </div>
    </div>
    <Footer />
  </div>
  <div class="text-white text-center" v-else>Cargando AdminUsers...</div>
</template>

<script>
import NavMain from "@/components/NavMain.vue";
import Footer from "@/components/Footer.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
// axios
import axios from "axios";
import moment from "moment";
// pagination

export default {
  components: {
    NavMain,
    Footer
    //
  },
  data() {
    return {
      tableUsers: [],
      url: process.env.VUE_APP_URL,
      // tamaño de pantalla
      is_portrait: false,
      is_landscape: false,
      // search
      search: "",
      filteredUsers: null,
      // pagination prueba
      NUM_RESULTS: 5, // Numero de resultados por página
      pag: 1 // Página inicial
    };
  },
  methods: {
    deleteRow(id, name) {
      this.$swal({
        icon: "warning",
        title: "Borrar Usuario!",
        text: "¿ Está seguro que desea BORRAR al Usuario ?" + "  " + name,
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        confirmButtonColor: "#8FD53B",
        cancelButtonColor: "#CB3234",
        focusCancel: true,
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.isConfirmed) {
          // Borrando...
          for (let i = 0; i < this.tableUsers.length; i++) {
            if (this.tableUsers[i]._id.$oid == id) {
              this.tableUsers.splice(i, 1);
            }
          }
          axios
            .delete(this.url + "/api/usuarios/" + id)
            .then(response => {
              // console.log(response);
            })
            .catch(e => {
              console.log(e);
            });
          this.$swal({
            icon: "success",
            text: "El Usuario se ha borrado satisfactoriamente",
            confirmButtonColor: "#8FD53B",
            focusConfirm: true
          });
        }
      });
    },
    async handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
        // if (screen.width <= 360 && screen.height <= 760) {
        //
        this.is_portrait = true;
        this.is_landscape = false;
        // }
      } else if (orientation === "landscape-primary") {
        // landscape mode
        if (
          (screen.width <= 760 && screen.height <= 360) ||
          (screen.width <= 1080 && screen.height <= 810)
        ) {
          this.is_landscape = true;
        }
        this.is_portrait = false;
      }
    },
    handleSearch(value) {
      if (value && value.length > 0) {
        // console.log(value)
        this.tableUsers = this.filteredUsers.filter(i => {
          const val = value.toLowerCase();
          // console.log(val)
          const nombre = i.nombre && i.nombre.toLowerCase();
          const apellido = i.apellido && i.apellido.toLowerCase();
          const correo = i.correo && i.correo.toLowerCase();
          // console.log(nombre)
          if (
            (val && nombre.indexOf(val) !== -1) ||
            (val && apellido.indexOf(val) !== -1) ||
            (val && correo.indexOf(val) !== -1)
          ) {
            // console.log(nombre)
            this.pag=1
            return true;
          }
          return false;
        });
      } else {
        this.tableUsers = this.filteredUsers;
        // this.trueCourses = this.filteredMyCourses
      }
    }
  },
  mounted() {
    //
    if (window.innerHeight > window.innerWidth) {
      // alert("Please use Landscape!");
      this.is_portrait = true;
      this.is_landscape = false;
    } else {
      this.is_portrait = false;
      if (
        (screen.width <= 800 && screen.height <= 360) ||
        (screen.width <= 1080 && screen.height <= 810)
      ) {
        this.is_landscape = true;
      }
    }
    window.addEventListener("orientationcha nge", this.handleOrientationChange);
    //
    axios
      .get(this.url + `/api/usuarios`)
      .then(response => {
        this.tableUsers = response.data.data;
        // search
        this.filteredUsers = this.tableUsers;
        //
        // console.log(
        //   "prueba: ",
        //   Math.round(this.tableUsers.length / this.NUM_RESULTS)
        // );
        // console.log(this.tableUsers);

        // console.log(this.tableUsers[0].created.$date);
        this.tableUsers.forEach(el => {
          var fecha = moment.utc(el.created.$date).format("DD-MM-YYYY h:mma");
          el.created.$date = fecha;
        });

        // Search
        $(document).ready(function() {
          $("#search").focus();
        });

        // DATATABLES
        // $(document).ready(function() {
        // DATATABLES
        // $("#example").DataTable({
        //   info: false,
        //   responsive: false,
        //   language: {
        //     decimal: "",
        //     emptyTable: "No hay información",
        //     info: "Mostrando _START_ a _END_ de _TOTAL_ Entradas",
        //     infoEmpty: "Mostrando 0 to 0 of 0 Entradas",
        //     infoFiltered: "(Filtrado de _MAX_ total entradas)",
        //     infoPostFix: "",
        //     thousands: ",",
        //     lengthMenu: "  Mostrar   _MENU_   Entradas  ",
        //     loadingRecords: "Cargando...",
        //     processing: "Procesando...",
        //     search: "Buscar:  ",
        //     zeroRecords: "Sin resultados encontrados",
        //     paginate: {
        //       first: "Primero",
        //       last: "Ultimo",
        //       next: "Siguiente",
        //       previous: "Anterior"
        //     }
        //   }
        // });
        // });
      })
      .catch(e => {
        console.log(e);
      });
  }
};
</script>

<style scoped>
/* @media screen and (min-width: 360px) and (max-width: 760px) and (orientation: portrait) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */
/* .portrait-rotate {
  transform: rotate(-90deg);
  transform-origin: left top;
  width: 100vh;
  height: 100vw;
  overflow-x: hidden;
  position: absolute;
  top: 100%;
  left: 0;
} */
th {
  color: white;
  /* font-size: 26px; */
  font-size: 1.8vmin;
  /* width: auto; */
  width: 2.5vmin;
}
td {
  color: #1d2945;
  font-size: 2.5vmin;
  /* width: auto; */
  /* width: 0.1vmin; */
  /* padding: -20px; */
}

tr {
  /* color: white; */
  font-family: "Poiret One", cursive !important;
  /* font-size: 22px; */
  font-size: 2.5vmin;
  font-weight: bold;
  /* width: auto; */
}
/* .dataTables_length label{
  font-size: 1vmin !important;
} */
.content-all {
  margin-bottom: 30vmin;
  /* transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0; */
  /* transform: rotate(90deg); */
}
.content-title {
  /* margin-top: 12rem; */
  margin-top: 18vmin;
  /* margin-bottom: 2rem; */
  margin-bottom: 3vmin;
  font-family: "Fredoka One", cursive;
  color: #1d2945;

  /* background: #d7dade; */
}
.main-title {
  font-size: 4vmin;
  font-weight: bold;
}
.tabla {
  border: 5px solid #00dece !important;
  border-radius: 20px;
  /* background: linear-gradient(to right,#fff,  #cceba1, #8fd53b); */
  background: #91d63e;
  /* margin-left: 30px; */
  /* margin-right: 30px; */
  margin: auto;
  overflow: auto;
}
.word-left {
  color: #ffffff;
  font-size: 2vmin;
  font-weight: bold;
  font-family: "Fredoka Ones", cursive;
  word-wrap: break-word;
}
input {
  /* position: absolute; */
  /* right: 0; */
  max-width: 40vmax;
  max-height: 3.5vmin;
  border-radius: 15px;
  /* margin-right: 1vmin; */
  /* margin-right: 7vmin; */
  font-size: 2.8vmin;
}
.button_icon {
  /* padding-right: 5px; */
  /* padding-left: 5px; */
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  /* font-size: 25px; */
  padding: 0.5vmin 0.5vmin 0.5vmin 0.5vmin;
  font-size: 2vmin;
  border-radius: 10px;
  margin-right: 0.5vmin;
  /* margin-bottom: 10vmin; */
  color: white;
  cursor: pointer;
}
.button_icon_add {
  /* width: 40px; */
  /* display: block; */
  /* position: absolute; */
  /* right: 0; */

  margin-left: 1vmin;
  background: #009d00 !important;
  /* padding-right: 5px; */
  /* padding-left: 5px; */
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  padding: 0.5vmin 0.5vmin 0.5vmin 0.5vmin;
  /* font-size: 25px; */
  font-size: 2vmin;
  border-radius: 10px;
  /* margin-right: 5px; */
  /* margin-top: 5px; */
  margin-top: 0.5vmin;
  cursor: pointer;
  float: right;
}
.button_icon:hover,
.button_icon_add:hover {
  /* background: #253755; */
  color: #ffffff !important;
  /* font-size: 23px; */
  font-size: 2.1vmin;
}
.fa-check,
.fa-times {
  font-size: 2vmin;
}
/* paginacion */
.page-link{
font-size: 1.8vmin;
/* align-items: center; */
margin-top: 1vmin;
background-color: #AED778;
color: #ffffff;
font-family: "Fredoka One", cursive;
/* outline-color: 3px solid #ffffff; */
}
.page-link:hover{
  background-color: #91D63E;
}
.page-link:focus{
  background-color: #91D63E;
  /* font-size: 30vmin; */
  outline: unset;
  /* border: none; */
  box-shadow:  unset;
}
</style>