import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// vue-sweetAlert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'; 
// bootstrapt 5
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "popper.js/dist/popper.js";
// font awesome
import '@fortawesome/fontawesome-free/css/all.css' 
import '@fortawesome/fontawesome-free/js/all.js' 
// personal css
import './assets/global.css'
//
// import axios from 'axios'; 
// var token = localStorage.getItem("token")
// if(token){
//     axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
// }
// 
const app = createApp(App)
app.use(router)
app.use(VueSweetalert2);
app.mount('#app');
<script async src="https://www.tiktok.com/embed.js"></script>