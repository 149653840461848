<template>
  <div class="">
    <!-- <Footer  v-if="!['Login','SLogin','NLogin'].includes($route.name)"/> -->
  </div>  
<router-view/>
<Footer v-if="!['Login','SLogin','NLogin','AdminUsers'].includes($route.name)"/>
</template>
<script>
// import Nav from '@/components/Nav.vue'
// import Login from '@/views/users/Login.vue'
import Footer from "@/components/Footer.vue";
export default {
  components:{
    Footer
    // Nav,
    // Login
  }
}
</script>


<style scoped>

</style>
