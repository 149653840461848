<template>
  <section class="article py-5">
    <div class="container">
      <!-- <div class="row"> -->
      <div class="text-center text-white">
        <h1 class="title display-4 fw-bold">
          Anímate a conocer todos nuestros Cursos!
        </h1>
      </div>
      <!-- </div> -->
    </div>
  </section>

  <div v-if="courses">
    <div class="container">
      <div class="card-group justify-content-center">
        <div class="row mx-2" v-for="course in courses" :key="course._id">
          <div class="card card-cursos">
            <router-link
              :to="{ name: 'CourseDetails', params: { id: course._id.$oid } }"
            >
              <div class="card-pointer">
                <div class="text-center">
                  <i class="fas fa-book book"></i>
                  <i class="fas fa-book-open book2"></i>
                </div>

                <div class="card-body card-body-main text-dark">
                  <h4 class="card-title fs-6 fw-bold">{{ course.nombre }}</h4>
                  <p class="card-text">
                    <small class="text-muted fst-italic">{{
                      course.autor
                    }}</small>
                  </p>
                </div>
                <div class="card card-hover">
                  <div class="card-body">
                    <h5
                      class="
                        card-title
                        text-uppercase text-dark
                        fs-6
                        fw-bold
                        text-center
                      "
                    >
                      {{ course.nombre }}
                    </h5>
                    <p class="card-text text-dark">
                      {{ course.cartilla }}
                    </p>
                    <div class="card-footer bg-transparent border-top-0">
                      <div class="row">
                        <div class="text-center">
                          <a href="#" class="btn-2 px-2 mx-1"
                            ><i class="fas fa-puzzle-piece fs-5 px-1"></i
                            >Practica!</a
                          >
                          <a href="curso1.html" class="btn-2 px-2 mx-1"
                            ><i class="fas fa-glasses fs-5 px-1"></i>Estudia!</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
            <div class="d-flex justify-content-center">
              <div class="stars text-center">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star-half"></i>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="icon-likes">
                <span @click="changeLike(course._id.$oid)">
                  <span v-show="like"><i class="far fa-thumbs-up icon-like"></i></span>
                  <span v-show="noLike"><i class="fas fa-thumbs-up icon-like"></i></span>
                </span>
                <i class="far fa-meh icon-like"></i>
                <i class="far fa-thumbs-down icon-like"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center text-white" v-else>
    <p>Cargando cursos....</p>
  </div>
  <!-- <div class="home">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div> -->
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "Courses",
  components: {
  },
  data() {
    return {
      courses: [],
      like: true,
      noLike: false,
      url: process.env.VUE_APP_URL,
    };
  },
  mounted() {
    // fetch(this.url + '/api/cursos')
    //   .then(res => res.json())
    //   .then(data => this.courses = data)
    //   .catch(err => console.log(err.message))
    axios
      .get(this. url + `/api/cursos`)
      .then((response) => {
        // console.log(response);
        this.courses = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods:{
    changeLike(id){
      // var icon = document.getElementById('icon');
      if(this.like == true){
        // icon.classList.toggle('far');
        // icon.classList.toggle('fas');
        this.like = false
        this.noLike = true
      }else{
        // icon.classList.toggle('fas');
        // icon.classList.toggle('far');
        this.like = true
        this.noLike = false
      }
    }
  }
};
// console.log(this.courses)
</script>

<style scoped>
/* .card-link{
    width: auto;
    text-decoration: none;
  } */
.card-cursos {
  border: #78be20 2px solid !important;
  max-width: 240px;
  max-height: 240px;
  /* width: 10rem; */
  margin-right: 20px;
  margin-top: 20px;
  position: relative;
}
.card-body-main {
  width: 250px;
  height: 80px;
}
.card-hover {
  visibility: hidden;
  width: 18rem;
  z-index: 2;
}
.card-pointer {
  position: relative;
}
/* .card-cursos:hover .card-hover {
  visibility: visible;
  transition: opacity 1s ease;
  transform: translate(72%, -12%) !important;
} */
.card-pointer:hover .card-hover {
  visibility: visible;
  transition: opacity 1s ease;
  transform: translate(72%, -12%) !important;
}
/* icons */
.book {
  font-size: 4rem;
  color: #90d53c;
  padding-top: 12px;
}
.book2 {
  display: none;
  font-size: 4rem;
  color: #90d53c;
  padding-top: 12px;
}
.card-cursos:hover .book {
  display: none;
}
.card-cursos:hover .book2 {
  display: inline;
}
.stars {
  width: 200px;
  display: inline;
  font-size: 18px;
  color: #ffdf00;
  margin-bottom: 30px;
  position: absolute;
  bottom: 0px;
  /* text-align: center; */
}
.icon-likes {
  display: inline;
  /* float: right; */
  color: #262d46;
  margin: 2px;
  /* margin-top: 20px; */
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  bottom: 0px;
}
.icon-like {
  margin-right: 8px;
}
.icon-like:hover {
  /* font-weight: bold; */
  font-size: 22px;
  margin-right: 10px;
}
.btn-2 {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  background-color: #8fd53b;
  border-radius: 0.25rem;
  color: white;
  width: 150px;
  margin-top: 4px;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.btn-2:hover {
  color: #ffffff;
  font-weight: bold;
}
</style>
