<template>
    <footer class="footer-class">
        <div class="container-fluid">
            <div class="main-footer">
                <div class="text-footer">Auspiciado por:</div>
                <div class="logo">
                    <div class="logo-img">
                        <img src="../assets/img/logos/redes-logo.jpg" />
                    </div>
                    <div class="logo-img" style="padding: 0.75rem">
                        <img src="../assets/img/logos/Cofinanciado-UE.png" />
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
/* footer {
    position:absolute;
   bottom:0;
   width:100%;
   height:60px;   
   background:#6cf;
   z-index: 4;
} */
.main-footer {
    display: flex;
    align-items: center;
    /* float: right;   */
}
.logo {
    display: flex;
    max-width: 25vmin;
    max-height: 9vmin;
    padding: 5px;
    /* float: right; */
}
.logo-img {
    /* max-width: 17vmin; */
    height: 88px;
    display: inline-block;
    padding: 0 0.75rem;
    margin-right: 2vmin;
    border-radius: 10px;

    background-color: white;
}
.logo-img > img {
    height: 100%;
}
.text-footer {
    color: #fff;
    font-size: 2.5vmin;
    font-family: 'Fredoka One', cursive;
    display: inline-block;
    margin-right: 2vmin;
}
</style>
