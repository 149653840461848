<template>
  <div class="main">
    <!-- <div class="">
            <NavLogin  v-if="!['Login','SLogin',].includes($route.name)"/>
    </div> -->
    <nav class="navbar navbar-light navbar-expand fixed-top">
      <div class="container-fluid">
        <div class="collapse navbar-collapse justify-content-end">
          <ul class="nav nav-pills" id="pills-tab" role="tablist">
            <li
              class="logo position-absolute top-0 start-0"
              role="presentation"
            >
              <img
                @click="homeActive"
                class="logo-img img-fluid"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                role="tab"
                aria-controls="pills-home"
                aria-selected="false"
                src="../../assets/img/LOGO-Edu-tiktok.png"
                alt=""
              />
              <!-- <img
                @click="homeActive"
                class="logo-img img-fluid"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                role="tab"
                aria-controls="pills-home"
                aria-selected="false"
                src="../../assets/img/logos/Cofinanciado-UE.png"
                alt=""
                height="140"
              /> -->
            </li>
            <li class="mx-3 text-end" role="presentation">
              <!-- <button class="btn-primary d-block">asdasdasdas</button> -->
              <button
                @click="loginActive"
                class="nav-link btn-login mx-2"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                INGRESAR
              </button>
              <button
                @click="registerActive"
                class="nav-link btn-login mx-2"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-contact"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                REGÍSTRATE
              </button>
            </li>
            <!-- <li class="nav-item" role="presentation">
              
            </li> -->
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <div class="main">
    <div class="container">
      <div class="pos">
        <!-- login y register portrait -->
        <div class="form-portrait">
          <div
            class="position-absolute top-50 start-50 translate-middle"
            v-if="(is_portrait && login) || (is_portrait && register)"
          >
            <div class="container text-center">
              <p>
                <span class="text text-change1" v-if="message_register">
                  Te damos la
                </span>
                <span class="text text-change2" v-if="message_register">
                  bienvenida</span
                >
              </p>
            </div>
            <FormLogin class="text-center" v-if="is_portrait && login" />
            <FormRegister class="text-center" v-if="is_portrait && register" />
          </div>
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-2 tab-left">
          <div
            class="picture position-absolute top-50 start-0 translate-middle-y"
          >
            <img
              v-if="picture"
              src="../../assets/img/DIBUJO-PANTALLA-PRINCIPAL.png"
              class="img-principal"
            />
            <div class="container text-center" v-if="is_portrait == false">
              <p>
                <span class="text text-change1" v-if="message_register">
                  Te damos la
                </span>
                <span class="text text-change2" v-if="message_register">
                  bienvenida</span
                >
              </p>
            </div>
          </div>
        </div>
        <div class="col-4 tab-rigth">
          <div class="text-content text-center">
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div class="text position-absolute top-50 translate-middle-y">
                  "Aprender es un proceso constante o no es nada"
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <!-- <div class="title-login container">INICIAR SESIÓN</div> -->
                <FormLogin v-if="is_portrait == false" />
              </div>
              <div
                class="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                <!-- <div class="title-register container">REGÍSTRATE</div> -->
                <FormRegister v-if="is_portrait == false" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="corner-green position-absolute bottom-0 start-0">
        <img src="../../assets/img/ESQUINA-VERDE.png" alt="" class="green" />
      </div>
      <div class="corner-blue position-absolute bottom-0 end-0">
        <img src="../../assets/img/ESQUINA-AZUL.png" alt="" class="blue" />
      </div>
      <Footer class="footer-login" />
    </div>
  </div>
</template>

<script>
// import NavLogin from "@/components/NavLogin.vue";
import FormRegister from "@/components/FormRegister.vue";
import FormLogin from "@/components/FormLogin.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Footer,
    FormRegister,
    FormLogin
  },
  data() {
    return {
      register: false,
      login: false,
      picture: true,
      message_register: false,
      message_login: false,
      is_portrait: false,
      url: process.env.VUE_APP_URL
    };
  },
  methods: {
    registerActive() {
      this.register = true;
      this.message_register = true;
      this.picture = false;
      //
      this.login = false;
    },
    loginActive() {
      this.login = true;
      this.message_register = true;
      this.picture = false;
      //
      this.register = false;
    },
    homeActive() {
      this.register = false;
      this.login = false;
      this.message_register = false;
      this.picture = true;
    },
    handleOrientationChange(){
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
        this.is_portrait = true;
        // }
      } else if (orientation === "landscape-primary") {
        // landscape mode
        // if (
        //   (screen.width <= 760 && screen.height <= 360) ||
        //   (screen.width <= 1080 && screen.height <= 810)
        // ) {
        //   this.is_landscape = true;
        // }
        this.is_portrait = false;
      }
    }
  },
  mounted() {
    if (window.innerHeight > window.innerWidth) {
      // alert("Please use Landscape!");
      //
      this.is_portrait = true;
    } else {
      this.is_portrait = false;
      // if (
      //   (screen.width <= 800 && screen.height <= 360) ||
      //   (screen.width <= 1080 && screen.height <= 810)
      // ) {
      // }
    }
    window.addEventListener("orientationchange", this.handleOrientationChange);
  }
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
/* .main{
  margin-bottom: -10rem;
} */
.logo {
  /* margin-top: -30px; */
  margin-top: -7.4vmin;
  margin-left: 1vmin;
  cursor: pointer;
  max-width: 25vmin;
  display: flex;
}
/* class="logo position-absolute top-0 start-0" */
.logo-img {
  /* padding: 0.5rem 1rem; */
  margin-top: 2rem;
  width: 100%;
  /* max-width: 10rem; */
  display: inline-block;
}
.tab-left {
  width: 60rem;
}
.picture {
  width: 45%;
  /* position: absolute; */
  z-index: 2;
}
.img-principal {
  width: 100%;
  height: 100%;
}
.tab-rigth {
  width: 5rem;
  /* font-family: "Fredoka One", cursive; */
  /* font-size: 65px; */
  font-weight: bold;
  /* color: #1d2945; */
  z-index: 2;
  /* position: relative; */
}
.text-content {
  position: absolute;
  top: 20%;
  left: 50%;
  height: 30%;
  width: 40%;
  /* margin: -15% 0 0 -25%; */
}
.text {
  color: #1d2945;
  /* font-size: 65px; */
  font-size: 4.5vw;
  padding-top: 12rem;
  text-align: center;
  font-family: "Fredoka One", cursive;
}
.text-change1 {
  color: #1d2945;
}
.text-change2 {
  color: #78b829;
}
/* portrait */
.form-portrait {
  z-index: 3 !important;
  /* position: relative; */
}
/*  */
.pos{
  z-index: 2;
  display: flex;
}
.corner-green {
  height: 110%;
  z-index: 1;
  margin-bottom: -3rem;
}
.green {
  width: 100%;
  height: 100%;
}
.corner-blue {
  width: 80%;
  z-index: 1;
  margin-bottom: -3rem;
}
.blue {
  width: 100%;
  height: 100%;
}
.footer-login {
  position: fixed;
  bottom: 0;
  left: 0;
}
/* .form-register {
  z-index: 3;
  width: 30rem;
  height: 40rem;
  border: 1px solid #000;
} */
.text-form {
  float: left;
}
.title-register {
  /* display: inline-block; */
  text-align: center;
  background-color: #7ab929 !important;
  border-radius: 0.8rem;
  color: white;
  max-width: 180px;
  /* margin-top: 4px; */
  font-size: 20px;
  font-family: "Fredoka One", cursive;
  padding-top: 13px;
  padding-bottom: 13px;
}
.title-login {
  /* display: inline-block; */
  text-align: center;
  background-color: #1d2945 !important;
  border-radius: 0.8rem;
  color: white;
  width: 250px;
  /* margin-top: 4px; */
  font-size: 25px;
  font-family: "Fredoka One", cursive;
  padding-top: 13px;
  padding-bottom: 13px;
}
.btn-register {
  /* display: inline-block; */
  text-align: center;
  text-decoration: none;
  /* vertical-align: middle; */
  cursor: pointer;
  background-color: #7ab929 !important;
  border-radius: 0.8rem;
  color: white;
  width: 190px;
  /* margin-top: 4px; */
  font-size: 25px;
  font-family: "Fredoka One", cursive;
  padding-top: 13px;
  padding-bottom: 13px;
}
.btn-register:active {
  color: #1d2945;
}
.btn-login {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  /* vertical-align: middle; */
  cursor: pointer;
  background-color: transparent !important;
  border: 2px solid #1d2945;
  border-radius: 0.8rem;
  color: #1d2945;
  /* width: 190px; */
  /* margin-top: 4px; */
  font-size: 3vmin;
  font-family: "Fredoka One", cursive;
  padding-top: 13px;
  padding-bottom: 13px;
}
/* .btn-login:active{
    color: #1D2945;
} */
/* 
 */
.nav-link.active.btn-login {
  color: #fff !important;
  background-color: #7ab929 !important;
  border: none;
}

.btn-register:hover,
.btn-login:hover {
  /* font-size: 26px; */
  font-weight: bold;
}
.corners {
}
</style>