<template></template>

<script>
export default {
  props: ["id"],
  data() {
    this.$router.replace({
      path: "/myProfile/:",
      name: "MyProfile",
    });
    return {};
  },
};
</script>

<style scoped>
</style>